import { useEffect, useState } from "react";
import { rdbGetUserActions } from "../../../store/firebaseStore";

export function UserLogView(props: { uid: string }) {
    // const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    // const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    // const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    // const loadMethod: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
    const [items, setItems] = useState<{ key: string, action: string }[]>([]);
    useEffect(() => {
        const doit = async () => {
            const actions = await rdbGetUserActions(props.uid);
            console.log(actions);
            const items = Object.entries(actions).map(([key, value], idx) => {
                console.log(key, value);
                const v: any = value;
                return { key: key, action: v.action }
            });
            setItems(items);
        };
        doit();
    }, []);
    return <>
        <ul>
            {items.map((item, idx) => <li key={idx}>time: {item.key} action: {item.action} </li>)}
        </ul>
    </>
}