import { Action, Helpers, Thunk, action, thunk } from "easy-peasy";
import { AppModel } from "./appModel";
import { getUsersData } from "./firebaseStore";
import { UserData } from "./userdata";

export type UsersStatus =
    | { type: "empty" }
    | { type: "loading" }
    | { type: "users", usersdata: UserData[] }
    | { type: "error"; msg: string };


export type UsersModel = {
    status: UsersStatus;
    update: Action<UsersModel, UsersStatus>;
    setStatus: Thunk<UsersModel, UsersStatus>;
    load: Thunk<UsersModel, void>;
}

export const createUsers = (): UsersModel => {
    return {
        status: { type: "empty" },
        update: action((state, payload) => {
            state.status = payload;
        }),

        setStatus: thunk(async (actions, payload, helpers: Helpers<UsersModel, AppModel, any>) => {
            actions.update(payload);
        }),

        load: thunk(async (actions, payload, helpers: Helpers<UsersModel, AppModel, any>) => {
            actions.setStatus({ type: "loading" });
            try {
                const usersdata: UserData[] = await getUsersData();
                console.log('usersData', usersdata);
                actions.setStatus({ type: "users", usersdata: usersdata })
            } catch (error) {
                actions.setStatus({ type: "error", msg: String(error) });
            }
        }),
    }
}