import { useState } from "react";
import { Item } from "../../../data/Data";
import { useDebounce } from "../../../hooks/useDebounce";
import { MarkdownView } from "../../MarkdownView";

const MARKDOWN_DEBOUNCE_TIME = 400;

export function MarkdownEditor(props: { item: Item, onChange: (item: Item) => void }) {
    const [text, setText] = useState<string>(props.item.text);
    const debouncedText = useDebounce<string>(text, MARKDOWN_DEBOUNCE_TIME);
    return <div className="MarkdownEditor Flex FlexColumn" style={{ padding: '0 0 .5rem' }}>
        <h4 className="NoMargin">Markdown:</h4>
        <textarea value={text} onChange={e => {
            setText(e.target.value);
            const changedItem = { ...props.item, text: e.target.value.trim() };
            props.onChange(changedItem);

        }}></textarea>
        <h4 className="NoMargin">Preview:</h4>
        <div className="" style={{ border: "10px solid #efefef" }}>
            <MarkdownView markdown={debouncedText} />
        </div>
    </div>

}
export function MarkdownEditorLite(props: { markdown: string, onChange: (markdown: string) => void }) {
    const [text, setText] = useState<string>(props.markdown);
    const debouncedText = useDebounce<string>(text, MARKDOWN_DEBOUNCE_TIME);
    return <div className="MarkdownEditor Flex FlexColumn" style={{ padding: '0 0 .5rem' }}>
        <h4 className="NoMargin">Markdown:</h4>
        <textarea value={text} onChange={e => {
            setText(e.target.value);
            props.onChange(e.target.value.trim());

        }}></textarea>
        <h4 className="NoMargin">Preview:</h4>
        <div className="" style={{ border: "10px solid #efefef" }}>
            <MarkdownView markdown={debouncedText} />
        </div>
    </div>

}

