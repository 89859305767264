import * as PDFJS from 'pdfjs-dist/legacy/build/pdf'
import { ChangeEvent, useRef, useState } from 'react';
import { Item, createItemStoragePath } from '../../../data/Data';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useStoreState } from 'easy-peasy';
import { AppModel } from '../../../store/appModel';
import { UserAccess } from '../../../store/userdata';
import { saveItemType } from '../../../store/firebaseStore';

PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`;

export function EditPdfView(props: { item: Item, path: string, onClose: (v: void) => void }) {
    // const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    // const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    const contentAccess: UserAccess = useStoreState<AppModel>((state) => state.contentUser.contentAccess);
    // const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    // const loadMethod: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
    // useEffect(()=>{
    // }, []);
    const url = "https://firebasestorage.googleapis.com/v0/b/korakademindev.appspot.com/o/test%2FFlax.pdf?alt=media&token=1b83d315-e0cc-4803-8c07-83d25cea0bca";

    const [file, setFile] = useState<File>();

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleUploadClick = async () => {
        if (!file) { return; }
        const storagePath = createItemStoragePath(contentAccess.id, props.item.path!, file.name);
        try {
            const storage = getStorage();
            const storageRef = ref(storage, storagePath);
            const snapshot = await uploadBytes(storageRef, file);
            console.log('Uploaded a blob or file!', snapshot);
            const url = await getDownloadURL(storageRef);
            console.log(url);
            await saveItemType({ t: 'pdf', url: url }, '/' + contentAccess.id + props.item.path!);
        } catch (error) {
            alert(error);
        }
    };

    const canvasDiv = useRef<HTMLDivElement>(null);
    return <>
        <h3>EditPdfView</h3>
        <p>{props.item.path!}</p>
        <input type="file" onChange={handleFileChange} />
        <div>{file && `${file.name} - ${file.type}`}</div>
        <p>{contentAccess.id}</p>
        <button onClick={handleUploadClick}>Upload</button>
        <button onClick={async e => {
            if (!canvasDiv || !canvasDiv.current) return;
            canvasDiv.current.innerHTML = '';
            const pdf: PDFJS.PDFDocumentProxy = await PDFJS.getDocument(url).promise;
            for (let pageIdx = 0; pageIdx < pdf.numPages; pageIdx++) {
                console.log('hoho');
                const canvas: HTMLCanvasElement = document.createElement('canvas');
                const page: PDFJS.PDFPageProxy = await pdf.getPage(pageIdx + 1);
                const viewport = page.getViewport({ scale: 2.5 });
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                canvas.style.width = canvas.style.height = '100%';
                const context = canvas.getContext("2d") as CanvasRenderingContext2D;
                const renderCondext = { canvasContext: context, viewport: viewport }
                await page.render(renderCondext);
                canvasDiv.current.appendChild(canvas);
            }
        }}>EditPdfView</button>
        <div ref={canvasDiv} />
    </>

}