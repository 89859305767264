export function SvgButtonMic(props: { title?: string, active: boolean, onClick?: () => void }) {
    return <div title={props.title} className={`SvgButton ${props.active ? "Active" : ""}`} onClick={e => props.onClick && props.onClick()}>
        <svg width="100%" height="100%" version="1.1" style={{}} viewBox='0 0 100 100'>
            <g transform="matrix(1,0,0,1,-126.126,-165.491)">
                <g transform="matrix(1,0,0,1,0,0.19119)">
                    <path d="M176.021,165.3C203.56,165.3 225.917,187.705 225.917,215.3C225.917,242.896 203.56,265.3 176.021,265.3C148.483,265.3 126.126,242.896 126.126,215.3C126.126,187.705 148.483,165.3 176.021,165.3ZM163.212,193.413L173.657,199.664C174.208,199.994 174.388,200.709 174.058,201.26C173.728,201.811 173.013,201.991 172.462,201.661L162.927,195.955C162.926,196.016 162.926,196.077 162.926,196.139L162.926,200.759L173.657,207.181C174.208,207.51 174.388,208.226 174.058,208.777C173.728,209.328 173.013,209.508 172.462,209.178L162.926,203.471L162.926,208.276L173.657,214.697C174.208,215.027 174.388,215.743 174.058,216.294C173.728,216.845 173.013,217.024 172.462,216.695L162.926,210.988L162.926,212.57C162.926,219.812 168.851,225.737 176.092,225.737C183.334,225.737 189.259,219.811 189.259,212.57L189.259,196.139C189.259,188.897 183.334,182.972 176.092,182.972C169.785,182.972 164.476,187.468 163.212,193.413ZM173.773,234.619L173.773,244.347L168.27,244.347C166.989,244.347 165.95,245.386 165.95,246.667C165.95,247.948 166.989,248.986 168.27,248.986L183.915,248.986C185.196,248.986 186.233,247.948 186.233,246.667C186.233,245.386 185.195,244.347 183.914,244.347L178.412,244.347L178.412,234.619C189.311,233.457 197.829,224.207 197.829,213.005C197.829,211.725 196.79,210.686 195.509,210.686C194.228,210.686 193.19,211.724 193.19,213.005C193.19,222.432 185.519,230.103 176.092,230.103C166.665,230.103 158.995,222.432 158.994,213.004C158.994,211.724 157.956,210.685 156.675,210.685C155.394,210.685 154.355,211.723 154.355,213.004C154.355,224.207 162.874,233.457 173.773,234.619Z" />
                </g>
            </g>
        </svg>
    </div>
}