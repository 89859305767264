import { SplendidGrandPiano } from "../../smplr";
import { StrandFlHarm8 } from "../../smplr/StrandFlHarm8";
import { Testvoice } from "../../smplr/testvoice";

export class PianoSingleton {
    private static instance: PianoSingleton;
    public context: AudioContext;
    public instrument: SplendidGrandPiano;
    // public instrument: StrandFlHarm8;
    private constructor() {
        this.context = new AudioContext();
        this.instrument = new SplendidGrandPiano(this.context);
        // this.instrument = new StrandFlHarm8(this.context);
        console.log('Instrument is created!');
    }

    public static getInstance(): PianoSingleton {
        if (!PianoSingleton.instance) {
            PianoSingleton.instance = new PianoSingleton();
        }
        return PianoSingleton.instance;
    }

    public init() {
        // console.log('init');
    }
}