import React from "react";
import { Marked, Renderer } from '@ts-stack/markdown';
import ReactMarkdown from "react-markdown";
import { NotationView } from "./music/NotationView";
import remarkImages from './remark-plugin/remark-images'
import { TreeItem } from "./general/TreeItem";
import { Keyboard } from "./music/Keyboard";
import { PianoSingleton } from "./music/PianoSingleton";

export function MarkdownView(props: { markdown: string }) {
    return <div className="MD">
        <ReactMarkdown
            remarkPlugins={[remarkImages]}
            components={{
                h4: ({ node, ...props }) => {

                    if (String(props.children).trim().startsWith("kbd:")) {
                        const data = String(props.children).trim().split(':');
                        try {

                            const first = parseInt(data[1]);
                            const last = parseInt(data[2]);
                            const mark = data[3] ? data[3].split(',').map(v => parseInt(v)) : [];
                            const names = data[4] ? data[4].split(',').map(v => parseInt(v)) : [];
                            console.log(first, last, mark, names);

                            return <Keyboard first={first} last={last} scale={20} mark={mark} names={names}
                                playNote={(midiNumber) => {
                                    PianoSingleton.getInstance().instrument.start({ note: midiNumber, velocity: 70 });
                                }} stopNote={(midiNumber) => {
                                    PianoSingleton.getInstance().instrument.stop();
                                }}

                            ></Keyboard>
                        } catch (error) {
                            return <p>Error: {String(error)}</p>
                        }
                    } else {
                        return <NotationView text={String(props.children)} />
                    }
                },
                h5: ({ node, ...props }) => {
                    const filenameWithoutExtension = String(props.children).split('?')[0];
                    console.log(filenameWithoutExtension);
                    if (filenameWithoutExtension.endsWith('.mp3')) {
                        return <div>
                            <audio controls src={String(props.children)} />
                        </div>;
                    }
                    if (filenameWithoutExtension.endsWith('.mp4')) {
                        return <div>
                            <video controls src={String(props.children)} />
                        </div>;
                    }




                    return <p style={{ color: 'red' }}>{String(props.children)}</p>
                },

                h6: ({ node, ...props }) => {
                    const type = String(props.children).trim();

                    if (type.startsWith("docs:")) {
                        const id = type.split(':')[1];
                        const title = type.split(':')[2] || 'Dokument';
                        const href = `https://docs.google.com/document/d/${id}/export?format=pdf`;
                        return <p><a href={href} target="_blank"><strong>{title}</strong></a></p>;
                    }

                    if (type.startsWith("docspdf:")) {
                        const id = type.split(':')[1];
                        const title = type.split(':')[2] || 'Dokument';
                        const href = `https://drive.google.com/file/d/${id}/view?usp=drive_link`;
                        return <p><a href={href} target="_blank"><strong>{title}</strong></a></p>;
                    }
                    //https://drive.google.com/file/d/1d2jz57ABPtCsgFXPy6dm-Pbn3RG1PHz2/view?usp=drive_link
                    //https://drive.google.com/file/d/1Mj2W2flGavoQNchVWWMaKNwjtwA9lwhb/view?usp=drive_link
                    //https://drive.google.com/file/d/1Mj2W2flGavoQNchVWWMaKNwjtwA9lwhb/view?usp=drive_link

                    // https://drive.google.com/file/d/1Mj2W2flGavoQNchVWWMaKNwjtwA9lwhb/view?usp=drive_link
                    // https://drive.google.com/file/d/1d2jz57ABPtCsgFXPy6dm-Pbn3RG1PHz2/view?usp=drive_link

                    return <h2>type: {type}</h2>
                },

                code: ({ node, ...props }) => <>
                    <details>
                        <summary>Mer information</summary>
                        {props.children}
                    </details>
                </>
            }}>
            {props.markdown}
        </ReactMarkdown >


    </div >
}

/*
<TreeItem header={<h1>Hello</h1>} children={props.children} />
*/