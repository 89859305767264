import { Action, Helpers, Thunk, action, thunk } from "easy-peasy";

import {
	UserCredential,
	getAuth,
	signInWithEmailAndPassword,
	signOut,
	onAuthStateChanged,
	User,
} from "firebase/auth";
import { AppModel } from "./appModel";
import { set } from "firebase/database";
import { emailToRefPath, linkTo } from "../utils/utils";
import { logUserAction } from "./firebaseStore";

export type UserStatus =
	| { type: "startup" }
	| { type: "guest" }
	| { type: "checkuser" }
	| { type: "user"; user: User }
	| { type: "error"; msg: string };

export type UserModel = {
	status: UserStatus;
	update: Action<UserModel, UserStatus>;
	setStatus: Thunk<UserModel, UserStatus>;
	logIn: Thunk<UserModel, LoginCredentials>;
	logOut: Thunk<UserModel, void>;
};

export type LoginCredentials = {
	username: string,
	password: string,
}

export const createUser = (): UserModel => {
	return {
		status: { type: "startup" },
		update: action((state, payload) => {
			state.status = payload;
		}),
		setStatus: thunk(async (state, payload, helpers) => {
			state.update(payload);
			if (payload.type === "user") {
				helpers.dispatch.userdata.load(payload.user!.uid);
			} else {
				helpers.dispatch.userdata.clear();
			}
		}),
		logIn: thunk(async (actions, payload, helpers: Helpers<UserModel, AppModel, any>) => {
			actions.update({ type: "checkuser" });
			const auth = getAuth();
			signInWithEmailAndPassword(auth, payload.username, payload.password)
				.then((userCredential) => {
					actions.setStatus({ type: "user", user: userCredential.user! });
					helpers.getStoreActions().location.clearContent();
					logUserAction(userCredential.user!.uid, "login", { username: userCredential.user!.email });
				})
				.catch((error) => {
					const errorCode = error.code;
					const errorMessage = error.message;
					actions.setStatus({ type: "error", msg: errorMessage });
					logUserAction('login-error', emailToRefPath(payload.username), { error: String(error) });
				});
		}),
		logOut: thunk(async (actions, payload, helpers: Helpers<UserModel, AppModel, any>) => {
			signOut(getAuth())
				.then(() => {
					actions.setStatus({ type: "guest" });
					helpers.getStoreActions().location.clearContent();
				})
				.catch((error) => {
					const errorCode = error.code;
					const errorMessage = error.message;
					actions.setStatus({ type: "error", msg: errorMessage });
				});
		}),
	};
};
