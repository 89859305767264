export function SvgButtonKeyboard(props: { title?: string, active: boolean, onClick?: () => void }) {
    return <div title={props.title} className={`SvgButton ${props.active ? "Active" : ""}`} onClick={e => props.onClick && props.onClick()}>
        <svg width="100%" height="100%" version="1.1" style={{}} viewBox='0 0 100 100'>
            <g transform="matrix(1,0,0,1,-1.25684,-165.491)">
                <g transform="matrix(1,0,0,1,0,0.19119)">
                    <path d="M51.152,165.3C78.691,165.3 101.048,187.705 101.048,215.3C101.048,242.896 78.691,265.3 51.152,265.3C23.614,265.3 1.257,242.896 1.257,215.3C1.257,187.705 23.614,165.3 51.152,165.3ZM71.674,190.618L71.674,215.806C71.674,217.127 70.601,218.2 69.28,218.2L68.096,218.2L68.096,234.724C68.096,236.643 69.654,238.2 71.572,238.2L78.524,238.2C80.442,238.2 82,236.643 82,234.724L82,194.094C82,192.176 80.442,190.618 78.524,190.618L71.674,190.618ZM34,218.2L32.491,218.2C31.169,218.2 30.096,217.127 30.096,215.806L30.096,190.618L23.572,190.618C21.654,190.618 20.096,192.176 20.096,194.094L20.096,234.724C20.096,236.643 21.654,238.2 23.572,238.2L30.524,238.2C32.442,238.2 34,236.643 34,234.724L34,218.2ZM39.674,190.618L39.674,215.806C39.674,217.127 38.601,218.2 37.28,218.2L36.096,218.2L36.096,234.724C36.096,236.643 37.654,238.2 39.572,238.2L46.524,238.2C48.442,238.2 50,236.643 50,234.724L50,218.2L48.491,218.2C47.169,218.2 46.096,217.127 46.096,215.806L46.096,190.618L39.674,190.618ZM55.674,190.618L55.674,215.806C55.674,217.127 54.601,218.2 53.28,218.2L52.096,218.2L52.096,234.724C52.096,236.643 53.654,238.2 55.572,238.2L62.524,238.2C64.442,238.2 66,236.643 66,234.724L66,218.2L64.491,218.2C63.169,218.2 62.096,217.127 62.096,215.806L62.096,190.618L55.674,190.618Z" />
                </g>
            </g>
        </svg>
    </div>
}