import { useRef, useState } from "react";

export const MixerSlider = (props: { label: string, size: string, value: number, onValue: (value: number) => void }) => {
    const ref = useRef();
    const [value, setValue] = useState(props.value);
    const setRef = (el: SVGSVGElement) => {
        if (!el) return;
        let startY = 0;
        let startX = 0;
        el.onmousedown = e => {
            startY = e.pageY;
            startX = e.pageX;
            const v = value;
            document.onmousemove = e => {
                const deltaY = ((startY - e.pageY) / 15);
                const deltaX = ((startX - e.pageX) / 15);
                const direction = (deltaY - deltaX) > 0;
                const delta = direction ? Math.max(deltaY, -deltaX) : Math.min(deltaY, -deltaX);
                const newValue = Math.max(0, Math.min(v + delta * 10, 100));
                setValue(newValue);
                if (props.onValue) props.onValue(newValue);
            }
            document.onmouseup = e => {
                document.onmouseup = null;
                document.onmousemove = null;
            }
        }
    }

    return <div className="Flex FlexColumn Gap0" style={{ width: props.size }}>
        <svg className="Meter" ref={setRef} width="100%" height="100%" viewBox="0 0 100 100" style={{ transform: 'rotate(120deg)', cursor: 'pointer' }}>
            <ellipse cx="50" cy="50" rx="42" ry="42" stroke='lightgray' strokeWidth="14" strokeLinecap='round' fill="none" pathLength="120" strokeDasharray="100 120" />
            <ellipse cx="50" cy="50" rx="42" ry="42" stroke='orange' strokeWidth="14" strokeLinecap='round' fill="none" pathLength="120" strokeDasharray={String(value) + ' 120'} />
        </svg>
        <p style={{ fontSize: '70%', textAlign: 'center' }}>{props.label}</p>
    </div>
}