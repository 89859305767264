import {
  Action,
  ActionOn,
  Actions,
  Computed,
  FilterActionTypes,
  Helpers,
  StateMapper,
  Thunk,
  ThunkOn,
  action,
  actionOn,
  computed,
  thunk,
  thunkOn,
} from "easy-peasy";
import { AppModel } from "./appModel";
import { Item, dataToItem, resolvePaths } from "../data/Data";
import { UserModel, UserStatus } from "./user";
import { UserAccess, UserDataModel } from "./userdata";
import { getContentData } from "./firebaseStore";

export type ContentStatus =
  | { type: "empty" }
  | { type: "loading" }
  | { type: "house"; house: Item }
  | { type: "error"; msg: string };

export type ContentModel = {
  status: ContentStatus;
  setStatus: Action<ContentModel, ContentStatus>;
  currentRoom: Computed<ContentModel, Item>;
  currentRoomItems: Computed<ContentModel, Item[]>;
  loadContent: Thunk<ContentModel, boolean>;
};

const CONTENT_LOADING_DELAY = 0;

export const createContentModel = (): ContentModel => {
  return {
    status: { type: "empty" },
    setStatus: action((state, payload) => {
      state.status = payload;
    }),

    loadContent: thunk(async (actions, payload, helpers: Helpers<ContentModel, AppModel, any>) => {
      if (!payload) {
        actions.setStatus({ type: "loading" });
      }
      // const kakadata = await fetch("/kak2020-data-export.json");
      // const data: any = await kakadata.json();
      // let item: Item = dataToItem(data);
      try {
        const data = await getContentData();
        const item: Item = dataToItem(data);;
        resolvePaths(item, "");

        console.log('contenData', item.id);
        // console.log(item);
        setTimeout(async () => {
          actions.setStatus({ type: "house", house: item });
          helpers.getStoreActions().location.getBase("loadContent");
        }, CONTENT_LOADING_DELAY);
      } catch (error) {
        actions.setStatus({ type: "error", msg: String(error) });
      }
    }),

    currentRoom: computed(
      [
        (state) => state.status,
        (state, storeState: StateMapper<FilterActionTypes<any>>) =>
          storeState.userdata.currentAccess,
      ],
      (contentStatus, currentAccess) => {
        if (contentStatus.type == "house" && contentStatus.house.children) {
          let room = contentStatus.house.children.find(
            (item) => item.id === currentAccess.id
          );
          if (room) return room;
        }
        return DEFAULT_ITEM;
      }
    ),


    currentRoomItems: computed(
      [
        (state) => state.status,
        (state, storeState: StateMapper<FilterActionTypes<any>>) =>
          storeState.userdata.currentAccess,
      ],
      (contentStatus, currentAccess) => {
        if (contentStatus.type == "house" && contentStatus.house.children) {
          let room = contentStatus.house.children.find(
            (item) => item.id === currentAccess.id
          );
          if (room) {
            let children = room.children;
            if (children) {
              return children;
            }
          }
        }
        return [DEFAULT_ITEM];
      }
    ),
  };
};

const DEFAULT_ITEM: Item = {
  id: "root",
  children: [],
  access: 0,
  title: "",
  text: "",
  publish: { t: "always" },
};

// if (contentStatus.type == "house" && contentStatus.house.children) {
//   let room = contentStatus.house.children.find(
//     (item) => item.id === userdata.currentAccess.id
//   );
//   if (room) return room;
// }
