import { useStoreState } from "easy-peasy";
import { MusicModel } from "../store/musicstore";
import { Keyboard } from "./music/Keyboard";
import { PianoSingleton } from "./music/PianoSingleton";
import { AppModel } from "../store/appModel";
import FreqSmoothing from "../utils/freqsmoothing";
import { noteFromPitch } from "./pitch/utils";

export function TestPianoView() {
    const music: MusicModel = useStoreState<AppModel>(state => state.music);

    return <div className="BGLightGray Shadow Z1">
        <Keyboard first={40} last={80} scale={20}
            playNote={(midiNumber) => {
                PianoSingleton.getInstance().instrument.start({ note: midiNumber, velocity: 70 });
            }} stopNote={(midiNumber) => {
                PianoSingleton.getInstance().instrument.stop();
            }}
            mark={music.midinote ? [music.midinote] : []}
            mark2={music.midinotes ? music.midinotes : []}
        />
        {/* <p>midinotes {music.midinotes}</p> */}
    </div>

}