import { useStoreState } from "easy-peasy";
import { ContentModel } from "../../../store/content";
import { AppModel } from "../../../store/appModel";
import { Item, filterAccessStudent, filterByType, filterPublished } from "../../../data/Data";
import { ContentUserModel } from "../../../store/contentuser";
import { CenterContent } from "../../CenterContent";
import { FrontItemDisplay } from "../FrontView";
import { ContentFrame } from "../../content/ContentFrame";
import { CardsWrapper } from "../../content/CardsWrapper";
import { MarkdownView } from "../../MarkdownView";
import { RoleSelectorView } from "../RoleSelectorView";

export function FrontKdistStudent() {
    const content: ContentModel = useStoreState<AppModel>((state) => state.content);
    const currentRoomItems = content.currentRoomItems as unknown as Item[];
    // const items: Item[] = filterPublished(filterAccessStudent(filterByType(currentRoomItems, { t: 'info' })));
    const contentUser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    // const fullName: string = useStoreState<AppModel>((state) => state.contentUser.fullName);
    // const firstItem: Item | undefined = items[0];
    // const loading = content.status.type === 'loading' ? <h3>Loading</h3> : <></>;
    const loading = content.status.type === 'loading' ? <h3>Laddar sidinnehåll...</h3> : <></>;

    const items: Item[] = filterPublished(filterAccessStudent(filterByType(currentRoomItems, { t: 'info' })));
    const firstItem = items[0];
    if (!firstItem) return <>Can not dipslay first item</>;

    return <CenterContent css={{ color: 'black' }}>
        <ContentFrame css={{ padding: '1rem 1.5rem 1.5rem', backgroundColor: 'rgba(255,255,255,1)', color: 'black' }}>
            <h1 className="Jumbo" >Välkommen, {contentUser.contentUserData.firstName}!</h1>
            {loading}
            <div className="Flex JustifyStart" >
                <div className="MaxW40Rem Large">
                    <MarkdownView markdown={firstItem.text} />
                </div>
            </div>
        </ContentFrame>
        <RoleSelectorView />
        <CardsWrapper>
            <>
                {firstItem.children && filterPublished(firstItem.children).map((item, index) => <FrontItemDisplay item={item} key={item.id} />)}
            </>



        </CardsWrapper>
    </CenterContent >
}


