import { Action, ActionCreator, Actions, action, useStoreActions, useStoreState } from 'easy-peasy';
import { AppModel } from '../store/appModel';
import { LoginCredentials, UserModel, UserStatus } from '../store/user';
import { MutableRefObject, RefObject, useEffect, useRef, useState } from 'react';
import { User, getAuth } from 'firebase/auth';
import { useModal } from '../hooks/useModal';
import { motion } from "framer-motion"
import { SvgButtonClose } from './buttons/SvgButtonClose';
export function UserView() {

    const user: UserModel = useStoreState<AppModel>((state) => state.user);
    const logIn: ActionCreator<LoginCredentials> = useStoreActions<AppModel>((actions) => actions.user.logIn);
    const logOut: ActionCreator<boolean> = useStoreActions<AppModel>((actions) => actions.user.logOut);
    const setStatus: ActionCreator<UserStatus> = useStoreActions<AppModel>((actions) => actions.user.setStatus);
    const { isModalOpen, openModal, closeModal, modalContent } = useModal();

    useEffect(() => {
        if (user.status.type === "user") {
            closeModal();
        };
    }, [user.status]);

    useEffect(() => {
        getAuth().onAuthStateChanged((user: User | null) => {
            if (user) {
                setStatus({ type: "user", user: user });
            } else {
                setStatus({ type: "guest" });
            }
        });
    }, []);

    return <>
        {(user.status.type === "guest" || user.status.type === 'error') && <button className="Small" onClick={_ => openModal(<Modal onClose={() => {
            setStatus({ type: "guest" });
            closeModal();
        }} data={user} />)}>Logga in</button>}
        {(user.status.type === "user") && <></>}
        {(user.status.type === "checkuser") && <p>Checking user identity...</p>}
        {(user.status.type === "startup") && <p>Startup</p>}
        {isModalOpen && modalContent}
    </>
}

function Modal(props: { onClose: () => void, data: any }) {
    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const logIn: ActionCreator<LoginCredentials> = useStoreActions<AppModel>((actions) => actions.user.logIn);
    const user: UserModel = useStoreState<AppModel>((state) => state.user);
    const setStatus: ActionCreator<UserStatus> = useStoreActions<AppModel>((actions) => actions.user.setStatus);
    useEffect(() => {
        setStatus({ type: "guest" });
    }, []);

    let formContent = <p>Hoho</p>;
    switch (user.status.type) {
        case 'guest': formContent = <div><form className="FormGrid" onSubmit={e => {
            e.preventDefault();
            logIn({ username: usernameRef.current!.value, password: passwordRef.current!.value });
            console.log(usernameRef.current?.value, passwordRef.current?.value);
        }}>
            <label htmlFor="username">E-post</label>
            <input ref={usernameRef} type="text" name="username" placeholder="username" />
            <label htmlFor="password">Lösenord</label>
            <input ref={passwordRef} type="password" name="password" placeholder="password" />
            <button type="submit">Logga in</button>
        </form></div>; break;
        case 'checkuser': formContent = <div>
            <p>Kontrollerar behörighet...</p>
            <img style={{ width: '3rem' }} src="/assets/svg-loaders/grid.svg" />
        </div>; break;
        case 'user': formContent = <div><p>Inloggad</p></div>; break;
        default: formContent = <div>
            {user.status.type === 'error' && <div className="Flex FlexColumn" style={{ width: '70%' }}>
                <h4 >Inloggning misslyckades.</h4>
                <p>Har du angett den e-postadress som du angav när du gjorde ansökan till Körakademin?</p>
                <p>Har du angett det lösenord som du fått i ditt välkomstbrev den 11 september 2023?</p>
                <button onClick={() => {
                    setStatus({ type: "guest" });
                }}>Pröva logga in igen</button>
            </div>}
        </div>; break;
    }

    return (
        <div className="Modal">
            <motion.div className="ModalContent "
                initial={{ transform: "translateY(-100%)" }}
                animate={{ transform: "translateY(0)" }}
                transition={{ duration: .2 }}
            >
                <header className="JustifyBetween" >
                    <h3>Logga in</h3>
                    <SvgButtonClose onClick={props.onClose} />
                </header>
                {formContent}
            </motion.div>

        </div >
    );
}
