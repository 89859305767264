import { Action, Actions, Computed, Helpers, Thunk, action, computed, thunk } from "easy-peasy";
import { Access } from "../data/Data";
import { setTokenSourceMapRange } from "typescript";
import { update } from "firebase/database";
import { AppModel } from "./appModel";
import { emailToRefPath, linkTo } from "../utils/utils";
import { ContentUserModel } from "./contentuser";
import { getUserData, setUserDataCurrentAccess } from "./firebaseStore";

export type UserAccess = {
	access: Access;
	id: string;
};
export const DEFAULT_ACCESS: UserAccess = { id: "kak", access: 0 };

export type UserClaims = {
	accesses: UserAccess[];
	current: UserAccess;
};

export type UserData = {
	firstName: string;
	lastName: string;
	claims: UserClaims;
	uid?: string;
	email?: string;
};

export const DEFAULT_USERDATA: UserData = {
	firstName: 'Anonymous',
	lastName: "Guest",
	claims: { accesses: [DEFAULT_ACCESS], current: DEFAULT_ACCESS }
};

export type UserDataStatus =
	| { type: "anonymous" }
	| { type: "loading" }
	| { type: "userdata"; userdata: UserData }
	| { type: "error"; msg: string };

export type UserDataModel = {
	currentAccess: UserAccess;
	status: UserDataStatus;
	update: Action<UserDataModel, UserDataStatus>;
	setStatus: Thunk<UserDataModel, UserDataStatus>;
	load: Thunk<UserDataModel, string>;
	clear: Thunk<UserDataModel, void>;
	fullName: Computed<UserDataModel, string>;
};


export const createUserData = (): UserDataModel => {
	return {
		currentAccess: DEFAULT_ACCESS,
		status: { type: "anonymous" },

		update: action((state, payload) => {
			state.status = payload;
			if (payload.type == "userdata") {
				state.currentAccess = payload.userdata.claims.current;
			} else {
				state.currentAccess = DEFAULT_ACCESS;
			}
		}),

		setStatus: thunk(async (actions, payload, helpers: Helpers<UserDataModel, AppModel, any>) => {
			actions.update(payload);
			if (payload.type == "userdata") {
				helpers.getStoreActions().contentUser.setContentUserData(payload.userdata);

				// spara current till firebase...
				if (payload.userdata.uid) {
					setUserDataCurrentAccess(payload.userdata.uid!, payload.userdata.claims.current);
				} else {
					console.log('ingen uid');
				}



				helpers.getStoreActions().contentUser.setContentAccess(payload.userdata.claims.current);
			} else {
				helpers.getStoreActions().contentUser.setContentUserData(DEFAULT_USERDATA);
				helpers.getStoreActions().contentUser.setContentAccess(DEFAULT_ACCESS);
			}
		}),

		load: thunk(async (actions, payload, helpers: Helpers<UserDataModel, AppModel, any>) => {
			const uid = emailToRefPath(payload);
			console.log(uid);
			actions.setStatus({ type: "loading" });
			// const response = await fetch("/usersdata.json");
			// const obj = await response.json();
			// console.log('obj', obj);
			// let keys = Object.keys(obj);
			// if (!Object.keys(obj).includes(uid)) {
			// 	actions.setStatus({ type: "error", msg: "alias not found" });
			// 	return;
			// }
			// let userdata: UserData = obj[uid];
			// console.log(userdata);
			// if (!userdata.claims.current)
			// 	userdata.claims.current = userdata.claims.accesses[0];
			// // actions.setStatus({ type: "userdata", userdata });
			try {
				const dbUserData: UserData = await getUserData(uid);
				dbUserData.uid = uid;
				console.log('dbUserData', dbUserData);
				actions.setStatus({ type: "userdata", userdata: dbUserData })
			} catch (error) {
				actions.setStatus({ type: "error", msg: String(error) });
			}
		}),

		clear: thunk(async (actions, payload) => {
			actions.setStatus({ type: "anonymous" });
		}),

		fullName: computed([(state) => state.status], (status) => {
			if (status.type != "userdata") return "Anonymous";
			return `${status.userdata.firstName} ${status.userdata.lastName}`;
		}),
	};
};
