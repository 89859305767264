import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@reach/tabs";
import "@reach/tabs/styles.css";
import { ContentView } from "../ContentView";
import { Access, Item, ItemType, Publish, sortItems, strToAccess, strToPublish, strToType, usersByAccess } from "../../data/Data";
import { ActionCreator, useStoreActions, useStoreState } from "easy-peasy";
import { AppModel } from "../../store/appModel";
import { useEffect, useRef, useState } from "react";
import { Menu, MenuButton, MenuDivider, MenuItem } from "@szhsin/react-menu";
import { UserData, UserDataModel } from "../../store/userdata";
import { AdminContentView } from "./views/AdminContentView";
import { UsersModel } from "../../store/users";
import { AdminUserView } from "./users/AdminUserView";
import { SideView } from "../SideView";
import { CenterContent } from "../CenterContent";
import { LocationModel } from "../../store/location";
import { AdminAddUserView } from "./users/AdminAddUserView";
import { AdminEditUserView } from "./users/AdminEditUserView";
import { ScorxListView } from "./views/ScorxListView";
import { ImportUsersView } from "./users/ImportUsersView";
import { MarkdownEditorLite } from "./views/MarkdownEditor";
import { AdminLogin2 } from "./users/AdminLogin";
import { AdminConfirmed, AdminConfirmedExport } from "./users/AdminConfirmed";

export function AdminMain2() {
    return <div className="CenterContent Centered" >
        <Tabs>
            <TabList>
                <Tab>Innehåll</Tab>
                <Tab>Användare</Tab>
                <Tab>Användarhistorik</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <AdminContentView />
                </TabPanel>
                <TabPanel>
                    <AdminUsers />
                </TabPanel>
                <TabPanel>
                    <p>Användarhistorik</p>
                </TabPanel>
            </TabPanels>
        </Tabs>
    </div>
}

export function AdminMain() {

    const location: LocationModel = useStoreState<AppModel>((state) => state.location);

    let content = <><h3>No admin view</h3></>;
    switch (location.path) {
        case "admin": content = <AdminContentView />; break;
        case "admin/users": content = <AdminUsers />; break;
        case "admin/createuser": content = <AdminAddUserView />; break;
        case "admin/confirmed": content = <AdminConfirmedExport />; break;
        case "admin/userhistory": content = <h3>userhistory</h3>; break;
        case "admin/scorxlist": content = <ScorxListView />; break;
        // case "admin/edituser": content = <AdminEditUserView />; break;
        case "admin/importusers": content = <ImportUsersView />; break;
        case "admin/scrapbook": content = <MarkdownEditorLite markdown="" onChange={(s: string) => { }} />; break;
        case "admin/login": content = <AdminLogin2 />; break;
        default:
            if (location.path.includes('admin/edituser')) content = <AdminEditUserView />;
    }

    return <>
        <SideView>
            <h3>Admin</h3>
            <div className="Flex FlexColumn">
                <a href="#admin" className={`TOC TOCChapter ${location.path === "admin" ? 'selected' : ''}`}>Kursmaterial</a>
                <a href="#admin/users" className={`TOC TOCChapter ${location.path === "admin/users" ? 'selected' : ''}`}>Användare</a>
                <a href="#admin/createuser" className={`TOC TOCSubchapter ${location.path === "admin/createuser" ? 'selected' : ''}`}>Skapa användare</a>
                <a href="#admin/edituser" className={`TOC TOCSubchapter ${location.path === "admin/edituser" ? 'selected' : ''}`}>Redigera användare</a>
                <a href="#admin/importusers" className={`TOC TOCSubchapter ${location.path === "admin/importusers" ? 'selected' : ''}`}>Importera användare</a>
                <a href="#admin/confirmed" className={`TOC TOCChapter ${location.path === "admin/confirmed" ? 'selected' : ''}`}>Bekräftade deltagare</a>
                <a href="#admin/userhistory" className={`TOC TOCChapter ${location.path === "admin/userhistory" ? 'selected' : ''}`}>Användarhistorik</a>
                <a href="#admin/scorxlist" className={`TOC TOCChapter ${location.path === "admin/scorxlist" ? 'selected' : ''}`}>Scorxlista</a>
                <a href="#admin/scrapbook" className={`TOC TOCChapter ${location.path === "admin/scrapbook" ? 'selected' : ''}`}>Markdown test</a>
                <a href="#admin/login" className={`TOC TOCChapter ${location.path === "admin/login" ? 'selected' : ''}`}>Login</a>
            </div>
        </SideView>
        <CenterContent>
            {content}
        </CenterContent>
    </>
}


type FilterUsersView = 'all' | 'kak-student' | 'kak-student-verified';

export function AdminUsers() {
    const usersdata: UsersModel = useStoreState<AppModel>((state) => state.users);
    const load: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.users.load);
    const [showAdd, setShowAdd] = useState(false);
    const [search, setSearch] = useState('');
    const [filterKak, setFilterKak] = useState(false);
    const [filterKdist, setFilterKdist] = useState(false);
    useEffect(() => {
        load();
    }, []);

    let userViews;
    let userdatas: UserData[] = [];
    if (usersdata.status.type === 'users') {
        const keys = Object.keys(usersdata.status.usersdata);
        userdatas = userViews = Object.entries(usersdata.status.usersdata).map((data, idx) => {
            return data[1] as UserData;
        });

        if (search) {
            userdatas = userdatas.filter(userdata => {
                const name = userdata.lastName + ' ' + userdata.firstName + ' ' + userdata.email + ' ' + userdata.uid;
                return name.toLowerCase().includes(search.toLowerCase());
            });
        }
        if (filterKak) {
            userdatas = usersByAccess(userdatas, { access: 1, id: 'kak' });
        }
        if (filterKdist) {
            userdatas = usersByAccess(userdatas, { access: 1, id: 'kdist' });
        }

        userViews = userdatas.map((userdata, idx) => {
            return <AdminUserView key={idx} userdata={userdata} />
        });
    }

    return <div>
        <div className="Flex AlignCenter JustifyBetween Pad05">
            <h3>Användare</h3>
            <p>{userdatas.length}</p>
            <button onClick={e => { setFilterKak(!filterKak) }}>Filter kak</button>
            <button onClick={e => { setFilterKdist(!filterKdist) }}>Filter kdist</button>
            <div className="Flex AlignCenter">
                <input type="search" placeholder="Sök" value={search} onChange={e => {
                    setSearch(e.target.value);
                }} />
                <Menu menuButton={<MenuButton className="Small">☰</MenuButton>}>
                    <MenuItem onClick={e => { load(); }}>Ladda användardata</MenuItem>
                    <MenuItem onClick={e => { setShowAdd(true) }}>Add user</MenuItem>
                </Menu>
            </div>
        </div>
        {userViews}
        {usersdata.status.type === 'error' && <p>error: {usersdata.status.msg}</p>}
        {usersdata.status.type === 'loading' && <p>Loading users...</p>}
        <button onClick={e => {
            load();
        }}>Load users</button>
    </div>
}

export type EditItemStatus = { type: "none" }
    | { type: "markdown", item: Item, path: string }
    | { type: "other", item: Item, path: string }
    | { type: "addChild", level: number, item: Item, path: string, crumbs: string[] }
    | { type: "delete", item: Item, path: string, crumbs: string[] }
    | { type: "resources", item: Item, path: string }
    | { type: "editRosetta", item: Item, path: string }
    | { type: "editPdf", item: Item, path: string }

// type AdminItemStatus = "none" | "title";
export const LABELS = ['rum', 'hylla', 'bok', 'kapitel', 'underkapitel'];
export const ARTIKEL = ['detta', 'denna', 'denna', 'detta', 'detta'];




