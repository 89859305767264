import init, { fib, testnotes, testnotes2, graphics } from 'rust-libs'
import { PlayNotesData, PlayPositionsData, Playhandler } from "../../play/playhandler";
import md5 from 'md5';

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from '../../hooks/useDebounce';
import { set } from 'firebase/database';
import { MusicModel } from '../../store/musicstore';
import { useStoreState } from 'easy-peasy';
import { AppModel } from '../../store/appModel';
import { SvgButtonPlay } from '../buttons/SvgButtonPlay';
import { SvgButtonStop } from '../buttons/SvgButtonStop';
let inited = false;
init().then(() => {
    console.log('init done');
    inited = true;
})

// type NotationProps = { text: string, timeout: number };
// type NotationState = { text: string; };

// export class NotationView extends React.Component<NotationProps, NotationState> {
//     private elementRef: React.RefObject<HTMLDivElement>;
//     private playdata: PlayNotesData = { notes: [], duration: 0 };
//     private playpositions: PlayPositionsData = { positions: [], duration: 0, width: 0, height: 0 };
//     private element: HTMLElement | undefined = undefined;
//     private id: string = '';

//     constructor(props: any) {
//         super(props);
//         this.state = { text: this.props.text }; // '| clef G |sp3 | 0 1 |bl'
//         this.elementRef = React.createRef();
//         this.debounce(this.state.text, this.props.timeout);
//         Playhandler.getInstance().instrument.loaded().then(() => {
//             console.log('Playhandler instrument loaded...');
//         });
//     }

//     private debounceHandle: number = 0;
//     private debounce(value: string, delay: number) {
//         this.debounceHandle && clearTimeout(this.debounceHandle);
//         this.debounceHandle = window.setTimeout(() => {
//             this.debounceHandle = 0;
//             if (this.elementRef.current) {
//                 try {

//                     this.id = md5(value);

//                     let data = testnotes2(value, 0.025);
//                     let segments = data.split('$$$');
//                     let svg = segments[0];
//                     this.playdata = JSON.parse(segments[1]);
//                     this.playpositions = JSON.parse(segments[2]);
//                     this.elementRef.current.innerHTML = svg;
//                     this.element = this.elementRef.current;
//                 } catch (error) {
//                     console.log(error);
//                 }
//             }
//         }, delay);
//     }

//     render() {
//         return <div className="Flex FlexColumn Pad05">
//             <div className="Flex">
//                 <button onClick={e => {
//                     Playhandler.getInstance().play(this.playdata, this.playpositions, this.id, this.element);
//                 }}>Play</button>
//                 <button onClick={e => {
//                     Playhandler.getInstance().stop();
//                 }}>Stop</button>
//                 <p>{this.id}</p>
//                 <p>{this.props.text}</p>
//             </div>
//             <div ref={this.elementRef} style={{ position: 'relative' }}></div>
//         </div>
//     }
// }

const expensiveCalculation = (text: string): string[] => {
    try {
        console.log('RECALC NOTATION', text);
        // console.log(text, '|clef G');
        let data = testnotes2(text, 0.025);
        console.log(data.substring(0, 50));
        return data.split('$$$');
    } catch (error) {
        console.log(error);
    }
    return ['aaa'];
};


export function NotationView(props: { text: string, onPlaydata?: (playdata: any) => void, onPlayPositions?: (playpositions: any) => void }) {
    const svgRef = useRef<HTMLDivElement | null>(null);
    const id = md5(props.text);
    // const [id, setId] = useState('abc123');
    // const [playdata, setPlaydata] = useState<PlayNotesData>({ notes: [], duration: 0 });
    // const [playpositions, setPlaypositions] = useState<PlayPositionsData>({ positions: [], duration: 0, width: 0, height: 0 });    
    const [code, setCode] = useState("");
    const music: MusicModel = useStoreState<AppModel>((state) => state.music);
    const [picMode, setPicMode] = useState(false);

    useEffect(() => {
        console.log('useEffect', props.text);
        const picMode = props.text.startsWith('pic:');
        setPicMode(picMode);
        setCode(picMode ? props.text.substring(4) : props.text);
    }, [props.text])



    const segments = useMemo(() => expensiveCalculation(code), [code]);

    useEffect(() => {
        Playhandler.getInstance().instrument.loaded().then(() => {
            console.log('Playhandler instrument loaded...');
        });
        svgRef.current!.innerHTML = segments[0];
        if (props.onPlaydata) {
            try {
                const playdata = JSON.parse(segments[1]);
                props.onPlaydata(playdata);

            } catch (error) {
                console.log(error);
            }
        }
        if (props.onPlayPositions) {
            try {
                const playpositions = JSON.parse(segments[2]);
                props.onPlayPositions(playpositions);

            } catch (error) {
                console.log(error);
            }
        }

    }, [segments]);

    const btn = !picMode && (music.scorePlaybackStatus.type === 'playing' && music.scorePlaybackStatus.id === id) ? <SvgButtonStop
        onClick={e => { Playhandler.getInstance().stop(); e.stopPropagation(); }}

    /> : <SvgButtonPlay

        onClick={e => {
            const playdata = JSON.parse(segments[1]);
            const playpositions = JSON.parse(segments[2]);
            Playhandler.getInstance().play(playdata, playpositions, id, svgRef.current!);
            e.stopPropagation();
        }} />;

    return <div className="Pad05">
        <div ref={svgRef} style={{ position: 'relative' }} >PREV</div>
        {!picMode && btn}
    </div>


}




/*
export function NotationView3(props: { text: string }) {
    const [text, setText] = useState(props.text);
    const debouncedText = useDebounce<string>(text, 500);
    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        console.log('debounced changed', debouncedText);
    }, [debouncedText]);

    return <div className="Flex FlexColumn Pad05">
        <div className="Flex">
            <h3>NotationView3</h3>
            <button onClick={e => {
                // Playhandler.getInstance().play(this.playdata, this.playpositions, this.element);
            }}>Play</button>
            <button onClick={e => {
                // Playhandler.getInstance().stop();
            }}>Stop</button>
            <p>{props.text}</p>
        </div>
        <div ref={elementRef} style={{ position: 'relative' }}></div>
    </div>
}





type Notation2Props = { text: string };
type Notation2State = { text: string; };

export class NotationView2 extends React.Component<Notation2Props, Notation2State> {
    private elementRef: React.RefObject<HTMLDivElement>;
    private playdata: PlayNotesData = { notes: [], duration: 0 };
    private playpositions: PlayPositionsData = { positions: [], duration: 0, width: 0, height: 0 };
    private element: HTMLElement | undefined = undefined;

    constructor(props: any) {
        super(props);
        this.state = { text: this.props.text }; // '| clef G |sp3 | 0 1 |bl'
        this.elementRef = React.createRef();
        this.debounce(this.state.text, 1000);
    }

    private debounceHandle: number = 0;
    private debounce(value: string, delay: number) {
        this.debounceHandle && clearTimeout(this.debounceHandle);
        this.debounceHandle = window.setTimeout(() => {
            this.debounceHandle = 0;
            if (this.elementRef.current) {
                try {
                    let data = testnotes2(value, 0.025);
                    let segments = data.split('$$$');
                    let svg = segments[0];
                    this.playdata = JSON.parse(segments[1]);
                    this.playpositions = JSON.parse(segments[2]);
                    this.elementRef.current.innerHTML = svg;
                    this.element = this.elementRef.current;
                } catch (error) {
                    console.log(error);
                }
            }
        }, delay);
    }


    render() {
        return <div className="Flex FlexColumn Pad05">
            <div className="Flex">
                <h3>TestNotation2</h3>
                <button onClick={e => {
                    Playhandler.getInstance().play(this.playdata, this.playpositions, this.element);
                }}>Play</button>
                <button onClick={e => {
                    Playhandler.getInstance().stop();
                }}>Stop</button>
            </div>
            <textarea value={this.state.text} onChange={e => {
                this.setState({ text: e.target.value });
                this.debounce(e.target.value, 300)
            }} style={{ width: '50rem', height: '4rem' }} />

            <div ref={this.elementRef} style={{ position: 'relative' }}></div>
        </div>
    }

}
*/

