import { useState } from "react";

export function TreeItem(props: { header: JSX.Element | JSX.Element[], children: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[], css?: any, open?: boolean, headerClassName?: string }) {
    const [show, setShow] = useState(props.open || false);
    const headerClassName = props.headerClassName || "Flex AlignBase";
    return <div className="TreeItem" style={props.css}>
        <div className={headerClassName} style={{ cursor: 'pointer' }}>
            {<span onClick={_ => setShow(!show)}>{show ? '\u25BC' : '\u25B6'}</span>}
            {props.header}
        </div>
        {show && props.children}
    </div>
}

export function TreeItemLink(props: { header: JSX.Element | JSX.Element[], children: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[], css?: any, open?: boolean, headerClassName?: string, href: string }) {
    const [show, setShow] = useState(props.open || false);
    const headerClassName = props.headerClassName || "Flex AlignBase";
    return <div className="TreeItem" style={props.css}>
        <div className={headerClassName} style={{ cursor: 'pointer' }}>
            {<span onClick={_ => setShow(!show)}>{show ? '\u25BC' : '\u25B6'}</span>}
            <a href={props.href}>{props.header}</a>
        </div>
        {show && props.children}
    </div>
}