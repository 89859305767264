import { Action, Thunk, action, thunk } from "easy-peasy";
import { Recording, Recordings, ScorxItem } from "../components/music/player/PlayerTypes";
import WebAudio from "../audio/WebAudio";
import { round2 } from "../utils/utils";

WebAudio.init({ unmute: true, allowBackgroundPlayback: true, forceIOSBehavior: false });

export type ScorxPlayerStatus = { type: 'empty' } | { type: 'loading', progress: number } | { type: 'recordings', recordings: Recordings, start: (time: number, leftTime: number, rightTime: number, loop: boolean) => Promise<boolean>, stop: () => Promise<boolean>, setMixer: (items: { id: string, level: number }[]) => void };

export type ScorxPlayerModel = {
    status: ScorxPlayerStatus,
    setStatus: Action<ScorxPlayerModel, ScorxPlayerStatus>,
    loadRecordings: Thunk<ScorxPlayerModel, Recordings>,
    positionStatus: ScorxPlayerPositionStatus,
    setPositionStatus: Action<ScorxPlayerModel, ScorxPlayerPositionStatus>,
    setMixerLevel: Action<ScorxPlayerModel, { id: string, level: number }[]>,
}

export const createScorxPlayerModel = (): ScorxPlayerModel => {
    return {
        status: { type: 'empty' },
        setStatus: action((state, payload) => {
            state.status = payload;
        }),
        loadRecordings: thunk(async (actions, recordings: Recordings) => {
            ScorxPlayer.getInstance().stop();
            actions.setStatus({ type: 'loading', progress: 0 });
            const promises = Object.values(recordings).map(recording => loadRecordingAudio(recording));
            const audioBuffers = await Promise.all(promises);
            Object.values(recordings).forEach((recording, index) => {
                console.log('recording', recording);
            });

            ScorxPlayer.getInstance().statusChange = (positionStatus: ScorxPlayerPositionStatus) => {
                actions.setPositionStatus(positionStatus);
            };

            actions.setStatus({
                type: 'recordings',
                recordings,
                start: ScorxPlayer.getInstance().start,
                stop: ScorxPlayer.getInstance().stop,
                setMixer: ScorxPlayer.getInstance().setMixerLevel,
            });

            ScorxPlayer.getInstance().setRecordings(recordings);
            actions.setPositionStatus({ type: 'stopped', position: 0, duration: ScorxPlayer.getInstance().duration, leftTime: 0, rightTime: ScorxPlayer.getInstance().duration, loop: false })
        }),
        positionStatus: { type: 'stopped', position: 0, duration: 0, leftTime: 0, rightTime: 0, loop: false },
        setPositionStatus: action((state, payload: ScorxPlayerPositionStatus) => {
            state.positionStatus = payload;
        }),
        setMixerLevel: action((state, payload: { id: string, level: number }[]) => {



        }),
    }
}

const loadRecordingAudio = (recording: Recording): Promise<boolean> => {
    const request = new XMLHttpRequest();
    return new Promise<any>((res, rej) => {
        if (recording.type.t === 'audiobuffer') {
            request.open('GET', recording.type.url, true);
            request.responseType = 'arraybuffer';
            request.onload = () => {
                if (request.status != 200) {
                    console.log('Could not laod recording');
                    rej('Could not load recording ' + recording);
                }
                const arrayBuffer: ArrayBuffer = request.response;
                const audioContext = new AudioContext();
                try {
                    audioContext.decodeAudioData(arrayBuffer, audioBuffer => {
                        recording.buffer = audioBuffer;
                        res(true);
                    })
                } catch (e) {
                    const message = `Can not extract audio from ${recording.filename} channel`;
                    rej(message);
                }
            };
            request.onerror = e => rej('Loading error $recording : $e');
            request.send();

        } else {
            rej('Recording type is not audiobuffer');
        }
    });
};


export type ScorxPlayerPositionStatus = { type: 'stopped' | 'playing', position: number, duration: number, leftTime: number, rightTime: number, loop: boolean };

export default class ScorxPlayer {
    private static instance: ScorxPlayer;
    private status: ScorxPlayerPositionStatus = { type: 'stopped', position: 0, duration: 0, leftTime: 0, rightTime: 0, loop: false };
    private sourceBuffers: AudioBufferSourceNode[] = [];
    private mixerGainNodes: Map<string, GainNode> = new Map();
    private recordings: Recording[] = [];
    private deltaTime: number = 0;
    public duration: number = 0;
    public position: number = 0;
    private isPlaying: boolean = false;
    public leftTime: number = 0;
    public rightTime: number = 0;
    public loop: boolean = false;

    private constructor() {
        console.log('ScorxPlayer created', this.mixerGainNodes);
        setInterval(this.onTimerLoop, 50);
    }

    public static getInstance(): ScorxPlayer {
        if (!ScorxPlayer.instance) ScorxPlayer.instance = new ScorxPlayer();
        return ScorxPlayer.instance;
    }

    onTimerLoop = () => {
        if (!this.isPlaying) return;
        this.position = round2(WebAudio.context.currentTime - this.deltaTime);
        if (this.position > this.rightTime) {
            this.stop();
            if (this.loop) {
                this.start(this.leftTime, this.leftTime, this.rightTime, this.loop);
            }
        } else {
            this.statusChange({ type: 'playing', position: this.position, duration: this.duration, leftTime: this.leftTime, rightTime: this.rightTime, loop: this.loop });
        }
    }

    //-----------------------------------------------------------

    setRecordings(recordings: Recordings) {
        this.stop();
        this.recordings = Object.values(recordings);
        if (this.recordings.length > 0 && this.recordings[0].buffer) {
            this.duration = round2(this.recordings[0].buffer!.duration);
            this.position = 0;
            this.rightTime = this.duration;
        } else {
            alert('Can not find recording durations');
        }
    }

    setMixerLevel = (items: { id: string, level: number }[]) => {
        console.log(items);
        console.log(this.mixerGainNodes);
        items.forEach(item => {

            const gainNode = this.mixerGainNodes.get(item.id);
            if (gainNode) {
                gainNode.gain.setValueAtTime(item.level, 0);
            }
        });
    }

    start = async (time: number, startTime: number, stopTime: number, loop: boolean): Promise<boolean> => {
        await this.stop();
        this.leftTime = startTime;
        this.rightTime = stopTime;
        this.loop = loop;
        await WebAudio.context.resume();
        this.mixerGainNodes.clear();
        this.sourceBuffers = this.recordings.map(recording => {
            const sourceBuffer = WebAudio.context.createBufferSource();
            const gainNode = WebAudio.context.createGain();
            if (recording.buffer)
                sourceBuffer.buffer = recording.buffer;
            sourceBuffer.connect(gainNode);
            gainNode.connect(WebAudio.context.destination);
            gainNode.gain.setValueAtTime(recording.level, 0);
            this.mixerGainNodes.set(recording.id, gainNode);
            sourceBuffer.start(0, time);
            return sourceBuffer;
        });
        this.deltaTime = WebAudio.context.currentTime - time;
        this.statusChange({ type: 'playing', position: WebAudio.context.currentTime - this.deltaTime, duration: this.duration, leftTime: this.leftTime, rightTime: this.rightTime, loop: this.loop });
        this.isPlaying = true;
        return Promise.resolve(true);
    }

    stop = (): Promise<boolean> => {
        this.sourceBuffers.forEach(sourceBuffer => {
            sourceBuffer.stop();
        });
        this.isPlaying = false;
        this.statusChange({ type: 'stopped', position: this.position, duration: this.duration, leftTime: this.leftTime, rightTime: this.rightTime, loop: this.loop });
        return Promise.resolve(true);
    }

    statusChange = (status: ScorxPlayerPositionStatus) => {

    }

}