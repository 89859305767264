import { FrontKdistGuest } from "./FrontKdistGuest";
import { FrontKdistStudent } from "./FrontKdistStudent";

export function FrontKdistAdmin() {
    return <>
        <FrontKdistStudent />
        <FrontKdistGuest />
    </>
}

