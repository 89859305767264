import { Action, Helpers, Thunk, action, thunk } from "easy-peasy";
import { Thread, ThreadItem, getThreadsByPath } from "../data/Data";
import { getDiscussions } from "./firebaseStore";
import { AppModel } from "./appModel";

export type DiscussionStatus =
    | { type: "empty" }
    | { type: "loading" }
    | { type: "items", threadItems: ThreadItem[], threads: Thread[] }

export type DiscussionModel = {
    status: DiscussionStatus,
    setStatus: Action<DiscussionModel, DiscussionStatus>,
    loadDiscussions: Thunk<DiscussionModel, string>,
}

export const createDiscussionModel = (): DiscussionModel => {
    return {
        status: { type: "empty" },
        setStatus: action((state, payload) => {
            state.status = payload;
        }),
        loadDiscussions: thunk(async (actions, payload, helpers: Helpers<DiscussionModel, AppModel, any>) => {
            actions.setStatus({ type: "loading" });
            const data = await getDiscussions(payload);
            const threads = getThreadsByPath(data, payload);



            actions.setStatus({ type: "items", threadItems: [], threads: threads });
        }),
    }
}

function getDisussions() {
    throw new Error("Function not implemented.");
}

