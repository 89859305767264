import { useStoreState } from "easy-peasy";
import { ContentUserModel } from "../../../store/contentuser";
import { AppModel } from "../../../store/appModel";
import { UserDataModel } from "../../../store/userdata";
import { UserAccessView } from "./AccessView";
import { UserModel } from "../../../store/user";

export function AliasUserView() {
    const contentAccess: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    const user: UserModel = useStoreState<AppModel>((state) => state.user);


    //  && contentAccess.contentAccess.id === userdata.currentAccess.id && contentAccess.contentAccess.access === userdata.currentAccess.access
    if (userdata.status.type === 'userdata' && contentAccess.contentUserData.uid != userdata.status.userdata.uid) {
        return <div className="Flex Shadow Pad05 AlignCenter JustifyCenter" style={{ background: 'white', borderBottom: '1px solid #bbb' }}>
            <p>Du betraktar nu innehållet med identiteten <strong>{contentAccess.contentUserData.firstName} {contentAccess.contentUserData.lastName}</strong></p><UserAccessView key={String(contentAccess.contentAccess)} useraccess={contentAccess.contentAccess} />
        </div>
    }


    return null;

}