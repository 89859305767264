import { ActionCreator, useStoreActions, useStoreState } from "easy-peasy";
import { ScorxModel } from "../../../store/scorxmodel";
import { ScorxPlayerModel } from "../../../store/scorxplayermodel";
import { AppModel } from "../../../store/appModel";
import { Recording, Recordings } from "./PlayerTypes";
import { MixerSlider } from "./MixerSlider";

export function ScorxPlayerView() {
    // const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    // const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    // const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    const loadRecordings: ActionCreator<Recordings> = useStoreActions<AppModel>((actions) => actions.scorxPlayer.loadRecordings);
    const scorx: ScorxModel = useStoreState<AppModel>((state) => state.scorx);
    const scorxPlayer: ScorxPlayerModel = useStoreState<AppModel>((state) => state.scorxPlayer);
    if (scorxPlayer.status.type === "empty") return <h3>ScorxPlayer Empty</h3>;
    if (scorxPlayer.status.type === "loading") return <h3>ScorxPlayer loading...</h3>;
    if (scorxPlayer.status.type === "recordings") {
        const start = scorxPlayer.status.start;
        const stop = scorxPlayer.status.stop;
        const setMixer = scorxPlayer.status.setMixer;
        const recordings = scorxPlayer.status.recordings;
        return <div className="Flex Pad05" style={{ userSelect: 'none' }}>
            <button onClick={e => { start(0, 0, scorxPlayer.positionStatus.duration, false) }}>Start</button>
            <button onClick={e => { start(10, 0, 13, true) }}>Start 10</button>
            <button onClick={e => { stop() }}>Stop</button>
            {Object.values(recordings).map((recording: Recording) => <MixerSlider key={recording.id} label={recording.name} size="3rem" value={70} onValue={value => setMixer([{ id: recording.id, level: value / 100 }])} />)}
            <p>{scorxPlayer.positionStatus.type} <span style={{ display: 'inline-block', width: '3rem' }}> {scorxPlayer.positionStatus.position}</span> {scorxPlayer.positionStatus.duration}</p>
        </div >
    }
    return <h3>Hoho</h3>
}