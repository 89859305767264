import * as PDFJS from 'pdfjs-dist/legacy/build/pdf'
import { useEffect, useRef, useState } from 'react';
PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`;

export function PdfView(props: { url: string }) {
    // const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    // const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    // const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    // const loadMethod: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
    const [pageLoadInfo, setPageLoadInfo] = useState("");
    const canvasDiv = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handlePdf = async () => {
            if (!canvasDiv || !canvasDiv.current) return;
            canvasDiv.current.innerHTML = '';
            const pdf: PDFJS.PDFDocumentProxy = await PDFJS.getDocument(props.url).promise;
            for (let pageIdx = 0; pageIdx < pdf.numPages; pageIdx++) {
                setPageLoadInfo(`Laddar sidan ${pageIdx + 1} av ${pdf.numPages}`);
                const canvas: HTMLCanvasElement = document.createElement('canvas');
                const page: PDFJS.PDFPageProxy = await pdf.getPage(pageIdx + 1);
                const viewport = page.getViewport({ scale: 2.5 });
                canvas.classList.add('PageShadow');
                canvas.classList.add('BDLightGray');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                canvas.style.width = canvas.style.height = '100%';
                const context = canvas.getContext("2d") as CanvasRenderingContext2D;
                const renderCondext = { canvasContext: context, viewport: viewport }
                await page.render(renderCondext);
                canvasDiv.current.appendChild(canvas);
            }
            setPageLoadInfo("");
        }
        handlePdf();

    }, [props.url]);
    if (!props.url) return <h3>No pdf url</h3>;
    return <>
        {pageLoadInfo && <p>{pageLoadInfo}</p>}
        <div className="Pad05 Flex FlexColumn" ref={canvasDiv} />
    </>
}