import { Menu, MenuButton, MenuDivider, MenuHeader, MenuItem } from "@szhsin/react-menu";
import { accessIdToFullname, accessLevelToRole } from "../../data/Data";
import { AppModel } from "../../store/appModel";

import { UserDataModel, UserDataStatus } from "../../store/userdata";
import { ContentFrame } from "../content/ContentFrame"

import { Action, ActionCreator, Actions, action, useStoreActions, useStoreState } from 'easy-peasy';

export const RoleSelectorView = () => {
    const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    const userdataSetStatus: ActionCreator<UserDataStatus> = useStoreActions<AppModel>((actions) => actions.userdata.setStatus);
    const locationClearContent: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.location.clearContent);
    if (userdata.status.type == 'userdata' && userdata.status.userdata.claims.accesses.length > 1) {
        let idx = userdata.status.userdata.claims.accesses.findIndex((item, index) => item.id == userdata.currentAccess.id && item.access == userdata.currentAccess.access);
        return <>
            <ContentFrame >
                <div className="Flex JustifyEnd">
                    <p>Du har fler än en användaridentitet.</p>
                    <Menu menuButton={<MenuButton className="Small" style={{ alignSelf: 'normal' }}>Växla identitet ☰</MenuButton>} transition>

                        <MenuHeader >Användaridentitet</MenuHeader>
                        {userdata.status.userdata.claims.accesses.map((item, index) => <MenuItem
                            type="checkbox"
                            checked={idx == index}
                            value={idx}
                            onClick={e => {
                                let status: UserDataStatus = userdata.status;
                                if (status.type == 'userdata') {
                                    status.userdata.claims.current = item;
                                    userdataSetStatus(status);
                                    locationClearContent();
                                }
                            }} key={index}>{accessIdToFullname(item.id)} {accessLevelToRole(item.access)}</MenuItem>)}
                    </Menu>
                </div>
            </ContentFrame >
        </>
    }

    return <></>

}