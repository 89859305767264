import './CenterContent.css'

export function CenterContent(props: { children: JSX.Element | JSX.Element[], css?: any }) {
    return <div className="CenterContent" style={props.css}>
        {props.children}
    </div>
}

export function FullwidthContent(props: { children: JSX.Element, css?: any }) {
    return <div className="FullwidthContent ABC" style={props.css}>
        {props.children}
    </div>
}


//{ background: 'url("assets/startup.jpg")' }