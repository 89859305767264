import { useState } from "react";
import { Item, RosettaItem } from "../../../data/Data";
import { saveItemType } from "../../../store/firebaseStore";
import { ActionCreator, useStoreActions } from "easy-peasy";
import { AppModel } from "../../../store/appModel";
import { TypeRosettaView } from "../../content/types/TypeRosettaView";
import { set } from "firebase/database";
import { MarkdownView } from "../../MarkdownView";

export function EditRosettaView(props: { item: Item, path: string, onClose: (v: void) => void }) {

    const [items, setItems] = useState<RosettaItem[]>(props.item && props.item.type && props.item.type.t === 'rosetta' && props.item.type.items || []);
    const [editItem, setEditItem] = useState<RosettaItem | null>(null);

    if (!props.item.type) return <h3>No rosetta...</h3>
    if (props.item.type.t !== "rosetta") return <h3>Not a rosetta...</h3>

    async function save(items: RosettaItem[]) {
        const columns = 0;
        console.log("save", items);
        try {
            await saveItemType({ t: 'rosetta', items, columns }, props.path)
        } catch (error) {
            alert(error);
        }
    }

    async function saveEdit(item: RosettaItem) {
        const idx = items.indexOf(editItem!);
        console.log('save item', idx);
        items[idx] = item;
        console.log('items', JSON.stringify(items));
        setItems([...items]);
        await save(items);
        setEditItem(null);

    }

    async function edit(item: RosettaItem) {
        setEditItem(item);

    }

    return <>
        <h2>Redigera Rosetta-övning</h2>
        {items.map((item, index) => <RosettaItemView key={item.a + item.q} item={item} items={items} setItems={setItems} save={save} edit={edit} />)}

        {!editItem && <RosettaItemAddView items={items} setItems={setItems} save={save} />}
        {editItem && <RosettaItemEditView items={items} item={editItem} save={saveEdit} />}
        <h2>Förhandsgranska</h2>
        <TypeRosettaView items={items} />
    </>
}

export function RosettaItemView(props: { item: RosettaItem, items: RosettaItem[], setItems: (items: RosettaItem[]) => void, save: (v: RosettaItem[]) => void, edit: (v: RosettaItem) => void }) {
    const loadContent: ActionCreator<boolean> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
    return <div className="Flex AlignCenter JustifyBetween Flex1 Frame">
        <div className="Flex AlignCenter JustifyStart Flex1">
            <p>a: {JSON.stringify(props.item.a)}</p>
            <p>q: {JSON.stringify(props.item.q)}</p>
        </div>
        <button className="Alert" onClick={() => {
            const idx = props.items.indexOf(props.item);
            const items = props.items.splice(idx, 1);
            props.setItems([...props.items]);
            props.save([...props.items]);
            loadContent(true);
        }}>Delete</button>
        <button className="" onClick={() => {
            props.edit(props.item)


        }}>Edit</button>

    </div>
}

export function RosettaItemEditView(props: { items: RosettaItem[], item: RosettaItem | null, save: (v: RosettaItem) => void }) {
    const [question, setQuestion] = useState<string>(props.item!.q);
    const [answer, setAnswer] = useState<string>(props.item!.a);
    return <div className="Flex AlignStart JustifyBetween Flex1">
        <div >
            <h4>Fråga:</h4>
            <textarea value={question} onChange={e => { setQuestion(e.target.value) }}></textarea>
            <MarkdownView markdown={question} />
        </div>

        <div>
            <h4>Svar:</h4>
            <textarea value={answer} onChange={e => { setAnswer(e.target.value) }}></textarea>
            <MarkdownView markdown={answer} />
        </div>
        <button className="Active" onClick={() => {
            props.item!.q = question;
            props.item!.a = answer;
            props.save(props.item!);
        }}>Save</button>
    </div>
}

export function RosettaItemAddView(props: { items: RosettaItem[], setItems: (items: RosettaItem[]) => void, save: (v: RosettaItem[]) => void }) {
    const loadContent: ActionCreator<boolean> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
    const [question, setQuestion] = useState<string>("");
    const [answer, setAnswer] = useState<string>("");
    return <div className="Flex AlignCenter JustifyBetween">
        <div >
            <h4>Fråga:</h4>
            <textarea value={question} onChange={e => { setQuestion(e.target.value) }}></textarea>
        </div>

        <div>
            <h4>Svar:</h4>
            <textarea value={answer} onChange={e => { setAnswer(e.target.value) }}></textarea>
        </div>

        <button className="Active" onClick={async () => {
            props.setItems([...props.items, { a: answer, q: question }]);
            props.save([...props.items, { a: answer, q: question }]);
            loadContent(true);
            setQuestion("");
            setAnswer("");
        }}>Add card</button>

    </div>
}