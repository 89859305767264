import React, { MouseEventHandler } from "react";
import { useMediaQuery } from "../hooks/useMediaQuery"

export function SideView(props: { children?: JSX.Element|JSX.Element[] }) {
    const [show, setShow] = React.useState(true);
    const smallScreen = useMediaQuery('(max-width: 860px)');

    React.useEffect(() => {
        console.log('smallScreen: ' + smallScreen);
        setShow(!smallScreen);
    }, [smallScreen]);

    const width = show ? '20rem' : '3rem';

    return <aside style={{ width: width, minWidth: width }}>
        {smallScreen && <div className="Flex JustifyEnd">
            {!show && <SvgHamburger onClick={() => setShow(true)} />}
            {show && <SvgClose onClick={() => setShow(!show)} />}
        </div>}
        {show && props.children}
    </aside>
}


function SvgHamburger(props: { onClick: () => void }) {
    return <svg style={{ cursor: 'pointer' }} width="1.8rem" onClick={_ => props.onClick()} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2"><path d="M49.895 0c27.54 0 49.897 22.404 49.897 50s-22.357 50-49.897 50C22.357 100 0 77.596 0 50S22.357 0 49.895 0zm-25.27 34h50.54c2.209 0 4-1.792 4-4s-1.791-4-4-4h-50.54c-2.207 0-4 1.792-4 4s1.793 4 4 4zm0 20h50.54c2.209 0 4-1.792 4-4s-1.791-4-4-4h-50.54c-2.207 0-4 1.792-4 4s1.793 4 4 4zm0 20h50.54c2.209 0 4-1.792 4-4s-1.791-4-4-4h-50.54c-2.207 0-4 1.792-4 4s1.793 4 4 4z" fill="#515151" /></svg>
}

function SvgClose(props: { onClick: () => void }) {
    return <svg style={{ cursor: 'pointer' }} width="1.8rem" onClick={_ => props.onClick()} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2"><path d="M49.896 0c27.538 0 49.895 22.404 49.895 50s-22.357 50-49.895 50C22.357 100 0 77.596 0 50S22.357 0 49.896 0zm-8.315 50L28.016 63.564a5.883 5.883 0 000 8.315 5.882 5.882 0 008.315 0l13.565-13.564L63.46 71.879a5.882 5.882 0 008.315 0 5.883 5.883 0 000-8.315L58.211 50l13.564-13.564a5.883 5.883 0 000-8.315 5.882 5.882 0 00-8.315 0L49.896 41.685 36.331 28.12a5.882 5.882 0 00-8.315 0 5.883 5.883 0 000 8.315L41.581 50z" fill="#515151" /></svg>
}