import { ActionCreator, useStoreActions, useStoreState } from "easy-peasy";
import { Item, ItemType, TypePitchData } from "../../../data/Data";
import { MusicModel } from "../../../store/musicstore";
import { AppModel } from "../../../store/appModel";
import { useEffect, useState } from "react";
import { NotationView } from "../../music/NotationView";
import { ContentUserModel } from "../../../store/contentuser";

import { saveItemType } from "../../../store/firebaseStore";
import { SvgButtonMan } from "../../buttons/SvgButtonMan";
import { SvgButtonWoman } from "../../buttons/SvgButtonWoman";
import { SvgButtonEar } from "../../buttons/SvgButtonEar";
import WebAudio from "../../../audio/WebAudio";
import { Testvoice } from "../../../smplr/testvoice";
import { FrontFunctionsModel } from "../../../store/frontfunctionsstore";
import { SvgButtonSpeaker } from "../../buttons/SvgButtonSpeaker";


const TESTVOICE = new Testvoice(WebAudio.context);


export function TypePitchView(props: { item: Item, data: TypePitchData }) {
    // const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    // const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    const frontfunctions: FrontFunctionsModel = useStoreState<AppModel>((state) => state.frontFunctions);
    const setShowPitch: ActionCreator<boolean> = useStoreActions<AppModel>((actions) => actions.frontFunctions.setShowPitch);
    // const loadMethod: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
    // useEffect(()=>{
    // }, []);
    const { running, loaded, stream, workerConnection } = useStoreState<AppModel>((state) => state.pitchstore);
    const setRunning: ActionCreator<boolean> = useStoreActions<AppModel>((actions) => actions.pitchstore.setRunning);



    const setManFilterFrequency: ActionCreator<number> = useStoreActions<AppModel>((actions) => actions.music.setManFilterFrequency);
    const music: MusicModel = useStoreState<AppModel>((state) => state.music);
    const [midiNumbers, setMidiNumbers] = useState<number[]>([48, 55, 57, 49]);
    const [currentIdx, setCurrentIdx] = useState<number>(0);
    const [currentNumber, setCurrentNumber] = useState<number>(midiNumbers[currentIdx]);
    const [qcode, setQcode] = useState(music.manFilterFrequency === 0 ? props.data.data1 : props.data.data2);

    useEffect(() => {
        setQcode(music.manFilterFrequency === 0 ? props.data.data1 : props.data.data2);
    }, [music.manFilterFrequency, props.data]);


    return <>
        <div className="Flex ">
            <div className="Frame Flex FlexColumn AlignCenter Pointer" onClick={() => {
                setManFilterFrequency(0);
                setQcode(props.data.data1);
            }}>
                <SvgButtonWoman active={music.manFilterFrequency === 0} />
                <p>Kvinnoröst</p>
            </div>
            <div className="Frame Flex FlexColumn AlignCenter Pointer" onClick={() => {
                setManFilterFrequency(340);
                setQcode(props.data.data2);
            }}>
                <SvgButtonMan active={music.manFilterFrequency !== 0} />
                <p>Mansröst</p>
            </div>
            <div className="Frame Flex FlexColumn AlignCenter Pointer" onClick={() => {
                setShowPitch(true);
                setRunning(!running);
            }}>
                <SvgButtonEar active={running} />
                <p>{running ? "Avsluta lyssning" : "Starta lyssning"}</p>
            </div>
        </div>

        <div className="Flex" style={{ paddingLeft: '4.3rem' }}>
            {midiNumbers.map((midiNr, idx) => <PitchItem onClick={idx => {
                console.log(idx);
                setCurrentIdx(idx);
                setCurrentNumber(midiNumbers[currentIdx - 1]);
            }} active={idx == currentIdx} idx={idx} midiNr={midiNr} pitchNr={music.midinote} />)}
        </div>

        <NotationView text={qcode} onPlaydata={(playdata) => {
            console.log(playdata);
            const notes = playdata.notes.map((note: any) => note.note);
            console.log(notes);
            setMidiNumbers(notes);
        }} />

        {contentuser.contentAccess.access === 3 && <TypePitchAdminView item={props.item} data={props.data} />}

    </>
}



export function TypePitchAdminView(props: { item: Item, data: TypePitchData }) {
    const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    const [data1, setData1] = useState("");
    const [data2, setData2] = useState("");

    useEffect(() => {
        setData1(props.data.data1);
        setData2(props.data.data2);
    }, [props.data]);


    return <>
        <h3>TypePitchAdminView</h3>
        <p> {JSON.stringify(props.data)}</p>
        <input value={data1} onChange={e => setData1(e.target.value)} />
        <input value={data2} onChange={e => setData2(e.target.value)} />

        <button onClick={e => {
            const data: ItemType = { t: 'pitch', data: { data1, data2 } };
            console.log(props.item.path, contentuser.contentAccess.id);
            const path = `/${contentuser.contentAccess.id}${props.item.path}`;
            console.log(path);
            saveItemType(data, path);
        }}>TypePitchAdminView</button>
    </>

}


export function PitchItem(props: { idx: number, active: boolean, midiNr: number, pitchNr: number | null, onClick: (idx: number) => void }) {

    const [playing, setPlaying] = useState(false);
    const diff = props.active && props.pitchNr ? props.pitchNr - props.midiNr : null;
    let background = props.active ? 'white' : 'lightgray';
    if (props.active && diff !== null) {
        switch (Math.abs(diff)) {
            case 0: background = 'lime'; break;
            case 1: background = 'orange'; break;
            case 2: background = 'tomato'; break;
            case 3: background = 'red'; break;
            default: background = 'lightgray'; break;
        }
    }

    return <div className="Flex FlexColumn Pad0 Frame  Gap0" style={{ userSelect: 'none', padding: '.2rem', cursor: 'pointer', borderColor: props.active ? 'dodgerblue' : 'lightgray' }
    } onClick={e => props.onClick(props.idx)} >
        <div style={{ width: '1.5rem', height: '1rem', border: '1px solid lightgray', backgroundColor: diff != null && diff >= 3 ? 'red' : 'white' }}></div>
        <div style={{ width: '1.5rem', height: '1rem', border: '1px solid lightgray', backgroundColor: diff != null && diff === 2 ? 'tomato' : 'white' }}></div>
        <div style={{ width: '1.5rem', height: '1rem', border: '1px solid lightgray', backgroundColor: diff != null && diff === 1 ? 'orange' : 'white' }}></div>
        <div style={{ width: '1.5rem', height: '1rem', border: '1px solid lightgray', backgroundColor: diff != null && diff === 0 ? 'lime' : 'white' }}></div>
        <div style={{ width: '1.5rem', height: '1rem', border: '1px solid lightgray', backgroundColor: diff != null && diff === -1 ? 'orange' : 'white' }}></div>
        <div style={{ width: '1.5rem', height: '1rem', border: '1px solid lightgray', backgroundColor: diff != null && diff === -2 ? 'tomato' : 'white' }}></div>
        <div style={{ width: '1.5rem', height: '1rem', border: '1px solid lightgray', backgroundColor: diff != null && diff <= -3 ? 'red' : 'white' }}></div>

        <div className="Flex JustifyContent" style={{ padding: '.2rem 0' }}
            onMouseDown={e => {
                props.onClick(props.idx);
                TESTVOICE.start(props.midiNr);
                setPlaying(true);
            }}
            onMouseUp={e => {
                TESTVOICE.stop();
                setPlaying(false);
            }}
        ><SvgButtonSpeaker active={playing} /></div>
    </div >

}