import { AppModel } from '../store/appModel';
import { Action, ActionCreator, Actions, action, useStoreActions, useStoreState } from 'easy-peasy';
import { LocationModel, LocationStatus } from "../store/location";
import { MaterialView, ShelfView } from './ShelfView';
import { ContentFilterView } from './ContentFilterView';
import { SideView } from './SideView';
import { ContentModel } from '../store/content';
import { UserAccess, UserDataModel } from '../store/userdata';
import { Item, filterHasChildren, filterTypeItem } from '../data/Data';
import { motion } from "framer-motion"

import { CenterContent, FullwidthContent } from './CenterContent';
import { ContentView } from './ContentView';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import { BookView } from './content/BookView';
import { AdminMain } from './admin/AdminMain';
import { ClassroomView } from './classroom/ClassroomView';
import { FrontView } from './front/FrontView';
import { SearchView } from './SearchView';

export function MainView() {
    const { status: data }: LocationModel = useStoreState<AppModel>((state) => state.location);
    const setStatus: ActionCreator<LocationStatus> = useStoreActions<AppModel>((actions) => actions.location.setStatus);
    switch (data.type) {
        case "front": return <FrontView />;
        case "classroom": return <ClassroomView />;
        case "material": return <MaterialView />;
        case "book": return <BookView />;
        case "repertoir": return <RepertoireView />;
        case "desktop": return <DesktopView />;
        case "search": return <SearchView />;
        case "admin": return <AdminMain />;
        default: return <FrontView />;
    }
}

export function RepertoireView() {
    return <>
        <SideView />
        <div className="Flex FlexColumn AlignStretch JustifyStretch OFAuto Flex1">
            <FullwidthContent>
                <h3>FULL WIDTH</h3>
            </FullwidthContent>
            <CenterContent>
                <h3>RepertoireView</h3>
            </CenterContent>
        </div>
    </>
}

export function DesktopView() {
    return <>
        <SideView />
        <div className="Flex FlexColumn AlignStretch JustifyStretch Pad05 OFAuto Flex1">
            <h3>DesktopView</h3>
        </div>
        <SideView />
    </>
}



