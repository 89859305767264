import { useStoreState } from "easy-peasy";
import { LocationModel } from "../../../store/location"
import { AppModel } from "../../../store/appModel";
import { useEffect, useState } from "react";
import { UserAccess, UserData } from "../../../store/userdata";
import { getUserData, rdbRemoveUserData, serverDeleteUser, setUserDataClaimsAccesses } from "../../../store/firebaseStore";
import { emailToRefPath } from "../../../utils/utils";
import { AdminUserAccesses } from "./AdminUserAccesses";

export function AdminEditUserView() {
    const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    const uid: string | null = location.status.type === 'admin' && location.status.uid ? location.status.uid : null;
    const [userData, setUserData] = useState<UserData | null>(null);

    useEffect(() => {
        if (!uid) return;
        getUserData(emailToRefPath(uid)).then((data) => {
            console.log('getUserData', data);
            setUserData(data);

        }).catch((err) => {
            alert('getUserData err: ' + err);
        });
    }, [uid])


    if (!uid || !userData) return <><h4>No user selected</h4></>

    const userAccesses: UserAccess[] = userData!.claims.accesses;

    return <>
        <h3>AdminEditUserView uid:{uid}</h3>
        <p>{JSON.stringify(userData)}</p>
        <p>{JSON.stringify(userAccesses)}</p>
        <AdminUserAccesses userAccesses={userAccesses} onChange={uas => {
            console.log('onChange', uas);
            setUserData({ ...userData, claims: { ...userData.claims, accesses: uas } });
            setUserDataClaimsAccesses(emailToRefPath(uid), uas);
        }} />

        <button onClick={async e => {
            try {
                const response = await serverDeleteUser(uid);
                console.log('serverDeleteUser', response);
                await rdbRemoveUserData(emailToRefPath(uid));
                console.log('rdbRemoveUserData', uid);
            } catch (error) {
                alert(error);
            }

        }}>Delete user</button>
    </>
}