import { Action, Helpers, Thunk, action, thunk } from "easy-peasy";
import { ScorxItem } from "../components/music/player/PlayerTypes";
import { AppModel } from "./appModel";
import { getScorxList } from "./firebaseStore";

export type ScorxStatus = { type: 'empty' } | { type: 'scorx', item: ScorxItem };
export type ScorxModel = {
    scorxStatus: ScorxStatus;
    setScorxStatus: Action<ScorxModel, ScorxStatus>;
    scorxList: ScorxItem[];
    setScorxList: Action<ScorxModel, ScorxItem[]>;
    loadScorxList: Thunk<ScorxModel, void>;
}

export const createScorxModel = (): ScorxModel => {
    return {
        scorxStatus: { type: 'empty' },
        setScorxStatus: action((state, payload) => {
            state.scorxStatus = payload;
        }),
        scorxList: [],
        setScorxList: action((state, payload) => {
            state.scorxList = payload;
        }),
        loadScorxList: thunk(async (actions, payload, helpers: Helpers<ScorxModel, AppModel, any>) => {
            const data = await getScorxList();
            console.log(data);
            actions.setScorxList(data);
        }),


    }

}