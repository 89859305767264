export function SvgButtonSpeaker(props: { title?: string, active: boolean, onClick?: () => void }) {
    return <div title={props.title} style={{ width: '1.4rem', height: '1.4rem' }} className={`SvgButton ${props.active ? "Play" : ""}`} onClick={e => props.onClick && props.onClick()}>
        <svg width="100%" height="100%" version="1.1" style={{}} viewBox='0 0 100 100'>
            <g transform="matrix(1,0,0,1,-539.276,-165.491)">
                <path d="M589.172,165.491C616.71,165.491 639.067,187.896 639.067,215.491C639.067,243.087 616.71,265.491 589.172,265.491C561.634,265.491 539.276,243.087 539.276,215.491C539.276,187.896 561.634,165.491 589.172,165.491ZM572.475,201.224C572.475,199.683 571.226,198.435 569.685,198.435L563.612,198.435C562.072,198.435 560.823,199.683 560.823,201.224L560.823,226.055C560.823,227.595 562.072,228.844 563.612,228.844L569.685,228.844C571.226,228.844 572.475,227.595 572.475,226.055L572.475,201.224ZM589.893,189.043C589.893,187.916 589.216,186.901 588.176,186.468C587.136,186.035 585.938,186.27 585.139,187.063L577.066,197.063C576.539,197.587 576.242,198.299 576.242,199.043L576.242,228.236C576.242,228.979 576.539,229.691 577.066,230.215L585.139,240.215C585.938,241.009 587.136,241.244 588.176,240.811C589.216,240.378 589.893,239.362 589.893,238.236L589.893,189.043ZM601.104,233.896L610.709,240.69C611.966,241.579 613.708,241.28 614.597,240.023C615.486,238.767 615.187,237.024 613.93,236.136L604.326,229.342C603.069,228.453 601.327,228.752 600.438,230.009C599.549,231.265 599.848,233.007 601.104,233.896ZM604.326,200.69L613.93,193.896C615.187,193.007 615.486,191.265 614.597,190.009C613.708,188.752 611.966,188.453 610.709,189.342L601.104,196.136C599.848,197.024 599.549,198.767 600.438,200.023C601.327,201.28 603.069,201.579 604.326,200.69ZM602.715,218.281L621.924,218.281C623.463,218.281 624.713,217.031 624.713,215.491C624.713,213.952 623.463,212.702 621.924,212.702L602.715,212.702C601.176,212.702 599.926,213.952 599.926,215.491C599.926,217.031 601.176,218.281 602.715,218.281Z" />
            </g>
        </svg>
    </div>
}

