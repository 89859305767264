import { useStoreState } from "easy-peasy";
import { filterPublished, Item } from "../../data/Data";
import { LocationModel } from "../../store/location";
import { AppModel } from "../../store/appModel";

export function BookTOCView() {
    const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    if (!location.book) return <img src="assets/loading.svg" alt="loading" className="Loading" />

    if (!location.book.children) return <h3>Inga kapitel angivna</h3>

    const children = location.book.children && filterPublished(location.book.children);


    const chapters = location.book.children && children.map((item, index) => <TOCChapter item={item} key={item.id} />);

    console.log(location.book.title, ' children', location.book.children?.length);
    return <>
        <p className="Label">Innehållsförteckning:</p>
        <div className="Flex FlexColumn">
            {chapters}
        </div>
    </>
}

export function TOCChapter(props: { item: Item }) {
    const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    if (!location.chapter) return <h3>No chapter found!</h3>
    // const chapterPath = props.bookPath + "/" + props.item.id;

    const published = filterPublished(props.item.children!);
    const subchapters = props.item.children && published.map((item, index) => <TOCSubchapter item={item} key={item.id} />);

    const selected = location.chapter && props.item.id === location.chapter.id ? 'selected' : '';
    return <div className="Flex FlexColumn Gap0">
        <a href={"#book" + props.item.path!} className={`TOC TOCChapter ${selected}`}>{props.item.title}</a>
        {subchapters}
    </div >
}

export function TOCSubchapter(props: { item: Item }) {
    const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    if (!location.book) return <h3>No book found!</h3>

    const selected = location.subchapter && props.item.id === location.subchapter.id ? 'selected' : '';
    return <a
        href={"#book" + props.item.path}
        className={`TOC TOCSubchapter ${selected}`}>
        {props.item.title}

    </a>
}

