import { useRef, useState } from "react";
import { Item, strToAccess, strToPublish, strToType } from "../../../data/Data";
import { UserDataModel } from "../../../store/userdata";
import { useStoreState } from "easy-peasy";
import { saveItemChildren } from "../../../store/firebaseStore";
import { LABELS } from "../AdminMain";
import slug from "slug";
import { AppModel } from "../../../store/appModel";

export function CreateItemChild(props: { item: Item, level: number, path: string, crumbs: string[], onClose: (v: void) => void }) {
    const [error, setError] = useState<string | null>(null);
    const [title, setTitle] = useState<string>('');
    const [id, setId] = useState<string>('');

    const typeRef = useRef<HTMLSelectElement>(null);
    const accessRef = useRef<HTMLSelectElement>(null);
    const publishRef = useRef<HTMLSelectElement>(null);
    const sortRef = useRef<HTMLInputElement>(null);
    const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);

    const [text, setText] = useState<string>('');

    return <div className="Flex FlexColumn PadV05">
        <form onSubmit={e => {
            e.preventDefault();

            if (!title || title.length < 5) setError('Title with at least 5 chars is required');
            if (!id) setError('Id is required');
            if (!typeRef || !typeRef.current || !typeRef.current.value) setError('Type is required');
            if (!accessRef || !accessRef.current || !accessRef.current.value) setError('Access is required');
            if (!publishRef || !publishRef.current || !publishRef.current.value) setError('Publish is required');
            if (!sortRef || !sortRef.current || !sortRef.current.value) setError('Sort is required');
            if (!text) setError('Text is required');
            if (userdata.status.type != 'userdata') { setError('Userdata is required'); return; }

            setError(null);

            const newItem: Item = {
                id,
                title,
                access: strToAccess(accessRef.current!.value),
                type: strToType(typeRef.current!.value),
                publish: strToPublish(publishRef.current!.value),
                sort: parseInt(sortRef.current!.value),
                originators: [{ firstName: userdata.status.userdata.firstName, lastName: userdata.status.userdata.lastName, uid: userdata.status.userdata.uid!, type: 'document' }],
                text: text.trim(),
            }

            props.item.children = props.item.children ? [...props.item.children, newItem] : [newItem];
            saveItemChildren(props.item, props.path).then(_ => {
                props.onClose();
            }).catch(err => { alert(err) });
        }

        }>
            <div className="Flex AlignEnd JustifyBetween PadB05">
                <div className="Flex">
                    <h3 className="NoMargin">Skapa {LABELS[props.level + 1]} i {LABELS[props.level]} {props.item.title}</h3>
                    {props.crumbs && props.crumbs.map((crumb, index) => <span key={index} style={{ border: '1px solid gray', borderRadius: '.2rem', padding: '0 .2rem', marginLeft: '.2rem' }}>{crumb}</span>)}

                </div>
                <button className="Active" type="submit">Skapa</button>
            </div>
            <div className="Flex FlexColumn AlignStart">
                <input value={title} onChange={e => {
                    setTitle(e.target.value);
                    setId(slug(e.target.value));
                }} type="text" name="title" placeholder="title" />
                <p>Id: {id}</p>
                <select ref={typeRef}>
                    <option value="item">Item</option>
                    <option value="footer">Footer</option>
                    <option value="rosetta">Rosetta</option>
                    <option value="scorxdev">Scorxdev</option>
                    <option value="info">Info</option>
                    <option value="task">Task</option>
                    <option value="tasks">Tasks</option>
                    <option value="news">News</option>
                    <option value="pdf">Pdf</option>
                    <option value="pitch">Pitch</option>
                    <option value="glossary">Glossary</option>
                </select>
                <select ref={accessRef}>
                    <option value="0">Gäst</option>
                    <option value="1">Studerande</option>
                    <option value="2">Lärare</option>
                    <option value="3">Admin</option>
                </select>
                <select ref={publishRef}>
                    <option value="always">Publicerad</option>
                    <option value="never">Opublicerad</option>
                </select>
                <textarea value={text} onChange={e => {
                    setText(e.target.value);
                }} name="text" placeholder="text" />

                <input ref={sortRef} defaultValue="100" type="number" name="sort" placeholder="sort" />

            </div>
        </form>
        <p>Error: {error}</p>
    </div>
}
