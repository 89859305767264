import { useStoreState } from "easy-peasy";
import { ContentModel } from "../../../store/content";
import { AppModel } from "../../../store/appModel";
import { Item, filterAccessStudent, filterByType, filterPublished } from "../../../data/Data";
import { ContentUserModel } from "../../../store/contentuser";
import { CenterContent } from "../../CenterContent";
import { FrontInfo, FrontItemDisplay } from "../FrontView";
import { ContentFrame } from "../../content/ContentFrame";
import { MarkdownView } from "../../MarkdownView";
import { CardsWrapper } from "../../content/CardsWrapper";
import { linkTo } from "../../../utils/utils";
import { EventsView } from "../../classroom/EventsView";
import { ConfirmCourseView } from "../ConfirmCourseView";
import { RoleSelectorView } from "../RoleSelectorView";

export function FrontKakStudent() {
    const content: ContentModel = useStoreState<AppModel>((state) => state.content);
    const currentRoomItems = content.currentRoomItems as unknown as Item[];
    const contentUser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    const loading = content.status.type === 'loading' ? <h3>Laddar sidinnehåll...</h3> : <></>;
    const roomItem = content.currentRoom as any as Item;
    const items: Item[] = filterPublished(filterAccessStudent(filterByType(currentRoomItems, { t: 'info' })));
    const firstItem = items[0];
    if (!firstItem) return <>Can not dipslay first item</>;
    const frontitems = items.map((item, index) => <FrontInfo item={item} key={item.id} />);

    return <>
        <CenterContent >
            <ContentFrame css={{ padding: '1rem 1.5rem 1.5rem', backgroundColor: 'rgba(255,255,255,1)' }}>
                <h1 className="Jumbo">Välkommen, {contentUser.contentUserData.firstName}!</h1>
                {loading}
                <div className="Flex JustifyStart" >
                    <div className="MaxW40Rem Large">
                        <MarkdownView markdown={firstItem.text} />
                    </div>
                </div>
            </ContentFrame>
            <RoleSelectorView />
            <CardsWrapper>
                <div className="FrontItem" >
                    <h4 className="Pad05">Kommande händelser</h4>
                    <div className="Pad05 Pointer" style={{ fontSize: '80%' }} onClick={e => { linkTo('classroom') }}>
                        <EventsView />
                    </div>
                </div>
                <div className="FrontItem" >
                    <h4 className="Pad05">Bekräfta kursdeltagande höstterminen 2024</h4>
                    <ConfirmCourseView />
                </div>
                <>
                    {firstItem.children && filterPublished(firstItem.children).map((item, index) => <FrontItemDisplay item={item} key={item.id} />)}
                </>



            </CardsWrapper>
        </CenterContent>
    </>
}