import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import appStore from './store/appStore';
import { StoreProvider } from 'easy-peasy';

type Props = StoreProvider["props"] & { children: React.ReactNode }
const Provider = StoreProvider as unknown as React.ComponentType<Props>

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={appStore}>
    <App />
  </Provider>
);


