import { ActionCreator, useStoreActions, useStoreState } from "easy-peasy";
import { UserAccess } from "../../store/userdata";
import { CenterContent } from "../CenterContent"
import { SideView } from "../SideView"
import { AppModel } from "../../store/appModel";
import { AdminDiscussionsOverview, StudentDiscussionsOverview } from "./ClassroomDiscussionsView";
import { TreeItem } from "../general/TreeItem";
import { EventsView } from "./EventsView";
import { useEffect, useState } from "react";
import { LocationModel } from "../../store/location";
import { BookmarksOverview } from "./BookmarksOverview";

type ClassRoomViewMode = "overview" | "events" | "createEvent";

export function ClassroomView() {
    const contentAccess: UserAccess = useStoreState<AppModel>((state) => state.contentUser.contentAccess);
    const currentAccess: UserAccess = useStoreState<AppModel>((state) => state.userdata.currentAccess);
    const loadDiscussions: ActionCreator<string> = useStoreActions<AppModel>((actions) => actions.discussions.loadDiscussions);
    const location: LocationModel = useStoreState<AppModel>((state) => state.location);

    const [mode, setMode] = useState<ClassRoomViewMode>("overview");

    let content = <></>;
    switch (location.path) {
        case "classroom": content = <>
            <h2>Översikt</h2>
            <EventsView />
            <BookmarksOverview />

            {currentAccess.access >= 3 && <AdminDiscussionsOverview />}
            {currentAccess.access == 1 && <StudentDiscussionsOverview />}

        </>; break;
        case "classroom/discussions": content = <AdminDiscussionsOverview />; break;
        case "classroom/events": content = <EventsView />; break;
        case "classroom/createEvent": content = <EventsView />; break;
    }

    return <>
        <SideView >
            <div className="Flex FlexColumn Gap0 PadV05">
                <h3>Klassrummet</h3>
                <p className="Small Light">Här hittar du information om kommande händelser, pågående diskussioner, frågor och svar mm.</p>


            </div>

            <div className="Flex FlexColumn">
                <a href="#classroom" onClick={e => {
                    // setMode("overview");
                }} className={`TOC TOCChapter ${location.path === "classroom" ? 'selected' : ''}`}>Översikt</a>
                {/* <a href="#classroom/discussions" onClick={e => {
                    // setMode("discussions");
                }} className={`TOC TOCChapter ${location.path === "classroom/discussions" ? 'selected' : ''}`}>Diskussioner</a> */}
                <a href="#classroom/events" onClick={e => {
                    // setMode("events");
                }} className={`TOC TOCChapter ${location.path === "classroom/events" ? 'selected' : ''}`}>Händelser</a>

                {contentAccess.access >= 3 &&
                    <a href="#classroom/createEvent" onClick={e => {
                        // setMode("createEvent");
                    }} className={`TOC TOCSubchapter ${location.path === "classroom/createEvent" ? 'selected' : ''}`}>Skapa händelse</a>}



            </div >
        </SideView>
        <CenterContent>
            {content}
        </CenterContent>
    </>
}

