import { TestdataModel, createTesdata } from "./Testdata";
import { LocationModel, createLocation } from "./location";
import { UserDataModel, createUserData } from "./userdata";
import { UserModel, createUser } from "./user";
import { ContentModel, createContentModel } from "./content";
import { StoreModel, createPitchStore } from "./pitchstore";
import { ContentUserModel, createContentUser } from "./contentuser";
import { FirebaseModel, createFirebase } from "./firebaseStore";
import { UsersModel, createUsers } from "./users";
import { DiscussionModel, createDiscussionModel } from "./discussionstore";
import { EventsModel, createEventsModel } from "./eventstore";
import { MusicModel, createMusicModel } from "./musicstore";
import { FrontFunctionsModel, createFrontFunctionsModel } from "./frontfunctionsstore";
import { ScorxModel, createScorxModel } from "./scorxmodel";
import { ScorxPlayerModel, createScorxPlayerModel } from "./scorxplayermodel";
import { GlossaryModel, createGlossaryModel } from "./glossaryStore";
import { createKdistContentModel, KdistContentModel } from "./kdistcontent";

export type AppModel = {
  scorx: ScorxModel,
  scorxPlayer: ScorxPlayerModel,
  frontFunctions: FrontFunctionsModel,
  music: MusicModel,
  events: EventsModel,
  glossary: GlossaryModel,
  discussions: DiscussionModel;
  firebaseStore: FirebaseModel;
  contentUser: ContentUserModel;
  pitchstore: StoreModel;
  testdata: TestdataModel;
  user: UserModel;
  userdata: UserDataModel;
  location: LocationModel;
  content: ContentModel;
  users: UsersModel;
  kdistContentModel: KdistContentModel;
};

const appModel: AppModel = {
  scorx: createScorxModel(),
  scorxPlayer: createScorxPlayerModel(),
  frontFunctions: createFrontFunctionsModel(),
  music: createMusicModel(),
  events: createEventsModel(),
  glossary: createGlossaryModel(),
  discussions: createDiscussionModel(),
  firebaseStore: createFirebase(),
  contentUser: createContentUser(),
  pitchstore: createPitchStore(),
  testdata: createTesdata(),
  user: createUser(),
  location: createLocation(),
  userdata: createUserData(),
  content: createContentModel(),
  users: createUsers(),
  kdistContentModel: createKdistContentModel(),
};

export default appModel;
