import React, { useEffect, useRef } from "react";
import { UserAccess, UserData } from "../../../store/userdata";
import { AccessView, UserAccessView } from "./AccessView";
import { NewFirebaseUser, rdbGetUserActions, serverAddUser } from "../../../store/firebaseStore";
import { AppModel } from "../../../store/appModel";
import { ActionCreator, useStoreActions } from "easy-peasy";
import { linkTo } from "../../../utils/utils";
import { UserLogView } from "./UserLogView";

export function AdminUserView(props: { userdata: UserData }) {
    const [show, setShow] = React.useState(false);
    const setContentAccess: ActionCreator<UserAccess> = useStoreActions<AppModel>((actions) => actions.contentUser.setContentAccess);
    const setContentUserData: ActionCreator<UserData> = useStoreActions<AppModel>((actions) => actions.contentUser.setContentUserData);

    const userAccesses = props.userdata && props.userdata.claims && props.userdata.claims.accesses && props.userdata.
        claims.accesses.map((useraccess, index) =>
            <span onClick={e => {
                setContentAccess(useraccess);
                setContentUserData(props.userdata);
            }}>
                <UserAccessView useraccess={useraccess} key={index} />
            </span>
        );


    return <div className="Flex FlexColumn Pad05" key={props.userdata.uid}>
        <div className="Flex AlignBase" style={{ cursor: 'pointer' }}>
            {<span key={props.userdata.uid} onClick={_ => setShow(!show)}>{show ? '\u25BC' : '\u25B6'}</span>}
            <h5 className="Flex NoMargin">{props.userdata.lastName}, {props.userdata.firstName}</h5>
            <div className="Flex">{userAccesses}</div>
        </div>

        {show && <div className="Flex FlexColumn">
            <div className="Flex JustifyBetween">
                <p>{props.userdata.email}</p>
                <p>uid: {props.userdata.uid}</p>
            </div>
            <div className="Frame">
                <UserLogView uid={props.userdata.uid!} />
            </div>
            <p>{JSON.stringify(props.userdata)}</p>
            <button className="Small" onClick={e => {
                linkTo('admin/edituser/' + props.userdata.uid);
            }}>Edit user</button>

        </div>


        }
    </div>
}



