import { PitchMonitor } from './pitch/pitch-monitor';
import { Detector, StoreModel } from '../store/pitchstore'
import { useEffect, useState } from 'react';
import { ActionCreator, useStoreActions, useStoreState } from 'easy-peasy';
import { AppModel } from '../store/appModel';
import { round2, valueSmoothing } from '../utils/utils';
import { SvgButtonWoman } from './buttons/SvgButtonWoman';
import { MusicModel } from '../store/musicstore';
import { SvgButtonMan } from './buttons/SvgButtonMan';
import { ContentUserModel } from '../store/contentuser';


const windowSizes = [512, 1024, 2048, 4096];
const detectors: Detector[] = ["mcleod", "autocorrelation"]
const clarityThreshold = .7;
const POWER_THRESHOLD = 0.15;


export function PitchTest() {
  // const [running, setRunning] = useState(false);
  const { initializeStream, stopStream, setEnabled } = useStoreActions<AppModel>((actions) => actions.pitchstore);
  const { running, loaded, stream, workerConnection } = useStoreState<AppModel>((state) => state.pitchstore);
  const { initializeWorker } = useStoreActions<AppModel>((actions) => actions.pitchstore);
  const setManFilterFrequency: ActionCreator<number> = useStoreActions<AppModel>((actions) => actions.music.setManFilterFrequency);
  const music: MusicModel = useStoreState<AppModel>((state) => state.music);
  const setRunning: ActionCreator<boolean> = useStoreActions<AppModel>((actions) => actions.pitchstore.setRunning);

  const contentUser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);


  useEffect(() => {
    (async () => {
      await initializeWorker();
      console.log('Worker initialized');
    })();
  }, [initializeWorker]);

  useEffect(() => {
    if (running) {
      (async () => {
        await initializeStream();
        setEnabled(true);
        console.log('Stream Initialized');
      })();
    } else {
      (async () => {
        setEnabled(false);
        await stopStream();
        console.log('Stream Stopped');
      })();
    }

    return stopStream;
  }, [initializeStream, running, setEnabled, stopStream]);

  let mainDisplay = <p></p>;
  if (loaded && stream && workerConnection) {
    mainDisplay = (
      <PitchMonitor
        pitchRenderer={NumberDisplay}
        powerThreshold={POWER_THRESHOLD}
        clarityThreshold={clarityThreshold}
        detectorName={detectors[0]}
        windowSize={windowSizes[3]}
        enabled={true}
        stream={stream}
        workerConnection={workerConnection}
      />
    );
  }

  return <div className="Flex AlignCenter Pad05">
    <SvgButtonWoman onClick={() => setManFilterFrequency(0)} active={music.manFilterFrequency != 340} />
    <SvgButtonMan onClick={() => setManFilterFrequency(340)} active={music.manFilterFrequency === 340} />
    <button onClick={e => {
      setRunning(!running);
    }} className={running ? 'Selected' : ''}>{running ? 'Stoppa tonhöjdslyssning' : 'Starta tonhöjdslyssning'}</button>

    {mainDisplay}
  </div>
}

let prevFreq: number = 0;

export function NumberDisplay({ freq = 440, clarity = 0, }: { freq: number | null; clarity: number | null; }) {

  const smoothFreq = freq ? valueSmoothing(freq, 0.1, prevFreq) : null;
  prevFreq = smoothFreq ? smoothFreq : 0;
  return <>
    {/* <p>Freq: {freq ? Math.round(freq) + 'hz' : null}</p> */}
    <p>Frekvens: <strong style={{ fontSize: '120%' }} > {smoothFreq ? Math.round(smoothFreq) + 'hz' : null}</strong></p >

  </>
}

/*
import React from 'react';

import PitchComponent from './components/pitch';
import { CircleChart } from './components/circle-chart/circle-chat';
import { PitchMonitor } from './components/pitch/pitch-monitor';
import { Detector, useStoreActions, useStoreState } from './model';

const windowSizes = [512, 1024, 2048, 4096];
const detectors: Detector[] = ["mcleod", "autocorrelation"]
const clarityThreshold = .7;
const POWER_THRESHOLD = 0.15;

function App() {
  const [running, setRunning] = React.useState(false);
  const { initializeStream, stopStream, setEnabled } = useStoreActions(
    (actions) => actions
  );

  const { loaded, stream, workerConnection } = useStoreState((state) => ({
    loaded: state.loaded,
    loading: state.loading,
    stream: state.stream,
    workerConnection: state.workerConnection,
  }));

  const { initializeWorker } = useStoreActions((actions) => actions);

  React.useEffect(() => {
    (async () => {
      await initializeWorker();
      console.log('Worker initialized');
    })();
  }, [initializeWorker]);

  React.useEffect(() => {
    if (running) {
      (async () => {
        await initializeStream();
        setEnabled(true);
        console.log('Stream Initialized');
      })();
    } else {
      (async () => {
        setEnabled(false);
        await stopStream();
        console.log('Stream Stopped');
      })();
    }

    return stopStream;
  }, [initializeStream, running, setEnabled, stopStream]);

  //----------------------------------------------------------------------------

  let mainDisplay = <h1>Test PitchMonitor</h1>;
  if (loaded && stream && workerConnection) {
    mainDisplay = (
      <PitchMonitor
        pitchRenderer={PitchComponent}
        powerThreshold={POWER_THRESHOLD}
        clarityThreshold={clarityThreshold}
        detectorName={detectors[0]}
        windowSize={windowSizes[3]}
        enabled={true}
        stream={stream}
        workerConnection={workerConnection}
      />
    );
  }

  return <>
    <header style={{ display: 'flex' }}>
      <button onClick={e => {
        setRunning(!running);
      }}>{running ? 'Stop' : 'Start'}</button>
    </header>
    {mainDisplay}
  </>

}

export default App;


*/