import { Item } from "../../../data/Data";
import { deleteItem } from "../../../store/firebaseStore";
import { MarkdownView } from "../../MarkdownView";

export function DeleteItem(props: { item: Item, path: string, crumbs: string[], onClose: (v: void) => void }) {

    return <div className="Flex FlexColumn PadV05">
        <div className="Flex AlignEnd JustifyBetween PadB05">
            <div className="Flex">
                <h3 className="NoMargin">{props.item.title}</h3>
                {/* {props.crumbs && <span style={{ color: 'black', border: '1px solid gray', borderRadius: '.2rem', padding: '0 .2rem' }}>{props.crumbs.join(' / ')}</span>} */}
                {props.crumbs && props.crumbs.map((crumb, index) => <span key={index} style={{ border: '1px solid gray', borderRadius: '.2rem', padding: '0 .2rem', marginLeft: '.2rem' }}>{crumb}</span>)}
            </div>
            <button className="Alert" onClick={_ => {
                console.log('delete');
                console.log(props.path);
                if (window.confirm('Är du säker på att du vill ta bort "' + props.item.title + '" och alla underliggande material?')) {
                    deleteItem(props.path);
                    props.onClose();
                }
            }}>Ta bort</button>

        </div>
        <div className="" style={{ border: "10px solid #efefef" }}>
            <MarkdownView markdown={props.item.text} />
        </div>


    </div>
}
