import { useState } from "react";
import { GLOSSARY_TYPES, Item } from "../../../data/Data";
import { saveItemType } from "../../../store/firebaseStore";
import { getGlossaryLabel } from "../../SearchView";

export function EditGlossaryView(props: { item: Item, path: string, onClose: (v: void) => void }) {

    const types = props.item.type && props.item.type.t === 'glossary' ? props.item.type.types : [];
    const [typesState, setTypesState] = useState<string[]>(types);

    const buttons = GLOSSARY_TYPES.map((type, index) => {

        return <button onClick={e => {
            const types = [type];
            props.item.type = { t: 'glossary', types };
            setTypesState(types);
            saveItemType({ t: 'glossary', types }, props.path);
        }}>{getGlossaryLabel(type)}</button>

    });

    return <div>
        <h1>Redigera ordlista</h1>
        <p>Under konstruktion</p>
        <p>types:{JSON.stringify(types)}</p>
        <p>typesState:{JSON.stringify(typesState)}</p>
        {buttons}

    </div>
}