import { Action, Computed, FilterActionTypes, StateMapper, action, computed } from "easy-peasy";
import { DEFAULT_ACCESS, DEFAULT_USERDATA, UserAccess, UserData } from "./userdata";

export type ContentUserModel = {
    contentAccess: UserAccess;
    contentUserData: UserData;
    setContentAccess: Action<ContentUserModel, UserAccess>;
    setContentUserData: Action<ContentUserModel, UserData>;
    fullName: Computed<ContentUserModel, string>;

    // isMale: boolean;
    // setIsMale: Action<ContentUserModel, boolean>;
}

export const createContentUser = (): ContentUserModel => {
    return {
        contentAccess: DEFAULT_ACCESS,
        contentUserData: DEFAULT_USERDATA,

        setContentAccess: action((state, payload) => {
            state.contentAccess = payload;
        }),
        setContentUserData: action((state, payload) => {
            state.contentUserData = payload;
        }),

        fullName: computed([(state: StateMapper<FilterActionTypes<any>>) => state.contentUserData], (state) => {
            return `${state.firstName} ${state.lastName}`;
        }),

        //--------------------------------------------
        // isMale: false,
        // setIsMale: action((state, payload) => {
        //     state.isMale = payload;
        // }),
    }
}
