import { Action, ActionCreator, Actions, action, useStoreActions, useStoreState } from 'easy-peasy';
import { AppModel } from '../store/appModel';
import { UserAccess, UserDataModel, UserDataStatus } from '../store/userdata';

import { Menu, MenuItem, MenuButton, MenuDivider, MenuRadioGroup, MenuHeader } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

import { accessIdToFullname, accessLevelToRole } from '../data/Data';
import { UserStatus } from '../store/user';
import { UserAccessView } from './admin/users/AccessView';

export function UserDataView() {
    const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    const userdataSetStatus: ActionCreator<UserDataStatus> = useStoreActions<AppModel>((actions) => actions.userdata.setStatus);
    const userLogOut: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.user.logOut);
    const locationClearContent: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.location.clearContent);

    if (userdata.status.type == 'loading') return <p>Loading userdata...</p>;
    if (userdata.status.type == 'error') return <p>Error loading userdata: {userdata.status.msg}</p>;
    if (userdata.status.type == 'anonymous') return <></>

    if (userdata.status.type == 'userdata') {
        let idx = userdata.status.userdata.claims.accesses.findIndex((item, index) => item.id == userdata.currentAccess.id && item.access == userdata.currentAccess.access);

        return <div className="Flex AlignCenter">
            {/* <p>{JSON.stringify(userdata.status.userdata.claims.accesses)}</p> */}
            <UserAccessView useraccess={userdata.currentAccess} />
            <Menu menuButton={<MenuButton className="Small" style={{ alignSelf: 'normal' }}>{userdata.status.userdata.firstName} {userdata.status.userdata.lastName} ☰</MenuButton>} transition>
                <MenuHeader >Användaridentitet</MenuHeader>
                {userdata.status.userdata.claims.accesses.map((item, index) => <MenuItem
                    type="checkbox"
                    checked={idx == index}
                    value={idx}
                    onClick={e => {
                        let status: UserDataStatus = userdata.status;
                        if (status.type == 'userdata') {
                            status.userdata.claims.current = item;
                            userdataSetStatus(status);
                            locationClearContent();
                        }
                    }} key={index}>{accessIdToFullname(item.id)} {accessLevelToRole(item.access)}</MenuItem>)}

                <MenuDivider />
                <MenuItem onClick={e => userLogOut()}>Logga ut</MenuItem>
            </Menu></div>
    }

    return <></>
}