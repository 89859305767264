import { useStoreState } from "easy-peasy";
import { Item, createItemStoragePath } from "../../../data/Data";
import { UserAccess } from "../../../store/userdata";
import { AppModel } from "../../../store/appModel";
import { ChangeEvent, useEffect, useState } from "react";
import { StorageReference, deleteObject, getDownloadURL, getStorage, listAll, ref, uploadBytes } from "firebase/storage";
import { set } from "firebase/database";

export function EditResourcesView(props: { item: Item }) {
    // const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    // const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    // const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    // const loadMethod: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
    // useEffect(()=>{
    // }, []);
    const contentAccess: UserAccess = useStoreState<AppModel>((state) => state.contentUser.contentAccess);
    const [items, setItems] = useState<StorageReference[]>([]);
    const [file, setFile] = useState<File>();

    useEffect(() => {
        const doList = async () => {
            const storagePath = createItemStoragePath(contentAccess.id, props.item.path!);
            const storage = getStorage();
            const storageRef = ref(storage, storagePath);
            const resources = await listAll(storageRef);
            console.log(resources.items);
            const itemRefs = [];
            setItems(resources.items);
        }
        doList();
    }, []);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };
    const handleUploadClick = async () => {
        if (!file) { return; }
        const storagePath = createItemStoragePath(contentAccess.id, props.item.path!, file.name);
        try {
            const storage = getStorage();
            const storageRef = ref(storage, storagePath);
            const snapshot = await uploadBytes(storageRef, file);
            console.log('Uploaded a blob or file!', snapshot);
            const url = await getDownloadURL(storageRef);
            console.log(url);
            setItems([...items, storageRef]);
        } catch (error) {
            alert(error);
        }
    };

    const deleteFile = async (itemRef: StorageReference) => {
        try {
            const storage = getStorage();
            const desertRef = ref(storage, itemRef.fullPath);
            await deleteObject(desertRef);
            console.log(itemRef.fullPath + ' deleted');
            const idx = items.indexOf(itemRef);
            console.log(idx);
            items.splice(idx, 1);
            setItems([...items]);
        } catch (e) {
            alert(e);
        }
    }


    return <>
        <h3>EditResourcesView</h3>
        <p>Item path: {props.item.path}</p>
        < input type="file" onChange={handleFileChange} />
        <div>{file && `${file.name} - ${file.type}`}</div>
        <button onClick={handleUploadClick}>Upload</button>
        {items.map(itemRef => <StorageItemRefView itemRef={itemRef} onDelete={deleteFile} />)}
    </>
}

export function StorageItemRefView(props: { itemRef: StorageReference, onDelete: (itemRef: StorageReference) => void }) {
    const [url, setUrl] = useState<string>('');
    useEffect(() => {
        getDownloadURL(props.itemRef).then(url => setUrl(url));
    }, [])


    return <div className="Flex FlexColumn Frame OFHidden">
        <h4>{props.itemRef.name}</h4>
        <p>{props.itemRef.fullPath}</p>
        <p>{url}</p>
        <button onClick={e => {
            props.onDelete(props.itemRef);
        }}>Delete</button>

    </div>
}