import { useStoreState } from "easy-peasy";
import { Thread, ThreadItem, Item, ThreadItemStatus } from "../../../data/Data";
import { AppModel } from "../../../store/appModel";
import { UserAccess, UserData, UserDataModel } from "../../../store/userdata";
import { addDiscussionItem, createDiscussion, getDiscussion } from "../../../store/firebaseStore";
import { ContentModel } from "../../../store/content";
import { getTimePath } from "../../../utils/utils";
import { ContentUserModel } from "../../../store/contentuser";
import React, { useState } from "react";
import { UserAccessView } from "../../admin/users/AccessView";
import { DiscussionItemView } from "../../classroom/ClassroomDiscussionsView";

export function ItemDiscussionView(props: { item: Item }) {
    const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    const [discussion, setDiscussion] = useState<Thread | null>(null);
    const [text, setText] = useState('');
    React.useEffect(() => {
        setTimeout(async () => {
            const discussion: Thread = await getDiscussion(userdata.currentAccess.id, props.item.path!);
            console.log(discussion);
            setDiscussion(discussion);
        }, 100);
    }, [props.item.id]);

    //inga diskussioner för gäster...
    if (userdata.currentAccess.access === 0) return null;

    if (discussion === null) {
        if (userdata.currentAccess && userdata.currentAccess.access === 3) {
            return <div>
                <button onClick={e => {
                    let obj: any = {};
                    const discussion: Thread = {
                        type: "discussion", itempath: props.item.path!, text: props.item.title, status: "initiated", active: true, items: obj, created: getTimePath(), updated: getTimePath()
                    }

                    createDiscussion(userdata.currentAccess.id, props.item.path!, discussion).then(_ => {
                        setDiscussion(discussion);
                    }).catch(err => alert(err));
                }}>Admin: Initiera diskussion</button>
            </div>

        } else {

            return <p style={{ color: 'gray' }}>Ingen pågående diskussion</p>
        }
    }

    const items = Object.entries(discussion?.items || {}).map(([key, value]) => value);

    return <details className="Frame" open={true}>
        <summary>Diskussion ({items.length} st inlägg)
        </summary>
        <div className="Flex DiscussionItems JustifyStretch">
            {items && items.map((ditem, index) =>
                <>
                    {ditem.useraccess && <DiscussionItemView item={ditem} key={index} alignRight={ditem.useraccess.access !== 3} />}
                </>
            )}

            <NewItemView userdata={contentuser.contentUserData} userAccess={contentuser.contentAccess} item={props.item} discussion={discussion!} onAdded={ditem => {
                setDiscussion({ ...discussion!, items: { ...discussion!.items, [ditem.time]: ditem } });
            }} />
        </div>
    </details>

    // // skapa diskussion för admin
    // if (userdata.currentAccess && userdata.currentAccess.access === 3) {
    //     if (items.length > 0) {
    //         return <div>
    //             <input type="text" value={text} placeholder="skriv inlägg här" onChange={e => {
    //                 setText(e.target.value);
    //             }} />
    //             <button onClick={e => {
    //                 const uid = userdata.status.type === 'userdata' ? userdata.status.userdata.uid : undefined;
    //                 const ditem: ThreadItem = {
    //                     time: getTimePath(),
    //                     fullname: userdata.fullName as any as string,
    //                     uid: uid,
    //                     text: text,
    //                     useraccess: userdata.currentAccess,
    //                     public: true,
    //                     itempath: props.item.path!,
    //                     status: "solved",
    //                 }
    //                 let obj: any = {};
    //                 obj[getTimePath()] = ditem;
    //                 const discussion: Thread = {
    //                     type: "discussion", itempath: props.item.path!, text: 'Här kan du skriva kommentarer eller ställa frågor till lärare eller grupp' + props.item.title, status: "initiated", active: true, items: obj, created: getTimePath(), updated: getTimePath()
    //                 }

    //                 createDiscussion(userdata.currentAccess.id, props.item.path!, discussion).then(_ => {
    //                     setDiscussion(discussion);
    //                 }).catch(err => alert(err));
    //             }}>Skapa ny diskussion x</button>
    //         </div>
    //     }

    // }


    // return <details className="Frame" open={true}>
    //     <summary>Diskussion ({items.length} st inlägg)</summary>
    //     <div className="Flex DiscussionItems JustifyStretch">
    //         {items && items.map((ditem, index) =>
    //             <>
    //                 {ditem.useraccess && <DiscussionItemView item={ditem} key={index} alignRight={ditem.useraccess.access !== 3} />}
    //             </>
    //         )}

    //         <NewItemView userdata={contentuser.contentUserData} userAccess={contentuser.contentAccess} item={props.item} discussion={discussion!} onAdded={ditem => {
    //             setDiscussion({ ...discussion!, items: { ...discussion!.items, [ditem.time]: ditem } });
    //         }} />
    //     </div>
    // </details>
}


// { ditem.useraccess && <DiscussionItemView item={ditem} key={index} alignRight={ditem.useraccess.access !== 3} />)}


// export function DiscussionItemView(props: { ditem: ThreadItem, alignRight: boolean }) {
//     const flexRight: any = props.alignRight ? { justifyContent: 'flex-end' } : {};
//     const textRight: any = props.alignRight ? { textAlign: 'right' } : {};
//     return <div className=" DiscussionItem" style={flexRight}>
//         <div className="DiscussionItemHeader" style={flexRight}>
//             <div >
//                 <p>{props.ditem.fullname}</p>
//                 <UserAccessView useraccess={props.ditem.useraccess} />
//                 <p>{props.ditem.time}</p>
//             </div>
//         </div>
//         <div className="DiscussionItemText" style={flexRight}>
//             <p style={textRight}>{props.ditem.text}</p>
//         </div>
//     </div>
// }

export function NewItemView(props: { userdata: UserData, userAccess: UserAccess, discussion: Thread, item: Item, onAdded: (ditem: ThreadItem) => void }) {
    const [text, setText] = useState('');
    return <div className="Flex JustifyEnd">
        <input type="text" value={text} style={{ width: '15rem' }} placeholder="skriv kommentar eller fråga här" onChange={e => {
            setText(e.target.value);
        }} />
        <button onClick={e => {
            const ditem: ThreadItem = {
                time: getTimePath(),
                fullname: props.userdata.firstName + ' ' + props.userdata.lastName,
                uid: props.userdata.uid!,
                text: text,
                useraccess: props.userdata.claims.current,
                public: true,
                itempath: props.item.path!,
                status: 'unread',
            }
            addDiscussionItem(props.userAccess.id, props.item.path!, props.discussion, ditem).then(_ => {
                props.onAdded(ditem);
                setText('');
            }).catch(err => alert(err));
        }}>Skicka</button>
    </div>
}