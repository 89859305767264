import { useRef, useState } from "react";
import { NewFirebaseUser, getUserData, rdbSetKakUserSource, rdbUpdateKakVerifyDate, serverAddUser, serverUidExists } from "../../../store/firebaseStore";
import { delayFunction, getSwedishDate } from "../../../utils/utils";


export type NewFirebaseUserExt = NewFirebaseUser & { existsDb: boolean, existsAuth: boolean, kakSource: string };

export function ImportUsersView() {
    // const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    // const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    // const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    // const loadMethod: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
    // useEffect(()=>{
    // }, []);

    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const [newUsers, setNewUsers] = useState<NewFirebaseUserExt[]>([]);
    const [progress, setProgress] = useState<{ idx: number, count: number }>({ idx: 0, count: 0 });

    const importUsers = () => {
        const text = textareaRef.current?.value;
        if (!text) return;
        const lines: string[] = text.split('\n');
        const newUsers: NewFirebaseUserExt[] = [];
        lines.map(line => {
            const fields: string[] = line.split('\t');
            const user: ImportUser = {
                uid: fields[0],
                ssnr: fields[1],
                lastname: fields[2],
                firstname: fields[3],
                email: fields[4],
                source: fields[5],
            }
            const date = new Date();
            const dateString = date.toLocaleDateString('sv-SE', { month: '2-digit', day: '2-digit', year: 'numeric' });
            const newUser: NewFirebaseUserExt = {
                uid: user.uid,
                email: user.email,
                password: 'Stenhammar',
                kakVerifyDate: dateString,
                userdata: {
                    firstName: user.firstname,
                    lastName: user.lastname,
                    claims: {
                        accesses: [{ access: 1, id: 'kak' }],
                        current: { access: 1, id: 'kak' }
                    }
                },
                kakSource: user.source,
                existsAuth: false,
                existsDb: false,
                // dbKakSource: 'XXXXXX',
            }
            newUsers.push(newUser);
            setNewUsers(newUsers);
        });
    }

    const checkUsers = async () => {
        let idx = 0;
        for (let newUser of newUsers) {
            idx++;
            setProgress({ idx: idx + 1, count: newUsers.length });
            const dbData = await getUserData(newUser.uid);
            const authData = await serverUidExists(newUser.uid);

            if (dbData) {
                newUser.kakVerifyDate = dbData.kakVerifyDate;
                // if (dbData.kakSource && dbData.kakSource.trim() !== '') {
                //     newUser.dbKakSource = dbData.kakSource;
                // }
            }

            newUser.existsDb = dbData ? true : false;
            newUser.existsAuth = authData.data ? true : false;
        }
        console.log('setNewUsers', newUsers.length);
        setNewUsers([...newUsers]);
        setProgress({ idx: 0, count: 0 });
    }

    const tagOld = async () => {
        const text = textareaRef.current?.value;
        if (!text) return;
        const lines: string[] = text.split('\n');
        const newUsers: NewFirebaseUserExt[] = [];

        for (let line of lines) {
            const fields: string[] = line.split('\t');
            const uid = fields[0];
            const kaksource = fields[5];
            console.log(uid, kaksource);
            await rdbSetKakUserSource(uid, kaksource);
        }
    }

    return <>
        <h3> ImportUsersView</h3>
        <p>Progress: {progress.idx} / {progress.count}</p>
        <p>current date: {getSwedishDate()}</p>
        <textarea ref={textareaRef} ></textarea>
        <div className="Flex JustifyEnd Pad05">
            <button onClick={e => { importUsers(); }}>ImportUsersView</button >
            <button onClick={e => { checkUsers(); }}>CheckUsers ({progress.idx} / {progress.count})</button>
            <button onClick={e => { tagOld() }}>Tag old</button>
        </div>
        <p>{newUsers.length}</p>
        {newUsers.map((user, index) => <ImportUserItem key={index} verifyDate={getSwedishDate()} user={user} onUserChange={(user: NewFirebaseUserExt) => {
            console.log('user updated!');
            setNewUsers([...newUsers]);
        }} />)}
    </>
}

export type ImportUser = {
    uid: string,
    ssnr: string
    lastname: string,
    firstname: string,
    email: string,
    source: string,
}

function ImportUserItem(props: { user: NewFirebaseUserExt, verifyDate: string, onUserChange: (user: NewFirebaseUserExt) => void }) {
    const { user } = props;
    return <div className="Flex">

        {(!props.user.existsDb && !props.user.existsAuth) &&
            <button onClick={e => {
                serverAddUser(user).then(response => {
                    console.log('User added', response);
                    props.user.existsAuth = true;
                    props.user.existsDb = true;
                    props.onUserChange(props.user);
                }).catch(err => {
                    alert('Error: ' + err);
                });
            }}>Create</button>}

        {/* <p>{user.uid}</p> */}

        {!checkDate(props.verifyDate, user.kakVerifyDate) &&
            <button onClick={async e => {
                user.kakVerifyDate = props.verifyDate;
                await rdbUpdateKakVerifyDate(user.uid, props.verifyDate);
                props.onUserChange(user);

            }}>Update</button>
        }

        <p className={user.existsDb ? checkColor(checkDate(props.verifyDate, user.kakVerifyDate)) : 'Pill BGDogdgerBlue'}>{user.kakVerifyDate}</p>
        <p>{user.userdata.lastName}</p>
        <p>{user.userdata.firstName}</p>
        <p>{user.email}</p>
        <p>{user.kakSource}</p>
        {/* <p>{user.dbKakSource}</p> */}
        <p className={checkColor(user.existsAuth)}>Auth</p>
        <p className={checkColor(user.existsDb)}>Database</p>
    </div >
}

const checkColor = (i: boolean) => i ? 'Pill BGLime' : 'Pill BGTomato';

const checkDate = (date1: string, date2: string | null) => {
    if (date2 === null) return false;
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d1.getTime() === d2.getTime();
}


