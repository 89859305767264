import { Menu, MenuButton, MenuDivider, MenuItem } from "@szhsin/react-menu";
import { ARTIKEL, EditItemStatus, LABELS } from "../AdminMain";
import { Item, sortItems } from "../../../data/Data";
import { useState } from "react";
import { linkTo } from "../../../utils/utils";

export function AdminItemView(props: {
    level: number,
    item: Item,
    path: string,
    crumbs: string[],
    setEditStatus: (s: EditItemStatus) => void
}) {
    const [showChildren, setShowChildren] = useState<boolean>(props.level === 0);

    let color = 'black';
    switch (props.item.type?.t) {
        case undefined:
        case 'item': color = 'black'; break;
        case 'footer': color = 'purple'; break;
        case 'info': color = 'dodgerblue'; break;
        case 'news': color = 'green'; break;
        case 'rosetta': color = 'red'; break;
        default: color = 'gray'; break;
    }

    return <div className="" >
        <div className="Flex PadV05 AlignCenter " >

            {props.item.children && props.item.children.length > 0 && <span style={{ color: color, cursor: 'pointer' }} onClick={_ => setShowChildren(!showChildren)}>{showChildren ? '\u25BC' : '\u25B6'}</span>}
            {(!props.item.children || props.item.children.length === 0) && <span style={{ color: color }} >{'\u2BC4'}</span>}

            <h5 className="NoMargin">{props.item.title} [{props.item.sort}]</h5>
            <Menu menuButton={<MenuButton className="Small" >☰</MenuButton>}>
                <MenuItem onClick={_ => {
                    props.setEditStatus({ type: "other", item: props.item, path: props.path + '/' + props.item.id });
                }}>Redigera grunddata</MenuItem>
                <MenuItem onClick={_ => {
                    props.setEditStatus({ type: "markdown", item: props.item, path: props.path + '/' + props.item.id });
                }}>Redigera text</MenuItem>
                <MenuItem onClick={_ => {
                    props.setEditStatus({ type: "resources", item: props.item, path: props.path + '/' + props.item.id });
                }}>Redigera resursfiler</MenuItem>


                <MenuItem disabled={props.item && props.item.type && props.item.type.t !== 'rosetta'} onClick={_ => {
                    props.setEditStatus({ type: "editRosetta", item: props.item, path: props.path + '/' + props.item.id });
                }}>Redigera rosettaövning</MenuItem>

                <MenuItem disabled={props.item && props.item.type && props.item.type.t !== 'pdf'} onClick={_ => {
                    props.setEditStatus({ type: "editPdf", item: props.item, path: props.path + '/' + props.item.id });
                }}>Redigera pdf</MenuItem>

                <MenuItem disabled={props.item && props.item.type && props.item.type.t !== 'glossary'} onClick={_ => {
                    props.setEditStatus({ type: "editGlossary", item: props.item, path: props.path + '/' + props.item.id });
                }}>Redigera glossary</MenuItem>



                <MenuDivider />
                <MenuItem onClick={_ => {
                    props.setEditStatus({ type: "addChild", item: props.item, path: props.path + '/' + props.item.id, crumbs: [...props.crumbs, props.item.title], level: props.level });
                }}>Skapa {LABELS[props.level + 1]} i {ARTIKEL[props.level]} {LABELS[props.level]}</MenuItem>
                <MenuItem onClick={_ => {
                    props.setEditStatus({ type: "delete", item: props.item, path: props.path + '/' + props.item.id, crumbs: [...props.crumbs, props.item.title] });

                }}>Ta bort {ARTIKEL[props.level]} {LABELS[props.level]}</MenuItem>
            </Menu>
            <p>{JSON.stringify(props.item.type!)}</p>
            <p style={{ fontSize: '80%', color: 'gray' }}>{props.item.text.substring(0, 30) + '...'}</p>
        </div>

        {
            showChildren && <div style={{ borderLeft: '2px solid lightgray', paddingLeft: '.5rem' }}  >
                {props.item.children && sortItems(props.item.children).map((child) => <AdminItemView
                    level={props.level + 1}
                    key={child.id}
                    item={child}
                    path={props.path + '/' + props.item.id}
                    crumbs={props.crumbs.concat([props.item.title])}
                    setEditStatus={props.setEditStatus}
                />)}



            </div>
        }
    </div >
}
