import { Action, ActionCreator, Actions, action, useStoreActions, useStoreState } from 'easy-peasy';
import { AppModel } from '../store/appModel';

import { UserAccess, UserDataModel } from '../store/userdata';
import { accessIdToFullname } from '../data/Data';
import { useMediaQuery } from '../hooks/useMediaQuery';

export function LogoView() {
    const data: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    const matches = useMediaQuery('(min-width: 900px)');
    if (matches) {
        return <div className="Flex AlignCenter TextDark">
            <img src="/assets/scorxlogo.svg" width="24rem" />
            {data.status.type != 'userdata' && <h3 style={{ userSelect: 'none' }} className="">Körakademin</h3>}
            {data.status.type == 'userdata' && <h3 style={{ userSelect: 'none' }} className="">{accessIdToFullname(data.status.userdata.claims.current.id)} </h3>}
        </div>
    }
    return <></>
}