import { useState } from "react";
import { Access, Item, ItemType, Publish, strToAccess, strToPublish, strToType } from "../../../data/Data";
import { ActionCreator, useStoreActions } from "easy-peasy";
import { AppModel } from "../../../store/appModel";
import { saveItemAccess, saveItemPublish, saveItemSort, saveItemTitle, saveItemType } from "../../../store/firebaseStore";

export function AdminItemOther(props: { item: Item, path: string }) {
    const [type, setType] = useState<ItemType>(props.item.type || { t: 'item' });
    const typeDirty = type.t !== props.item.type?.t;
    const [publish, setPublish] = useState<Publish>(props.item.publish || { t: 'always' });
    const publishDirty = publish.t !== props.item.publish?.t;
    const [sort, setSort] = useState<number>(props.item.sort || 0);
    const sortDirty = sort !== props.item.sort;
    const [title, setTitle] = useState<string>(props.item.title || '');
    const titleDirty = title !== props.item.title;
    const [access, setAccess] = useState<Access>(props.item.access || 0);
    const accessDirty = access !== props.item.access;
    const loadContent: ActionCreator<boolean> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
    const dirty = typeDirty || publishDirty || sortDirty || titleDirty || accessDirty;


    return <>
        <h2>Redigera {props.item.title}</h2>
        <form className="Flex FlexColumn" onSubmit={e => {
            e.preventDefault();
        }}>

            <p>title: {JSON.stringify(props.item.title)}</p>
            <p>id: {JSON.stringify(props.item.id)}</p>
            <p>titleDirty: {JSON.stringify(titleDirty)}</p>
            <input value={title} onChange={e => {
                console.log(e.target.value);
                setTitle(e.target.value);
            }} type="text" name="title" placeholder="title" />

            <p>type: {JSON.stringify(props.item.type)}</p>
            <p>typeDirty: {JSON.stringify(typeDirty)}</p>
            <select value={type.t} onChange={e => {
                console.log(e.target.value);
                const type = strToType(e.target.value);
                setType(type);
            }}>
                <option value="item">Item</option>
                <option value="footer">Footer</option>
                <option value="rosetta">Rosetta</option>
                <option value="scorxdev">Scorxdev</option>
                <option value="info">Info</option>
                <option value="task">Task</option>
                <option value="tasks">Tasks</option>
                <option value="news">News</option>
                <option value="pdf">Pdf</option>
                <option value="pitch">Pitch</option>
                <option value="glossary">Glossary</option>
            </select>

            <p>publish: {JSON.stringify(props.item.publish)}</p>
            <p>publishDirty: {JSON.stringify(publishDirty)}</p>
            <select value={publish.t} onChange={e => {
                console.log(e.target.value);
                const publish = strToPublish(e.target.value);
                setPublish(publish);
            }}>
                <option value="always">Publicerad</option>
                <option value="never">Opublicerad</option>
            </select>

            <p>access: {JSON.stringify(props.item.access)}</p>
            <p>accessDirty: {JSON.stringify(accessDirty)}</p>
            <select value={String(access)} onChange={e => {
                const access = strToAccess(e.target.value);
                setAccess(access);
            }}>
                <option value="0">Gäst</option>
                <option value="1">Studerande</option>
                <option value="2">Lärare</option>
                <option value="3">Admin</option>
            </select>

            <p>sort: {JSON.stringify(props.item.sort)}</p>
            <p>sortDirty: {JSON.stringify(sortDirty)}</p>
            <input value={sort} onChange={e => {
                console.log(e.target.value);
                setSort(parseInt(e.target.value));
            }} type="number" name="sort" placeholder="sort" />
            <p>{props.path}</p>
            {dirty && <div className="Flex JustifyEnd">
                <button className="Alert" type="submit" onClick={async _ => {
                    try {
                        if (titleDirty)
                            await saveItemTitle(title, props.path);
                        if (sortDirty)
                            await saveItemSort(sort, props.path);
                        if (publishDirty)
                            await saveItemPublish(publish, props.path);
                        if (typeDirty)
                            await saveItemType(type, props.path);
                        if (accessDirty)
                            await saveItemAccess(access, props.path);
                        loadContent(true);
                    } catch (error) {
                        alert(error);
                    }
                }}>Spara</button>
            </div>}
        </form >


    </>
}
