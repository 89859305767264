import { useEffect, useRef, useState } from "react";
import { RosettaItem } from "../../../data/Data";
import { shuffleArray } from "../../../utils/shuffle-array";
import { CenterContent } from "../../CenterContent";
import { CardsWrapper } from "../CardsWrapper";
import { ContentFrame } from "../ContentFrame";
import { nod, rotateIn, shake } from "../../../utils/css-animation";
import { delay } from "../../../utils/utils";
import { set } from "firebase/database";
import { MarkdownView } from "../../MarkdownView";

export function TypeRosettaView(props: { items: RosettaItem[], onReset: () => void }) {
    const [roundNumber, setRoundNumber] = useState(0);
    const [questions, setQuestions] = useState<RosettaItem[]>([]);
    const [question, setQuestion] = useState<RosettaItem | null>(null);
    const [results, setResults] = useState(props.items.length);
    const [resultMap, setResultMap] = useState<number[]>([]);

    function reset() {
        console.log('RESET');
        const questions = shuffleArray(props.items);
        setQuestions(questions);
        setQuestion({ ...questions[0] });
        setResults(props.items.length);
        let resultMap: number[] = [];
        for (let q of questions) {
            resultMap.push(0);
        }
        setResultMap([...resultMap]);
    }

    useEffect(() => {

        // setTimeout(() => {
        reset();

        // }, 1000);
    }, [props.items]);

    if (results < 1) return <>

        <ContentFrame>
            <h3>Bra jobbat! <button onClick={() => {
                reset();
                props.onReset();
            }}>Upprepa övningen</button></h3>
        </ContentFrame>

    </>

    return <>

        <ContentFrame>
            <RosettaRound roundNumber={roundNumber} question={question} answers={props.items} finished={(clickCount: number) => {
                console.log('finished round', clickCount);
                const idx = questions.indexOf(question!);
                const newQuestions = [...questions];
                if (clickCount === 1) {
                    setResults(results - 1);
                    newQuestions.shift();
                } else {
                    newQuestions.push(newQuestions.shift()!);
                }
                setQuestions(newQuestions);
                setQuestion({ ...newQuestions[0] });
            }}
            />
            {/* <p>questions: {JSON.stringify(questions)}</p>
                <p>results: {JSON.stringify(results)}</p> */}
        </ContentFrame>

    </>
}

let WAITING = false;

export function RosettaRound(props: { question: RosettaItem | null, answers: RosettaItem[], roundNumber: number, finished: (count: number) => void }) {

    const items: RosettaItem[] = shuffleArray([...props.answers]);
    const [clickCount, setClickCount] = useState(0);

    useEffect(() => {
        setClickCount(0);
    }, [props.answers, props.question]);

    if (!props.answers) return null;
    if (!props.question) return null;

    function itemClick(item: RosettaItem, el: HTMLElement) {
        if (WAITING) return;
        WAITING = true;
        const correct = (JSON.stringify(item) === JSON.stringify(props.question));
        correct ? el.style.borderColor = 'lime' : el.style.borderColor = 'tomato';
        correct ? nod(el) : shake(el);
        setTimeout(() => {
            setClickCount(clickCount + 1);
            if (correct)
                props.finished(clickCount + 1);
            WAITING = false;
            el.style.borderColor = 'gray'
        }, 800);
    }
    //äöäöä
    return <div className="Flex FlexColumn">
        <div className="RosettaInfo">
            <h3>Gör så här:</h3>
            <p>Klicka på det svarskort som bäst motsvarar det frågekort som visas.
                Övningen upprepas tills du har svarat rätt på alla kort på första försöket.</p></div>
        <h3>Frågekort</h3>
        <RosettaCard showAnswer={false} item={props.question} index={0} animate={true} itemClick={_ => { }} />
        <h3>Svarskort</h3>
        <RosettaCards items={items} itemClick={itemClick} />
        {/* <p>Round Click count: {clickCount}</p> */}
    </div>
}

export function RosettaCards(props: { items: RosettaItem[], itemClick?: (item: RosettaItem, el: HTMLElement) => void }) {
    return <CardsWrapper css={{ padding: '1rem', gap: '2rem' }}>
        {props.items.map((item, index) => <RosettaCard index={index} showAnswer={true} key={index} item={{ ...item }} itemClick={props.itemClick} animate={true} />)}
    </CardsWrapper>
}

export function RosettaCard(props: { item: RosettaItem, index: number, showAnswer: boolean, animate: boolean, itemClick?: (item: RosettaItem, el: HTMLElement) => void }) {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!ref.current) return;
        if (!props.animate) return;
        // ref.current!.style.opacity = '0';
        setTimeout(() => {
            // ref.current!.style.opacity = '1';
            rotateIn(ref.current!);
        }, props.index * 200);
    }, [props.item]);

    const className = props.showAnswer ? 'RosettaCardAnswer' : 'RosettaCardQuestion';

    return <div className={'RosettaCard Shadow ' + className} style={{}} ref={ref} onClick={e => { props.itemClick!(props.item, ref.current!); }}>
        <MarkdownView markdown={props.showAnswer ? props.item.a : props.item.q} />
        {props.showAnswer && <div className="RosettaCorner">{props.index + 1}</div>}
    </div >
}

