import { ActionCreator, useStoreActions } from "easy-peasy";
import { Item } from "../../../data/Data";
import { useState } from "react";
import { saveItemText } from "../../../store/firebaseStore";

import { AppModel } from "../../../store/appModel";
import { MarkdownEditor } from "./MarkdownEditor";
import { EditResourcesView } from "./EditResourcesView";

export function AdminItemEdit(props: { item: Item, path: string }) {
    const loadContent: ActionCreator<boolean> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
    const [dirtyItem, setDirtyItem] = useState<Item>(props.item);

    const [original, setOriginal] = useState<string>(JSON.stringify(props.item));
    const [copy, setCopy] = useState<string>(JSON.stringify(props.item));
    const dirty = original !== copy;
    const showDirt = dirty ? "Dirty" : "Clean";

    return <>
        <div className="Flex PadV05 AlignCenter JustifyBetween" >
            <div className="Flex">
                <h3>{props.item.title}</h3>
            </div>
            <div>
                {dirty && <button className="Alert" onClick={_ => {
                    // saveContentItem(dirtyItem, props.path + '/' + dirtyItem.id);
                    saveItemText(dirtyItem.text, props.path);
                    loadContent(true);

                    setOriginal(JSON.stringify(dirtyItem));
                    setCopy(JSON.stringify(dirtyItem));

                }}>Save item</button>}
                {!dirty && <p>{showDirt}</p>}
            </div>
        </div >
        <MarkdownEditor item={props.item} onChange={mditem => {
            setCopy(JSON.stringify(mditem));
            setDirtyItem(mditem);
        }} />

        <EditResourcesView item={props.item} />

    </>
}
