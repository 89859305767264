import WebAudio from "../audio/WebAudio";
import { SplendidGrandPiano, CacheStorage, SamplerNote, Sampler } from "../smplr";
import { Testvoice } from "../smplr/testvoice";
import { StrandFlHarm8 } from "../smplr/StrandFlHarm8";

import appStore from "../store/appStore";

console.log('appStore', appStore);

// appStore.getActions().music.setMidinotes([40, 50, 60]);


export class Playhandler {
    private static instance: Playhandler;
    public context: AudioContext;
    public instrument: SplendidGrandPiano;
    public playingMidiNotes: number[] = [];
    // public instrument: Testvoice;
    // public instrument: StrandFlHarm8;
    // public voice: Testvoice;
    public element: HTMLElement | undefined = undefined;
    public pointerElement: HTMLElement | undefined = undefined;

    /**
     * The Playhandler's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
    private constructor() {
        // this.context = new AudioContext();
        this.context = WebAudio.context;
        this.instrument = new SplendidGrandPiano(this.context);
        // this.instrument = new Testvoice(this.context);
        // this.instrument = new StrandFlHarm8(this.context);
        this.instrument.loaded().then(() => {
            console.log('Instrument is loaded!');
        });
        this.timerHandle = window.setInterval(this.timerLoop, 500);
    }

    /**
     * The static method that controls the access to the Playhandler instance.
     *
     * This implementation let you subclass the Playhandler class while keeping
     * just one instance of each subclass around.
     */


    public static getInstance(): Playhandler {
        if (!Playhandler.instance) {
            Playhandler.instance = new Playhandler();
        }
        return Playhandler.instance;
    }

    public init() {
        // console.log('init');
    }

    private currentElemement: HTMLElement | undefined = undefined;
    private currentAnimation: Animation | undefined = undefined;

    private stopBeforePlay() {
        this.isPlaying = false;
        appStore.getActions().music.setScorePlaybackStatus({ type: 'stopped' });
        this.instrument.stop();
        if (this.element && this.pointerElement) {
            console.log(this.pointerElement);
            try {
                this.element.removeChild(this.pointerElement);
            } catch (error) {
                console.log(error);
            }
            this.pointerElement = undefined;
        }
        if (this.currentAnimation) {
            this.currentAnimation.pause();
        }
    }

    private isPlaying: boolean = false;
    private timerHandle: number = 0;
    private timerLoop = () => {
        if (!this.isPlaying) return;
        const now = this.context.currentTime;
        const playbackTime = now - this.startDelta;;
        console.log('playbackTime', playbackTime, this.stoptime);
        if (playbackTime > this.stoptime) {
            this.stop();
        }
    }

    private startDelta: number = 0;
    private stoptime: number = 0;

    public play(playdata: PlayNotesData, positions: PlayPositionsData, id: string, element: HTMLElement | undefined) {
        appStore.getActions().music.setMidinotes([]);
        this.playingMidiNotes = [];
        this.stopBeforePlay();
        console.log(id);
        // animation ======================================
        if (element) {
            this.element = element;
            let svgWidth = element.firstElementChild!.clientWidth;
            this.createPointerElement(element);
            //--------------------------------------------------
            while (positions.positions[0].duration === 0 && positions.positions[0].position === 0) {
                positions.positions.shift();
            }

            const keyframes: Keyframe[] = [];
            positions.positions.forEach((position, index) => {
                const frame: Keyframe = {
                    left: (position.x / positions.width) * svgWidth + 'px',
                    top: '0',
                    height: '100%',
                    offset: position.position / positions.duration,
                }
                keyframes.push(frame);
            });

            let animation;
            if (this.pointerElement) {
                const options: KeyframeAnimationOptions = { duration: (positions.duration / 24) * 1000, fill: 'forwards' };
                this.currentAnimation = this.pointerElement.animate(keyframes, options);
                this.currentAnimation.currentTime = 0;
                this.currentAnimation.play();
            }
        }

        // playback ======================================
        const playdataTransformed = transformDurations(playdata);
        this.startDelta = this.context.currentTime;
        this.stoptime = playdataTransformed.duration;
        console.log('Playdata duration', playdataTransformed.duration);
        this.isPlaying = true;

        playdataTransformed.notes.forEach((note) => {
            this.instrument.start({
                note: note.note, time: this.startDelta + note.time, duration: note.duration, velocity: note.volocity * .7,
                onEnded: (par: any) => {
                    const idx = this.playingMidiNotes.indexOf(par.note);
                    const notes = this.playingMidiNotes;
                    notes.splice(idx, 1);
                    this.playingMidiNotes = [...notes];
                    appStore.getActions().music.setMidinotes([...this.playingMidiNotes]);
                },
                onStarted: (par: any) => {
                    if (par.note) {
                        this.playingMidiNotes.push(par.note);
                        appStore.getActions().music.setMidinotes([...this.playingMidiNotes]);
                    }
                }
            });
        });
        appStore.getActions().music.setScorePlaybackStatus({ type: 'playing', id: id });
    }

    public stop() {
        this.isPlaying = false;
        if (this.currentAnimation) {
            this.currentAnimation.pause();
            this.currentAnimation.currentTime = 0;
            this.currentAnimation = undefined;
            if (this.element && this.pointerElement) {
                this.element.removeChild(this.pointerElement);
            }
        }
        this.instrument.stop();
        appStore.getActions().music.setScorePlaybackStatus({ type: 'stopped' });
        appStore.getActions().music.setMidinotes([]);
        this.playingMidiNotes = [];
    }

    private createPointerElement(element: HTMLElement) {
        console.log(this.element?.children.length);
        this.pointerElement = document.createElement('div');
        this.pointerElement.style.position = 'absolute';
        this.pointerElement.style.width = '3px';
        this.pointerElement.style.height = '100%';
        this.pointerElement.style.backgroundColor = 'orange';
        this.pointerElement.style.left = '2px';
        this.pointerElement.style.top = '0px';
        element.appendChild(this.pointerElement);
    }
}

export type PlayNote = {
    note: number;
    volocity: number;
    duration: number;
    time: number;
    partidx: number;
    voiceidx: number;
    noteidx: number;
}

export type PlayNotes = PlayNote[];

export type PlayNotesData = {
    notes: PlayNotes;
    duration: number;
}

export type PlayPosition = {
    duration: number,
    position: number,
    x: number,
}

export type PlayPositions = PlayPosition[];

export type PlayPositionsData = {
    positions: PlayPositions,
    width: number,
    height: number,
    duration: number
}

export function transformDurations(data: PlayNotesData): PlayNotesData {
    const notes = data.notes.map((note: PlayNote) => {
        const duration = note.duration / 24;
        const time = note.time / 24;
        return { ...note, duration, time }
    });
    return { duration: data.duration / 24, notes: [...notes] };
}

