import { CenterContent } from "../../CenterContent";

export function FrontKakTeacher() {
    return <>
        <CenterContent>
            <h1 className="Jumbo">Välkommen, lärare!</h1>
        </CenterContent>
        <CenterContent>
            <h3>FrontKakTeacher</h3>
        </CenterContent>
    </>
}