import { Action, Computed, FilterActionTypes, Helpers, StateMapper, Thunk, action, computed, thunk } from "easy-peasy";
import { AppModel } from "./appModel";
import { Item, findChildFromPath } from "../data/Data";
import { ContentStatus } from "./content";

type ClassRoomPage =
    | "overview"
    | "discussions"
    | "events"
    | "createEvent"

type AdminPage =
    | "content"
    | "users"
    | "createuser"
    | "edituser"
    | "importusers"
    | "userhistory"
    | "scorxlist"
    | "scrapbook"

export type LocationStatus =
    | { type: "front" }
    | { type: "classroom", page: ClassRoomPage }
    | { type: "material" }
    | { type: "book" }
    | { type: "repertoir" }
    | { type: "desktop" }
    | { type: "admin", page: AdminPage, uid: string }
    | { type: "search", text: string, tags: string[] }
    ;

export type LocationModel = {
    path: string;
    setPath: Action<LocationModel, string>;

    status: LocationStatus;
    update: Action<LocationModel, LocationStatus>;
    setStatus: Thunk<LocationModel, LocationStatus>;

    shelf: Item | null;
    setShelf: Action<LocationModel, Item | null>;
    shelfPath: string;
    setShelfPath: Action<LocationModel, string>;


    book: Item | null;
    setBook: Action<LocationModel, Item | null>;
    bookPath: string;
    setBookPath: Action<LocationModel, string>;

    chapter: Item | null;
    setChapter: Action<LocationModel, Item | null>;
    chapterPath: string;
    setChapterPath: Action<LocationModel, string>;


    subchapter: Item | null;
    setSubchapter: Action<LocationModel, Item | null>;
    subchapterPath: string;
    setSubchapterPath: Action<LocationModel, string>;

    clearContent: Action<LocationModel, void>;
    setupLocation: Thunk<LocationModel, void>;
    getBase: Thunk<LocationModel, string>;

    statusIdx: Computed<LocationModel, number>,

};

export const createLocation = (): LocationModel => {
    return {
        path: "",
        setPath: action((state, payload) => {
            state.path = payload;
        }),
        //----------------------------------------
        status: { type: "front" },
        update: action((state, payload) => {
            state.status = payload;
        }),
        setStatus: thunk(
            async (actions, s: LocationStatus, { getStoreActions }) => {
                actions.update(s);
            }
        ),
        //----------------------------------------


        shelf: null,
        setShelf: action((state, payload) => {
            state.shelf = payload;
        }),
        shelfPath: "",
        setShelfPath: action((state, payload) => {
            state.shelfPath = payload;
        }),


        book: null,
        setBook: action((state, payload) => {
            state.book = payload;
        }),
        bookPath: "",
        setBookPath: action((state, payload) => {
            state.bookPath = payload;
        }),


        chapter: null,
        setChapter: action((state, payload) => {
            state.chapter = payload;
        }),
        chapterPath: "",
        setChapterPath: action((state, payload) => {
            state.chapterPath = payload;
        }),


        subchapter: null,
        setSubchapter: action((state, payload) => {
            state.subchapter = payload;
        }),
        subchapterPath: "",
        setSubchapterPath: action((state, payload) => {
            state.subchapterPath = payload;
        }),

        clearContent: action((state, payload) => {
            state.path = "";

            state.shelf = null;
            state.shelfPath = "";
            state.book = null;
            state.bookPath = "";
            state.chapter = null;
            state.chapterPath = "";
            state.subchapter = null;
            state.subchapterPath = "";
            state.status = { type: "front" };
            window.location.hash = "";
        }),
        //----------------------------------------
        setupLocation: thunk((actions, payload) => {
            console.log("setupLocation");
            window.onpopstate = (event) => {
                actions.getBase('window.onpopstate');
            };
            actions.getBase('setupLocation');
        }),

        getBase: thunk((actions, payload, helpers: Helpers<LocationModel, any, any>) => {
            const path = window.location.hash.replace("#", "").toLowerCase();
            actions.setPath(path);
            if (path === "") {
                actions.setStatus({ type: "front" });
                return;
            }

            const segments = path.split("/");

            switch (segments[0]) {
                case 'classroom':
                    actions.setStatus({ type: "classroom", page: segments[1] as ClassRoomPage });
                    return;
                case 'repertoir':
                    actions.setStatus({ type: "repertoir" });
                    return;
                case 'desktop':
                    actions.setStatus({ type: "desktop" });
                    return;
                case 'admin':
                    actions.setStatus({ type: "admin", page: segments[1] as AdminPage, uid: segments[2] });
                    return;
                case 'search':
                    const tags = [...segments].filter((item, idx) => item.length > 0);
                    tags.shift();
                    actions.setStatus({ type: "search", text: '', tags: tags });
                    return;

                case 'book':
                case 'material':
                    actions.setStatus({ type: segments[0] });
                    if (segments.length <= 1) return { shelf: null, book: null, chapter: null, subchapter: null };


                    // const currentLocation = helpers.getStoreState().location;
                    const contentStatus = helpers.getStoreState().content.status;
                    const currentAccess = helpers.getStoreState().userdata.currentAccess;
                    console.log('getBase material', contentStatus, currentAccess);

                    if (contentStatus.type != 'house') return { shelf: null, book: null, chapter: null, subchapter: null };
                    let room = contentStatus.house.children.find((item: Item) => item.id === currentAccess.id);
                    if (!room) return { shelf: null, book: null, chapter: null, subchapter: null };
                    //------------------------------------------------------------------
                    let shelfPath = segments[1];
                    let bookPath = [segments[1], segments[2]].join('/');
                    let chapterPath = [segments[1], segments[2], segments[3]].join('/');
                    let subchapterPath = [segments[1], segments[2], segments[3], segments[4]].join('/');
                    actions.setShelfPath(shelfPath);
                    actions.setBookPath(bookPath);
                    actions.setChapterPath(chapterPath);
                    actions.setSubchapterPath(subchapterPath);

                    let shelf = findChildFromPath(room, shelfPath);
                    let book = findChildFromPath(room, bookPath);
                    let chapter = findChildFromPath(room, chapterPath);
                    if (!chapter && book && book.children) {
                        chapter = book.children[0];
                    }
                    let subchapter = findChildFromPath(room, subchapterPath);
                    if (!subchapter && chapter && chapter.children) {
                        subchapter = chapter.children[0];
                    }

                    actions.setShelf(shelf);
                    actions.setBook(book);
                    actions.setChapter(chapter);
                    actions.setSubchapter(subchapter);

                    return;
            }
        }),
        statusIdx: computed([(state) => state.status], (status) => {
            switch (status.type) {
                case "front": return 0;
                case "classroom": return 1;
                case "material": return 2;
                case "book": return 3;
                case "search": return 4;
                case "desktop": return 5;
                case "admin": return 6;
            }
            return 0;
        }),

    };
};

export type CurrentContentData = {
    shelf: Item | null,
    book: Item | null,
    chapter: Item | null,
    subchapter: Item | null
}

function getItemsFromPath(status: ContentStatus, path: String): CurrentContentData {
    if (status.type != 'house') return { shelf: null, book: null, chapter: null, subchapter: null };
    return { shelf: null, book: null, chapter: null, subchapter: null };
}

