import { ActionCreator, useStoreActions, useStoreState } from "easy-peasy";
import { AppModel } from "../../../store/appModel";
import { ScorxModel } from "../../../store/scorxmodel";
import { useEffect } from "react";
import { TreeItem } from "../../general/TreeItem";
import { Recordings, ScorxItem } from "../../music/player/PlayerTypes";

export function ScorxListView() {
    // const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    // const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    // const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    // const loadMethod: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
    const scorx: ScorxModel = useStoreState<AppModel>((state) => state.scorx);
    const loadScorxList: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.scorx.loadScorxList);

    useEffect(() => {
        loadScorxList();
    }, []);

    return <>
        <h3>ScorxListView</h3>
        {Object.entries(scorx.scorxList).map(([key, value]) => <ScorxListItem item={value} key={key} />)}

    </>
}
export function ScorxListItem(props: { item: ScorxItem }) {
    const loadRecordings: ActionCreator<Recordings> = useStoreActions<AppModel>((actions) => actions.scorxPlayer.loadRecordings);
    return <TreeItem header={
        <h4 className="NoMargin">{props.item.scorxId} {props.item.title}</h4>
    }>
        <button onClick={e => {
            console.log(props.item.recordings);
            loadRecordings(props.item.recordings);
        }}>Load recordings</button>
        <p>{JSON.stringify(props.item)}</p>
    </TreeItem>
}