import { Action, action } from "easy-peasy";

export type FrontFunctionsModel = {
    showPitch: boolean;
    setShowPitch: Action<FrontFunctionsModel, boolean>;
    showPiano: boolean;
    setShowPiano: Action<FrontFunctionsModel, boolean>;
    showScorx: boolean;
    setShowScorx: Action<FrontFunctionsModel, boolean>;
}

export const createFrontFunctionsModel = (): FrontFunctionsModel => {
    return {
        showPitch: false,
        setShowPitch: action((state, payload) => {
            state.showPitch = payload;
        }),
        showPiano: false,
        setShowPiano: action((state, payload) => {
            state.showPiano = payload;
        }),
        showScorx: false,
        setShowScorx: action((state, payload) => {
            state.showScorx = payload;
        }),
    }
}