import { Blob } from "buffer";
import { Item, dataToItem } from "../data/Data";

export async function loadContent(currentRoom: string | null) {
    const kakadata = await fetch('/kak2020-data-export.json');
    const data: any = await kakadata.json();
    let item: Item = dataToItem(data);
    if (currentRoom && item.children && item.children.length > 0) {
        item = item.children!.find(i => i.id === currentRoom) || item;
    } else {
        item = item.children![0];
    }
    console.log(JSON.stringify(item));
}

export const generateId = () => (Math.random() + 1).toString(36).substring(7);

export const sanitizePath = (path: string): string => {
    if (path.startsWith('/')) {
        path = path.substring(1);
    }
    return path;
}

export const linkTo = (path: string) => {
    window.location.hash = path;
}

export const MATERIAL_TAG = 'material';
export const BOOK_TAG = 'book';

export const emailToRefPath = (email: string): string => {
    return email.replaceAll("@", "_AT_").replaceAll(".", "_DOT_");
}

export const pathToEmail = (path: string): String => {
    return path.replaceAll("_AT_", "@").replaceAll("_DOT_", ".");
}

export const round2 = (num: number): number => {
    return Math.round(num * 100) / 100;
}

export const round3 = (num: number): number => {
    return Math.round(num * 1000) / 1000;
}

export const getTimePath = (): string => {
    const date = new Date();
    return date.toLocaleDateString('sv-SE') + '-' + date.toLocaleTimeString('sv-SE').replaceAll(':', '-');
}

export const createTimePath = (date: Date) => {
    return date.toLocaleDateString() + '-' + date.toLocaleTimeString().replaceAll(':', '-');
}

export const getSwedishDate = (): string => {
    return new Date().toLocaleDateString('sv-SE', { month: '2-digit', day: '2-digit', year: 'numeric' });
}

export const getXhrBlob = (url: string): Promise<Blob> => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.open('GET', url);
        xhr.onload = () => resolve(xhr.response);
        xhr.onerror = () => reject(xhr.statusText);
        xhr.send();
    });
}

// const SMOOTHING = 0.1;
export const valueSmoothing = (value: number, smoothing: number, lastValue: number): number => {
    return (value * smoothing) + (lastValue * (1 - smoothing));
}

export const delayFunction = (fn: Function, delay: number): Promise<boolean> => {
    return new Promise((res, rej) => {
        setTimeout(() => {
            fn();
            res(true);
        }, delay);
    });
};

export const timeWOSeconds = (time: string): string => {
    const segments = time.split(':');
    segments.pop();
    return segments.join(':');
}

export const delay = (ms: number): Promise<void> => {
    return new Promise((res, rej) => {
        setTimeout(() => {
            res();
        }, ms);
    });
}

export const dateOnly = (s: string): string => {
    return s.substring(0, 13);
}

export const capitalize = (s: string): string => {
    return s.charAt(0).toUpperCase() + s.slice(1);
}