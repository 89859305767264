import { createStore, createTypedHooks, persist } from 'easy-peasy';
import storeDefinition, { AppModel } from './appModel';

// use persist to save state to local storage
// const store = createStore<StoreModel>(persist(storeDefinition));
const appStore = createStore<AppModel>(storeDefinition);
const typedHooks = createTypedHooks<AppModel>();
export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;
export default appStore;

