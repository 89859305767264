import { motion } from "framer-motion";
import { SideView } from "../SideView";
import { LocationModel } from "../../store/location";
import { ActionCreator, useStoreActions, useStoreState } from "easy-peasy";
import { AppModel } from "../../store/appModel";
import { BookTOCView } from "./BookTOCView";
import { CenterContent } from "../CenterContent";
import Markdown from "markdown-to-jsx";
import { MarkdownView } from "../MarkdownView";
import { ItemDiscussionView } from "./discussion/ItemDiscussionView";
import { PdfView } from "../PdfView";
import { capitalize, linkTo, pathToEmail } from "../../utils/utils";
import { BookmarkView } from "./BookmarkView";
import { Item } from "../../data/Data";
import { TypePitchView } from "./types/TypePitchView";
import { TypeRosettaView } from "./types/TypeRosettaView";
import { ContentModel } from "../../store/content";
import { UserDataModel } from "../../store/userdata";
import { KDistActive, KdistContentModel } from "../../store/kdistcontent";
import { useEffect } from "react";
import { TypeGlossaryView } from "./types/TypeGlossaryView";

export function BookView() {
    const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    const content: ContentModel = useStoreState<AppModel>((state) => state.content);

    if (!location.book) return <CenterContent>
        <img style={{ width: '3rem' }} src="assets/svg-loaders/grid.svg" alt="loading" className="Loading" />
    </CenterContent>

    return <>
        <motion.div className="Flex FlexColumn AlignStretch JustifyStretch OFAuto Flex1"
            initial={{ transform: "translateX(100%)" }}
            animate={{ transform: "translateX(0)" }}
            transition={{ duration: .2 }}
        >
            <CenterContent>
                <div className="Flex JustifyBetween BookHeader">
                    <h5>{location.book.title}</h5>
                    <p style={{ fontSize: '70%' }}>Publiceras permanent</p>
                </div>
                <>
                    {location.chapter && <BookItemView item={location.chapter} isChapter={true} room={content.currentRoom.id} />}
                    {location.subchapter && <BookItemView item={location.subchapter} isChapter={false} room={content.currentRoom.id} />}
                </>
            </CenterContent>
        </motion.div >
        <SideView >
            <button onClick={e => {

                const segments = location.path.split('/');
                segments.shift();
                segments.unshift('material');
                const path = segments.join('/');
                linkTo(path);
            }}>‹ Till kursmaterial</button>
            <h3>{location.book.title}</h3>

            <BookTOCView />
            {/* <KDistItemActiveView item={location.book} room={content.currentRoom.id} /> */}

        </SideView>
    </>
}

export function BookItemView(props: { item: Item, isChapter: boolean, room: string }) {

    const rosettaItem = (props.item.type && props.item.type.t === 'rosetta') ? <TypeRosettaView items={props.item.type.items} /> : null;
    const glossaryItem = (props.item.type && props.item.type.t === 'glossary') ? <TypeGlossaryView types={props.item.type.types} /> : null;

    return <>
        {props.isChapter && <h1>{props.item.title}</h1>}
        {!props.isChapter && <h2>{props.item.title}</h2>}

        <MarkdownView markdown={props.item.text} />
        {props.item.type && props.item.type.t === 'pdf' && <PdfView url={props.item.type.url} />}
        {props.item.type && props.item.type.t === 'pitch' && <TypePitchView item={props.item} data={props.item.type.data} />}

        {rosettaItem}
        {glossaryItem}

        <ItemDiscussionView item={props.item} />
        {props.isChapter && props.item.children && props.item.children.length === 0 && <BookmarkView item={props.item} />}
        {!props.isChapter && <BookmarkView item={props.item} />}
    </>
}

export function KDistItemActiveView(props: { item: Item, room: string }) {
    const userdataModel: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    const kdistContentModel: KdistContentModel = useStoreState<AppModel>((state) => state.kdistContentModel);
    const loadAllConnectionsItems: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.kdistContentModel.loadAllConnectionsItems);

    const saveKdistUserItemConnection: ActionCreator<{ uid: string, itemId: string, active: KDistActive }> = useStoreActions<AppModel>((actions) => actions.kdistContentModel.saveKdistUserItemConnection);

    useEffect(() => {
        loadAllConnectionsItems();
    }, []);

    if (userdataModel.status.type !== 'userdata') return null;
    if (kdistContentModel.status.type !== 'items') return null;

    const userdata = userdataModel.status.userdata;
    let connections = kdistContentModel.status.connectionsItems.filter((item, index) => item.uid === pathToEmail(userdata.uid!));

    connections = connections.filter((item, index) => item.itemId === props.item.id);
    connections = connections.filter((item, index) => item.uid === pathToEmail(userdata.uid!));

    const active = connections.length > 0 ? connections[0].active : 'inactive';

    if (active === 'active') return <>
        <p className="Label">Status:</p>
        <p className="Statuslabel Strong active">Aktiv</p>
        <p className="SmallText">Du kan arbeta med innehållet i <strong>{props.item.title}</strong>. När du bedömer att du behärskar innehållet i kapitlen (se innehållsförteckningen ovan), klicka på knappen nedan.</p>
        <button className="SmallText" onClick={e => {

            saveKdistUserItemConnection({ uid: userdata.uid!, itemId: props.item.id, active: 'self-estimated' });

        }}>Ja, jag bedömer att jag behärskar innehållet i <strong>{props.item.title}</strong></button>
    </>

    if (active === 'self-estimated') return <>
        <p className="Label">Status:</p>
        <p className="Statuslabel Strong self-estimated">Självskattad</p>
        <p className="SmallText">Du har angivit att du har kunskaper som motsvarar innehållet i  <strong>{props.item.title}</strong>. Du kan ändra status till Aktiv om du inser att du inte har dessa kunskaper.</p>
        <button className="SmallText" onClick={e => {
            saveKdistUserItemConnection({ uid: userdata.uid!, itemId: props.item.id, active: 'active' });
        }}>Jag vill ändra status från Självskattad till Aktiv för <strong>{props.item.title}</strong></button>
    </>

    const activeText = active == 'godkänd' ? 'Godkänd' : active == 'val-godkand' ? 'Väl godkänd' : 'Inaktiv';
    const activeStyle = String(active);

    return <>
        <p className="Label">Status:</p>
        <p className={"Statuslabel Strong " + activeStyle}>{capitalize(activeText)}</p>
    </>
}   