import { useStoreState } from "easy-peasy";
import { ContentUserModel } from "../../store/contentuser";
import { AppModel } from "../../store/appModel";
import { useEffect, useState } from "react";
import { confirmCourse, createDummyConfirmSept2024, getConfirmCourse } from "../../store/firebaseStore";

type Confirmation = 'loading' | 'true' | 'false';

export function ConfirmCourseView() {
    const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);

    const [confirmed, setConfirmed] = useState<Confirmation>('loading');

    useEffect(() => {
        const doit = async () => {
            if (contentuser.contentUserData) {
                setTimeout(async () => {
                    const val = await getConfirmCourse(contentuser.contentUserData.uid!);
                    console.log(val);
                    if (val) {
                        setConfirmed('true');
                    } else {
                        setConfirmed('false');
                    }
                }, 500);
            }
        }
        doit();
    }, []);

    switch (confirmed) {
        case 'loading':
            return <p className="Pad1">Laddar...</p>
        case 'false':
            return <div className="Pad1 Flex FlexColumn">
                <p>{contentuser.contentUserData.firstName}, genom att klicka på knappen nedan så bekräftar du att du vill</p>
                <ul>
                    <li>fortsätta som kursdeltagare i Mellansels folkhögskolas konstnadsfria körsångarkurs Körakademin under höstterminen 2024</li>
                    <li>ha fortsatt fri tillgång till kursmaterialet på korakademin.se</li>
                    <li>ha fortsatt fri tillgång till allt inspelat material på WeAreVoice (wearevoice.se)</li>
                </ul>

                <button style={{ padding: '.7rem' }} className="Selected" onClick={e => {
                    console.log(contentuser.fullName);
                    if (contentuser.contentUserData && contentuser.fullName)
                        confirmCourse(contentuser.contentUserData.uid!, String(contentuser.fullName))
                    setConfirmed('true');
                }}>Ja, jag vill fortsätta som kursdeltagare!</button>


            </div>
        case 'true':
            return <div className="Pad1 Flex FlexColumn">
                <p>Tack för att du har bekräftat ditt kursdeltagande i Körakdemin!</p>
                <p>Har du tagit del av kursplanen? <a href="#book/kursdokument/kursplan-hostterminen-2023">Klicka här!</a></p>
                <p><a href="#book/kursdokument/kom-igang-med-studierna">Klicka här</a> för information om hur du kommer igång med dina studier.</p>
            </div>
    }
    return <></>

}



