import { valueSmoothing } from "./utils";

export default class FreqSmoothing {
    private static instance: FreqSmoothing;
    private constructor() { }
    public static getInstance(): FreqSmoothing {
        if (!FreqSmoothing.instance) FreqSmoothing.instance = new FreqSmoothing();
        return FreqSmoothing.instance;
    }
    private previousFrequency: number = 0;
    public smooth(frequency: number, manFilter?: number | null): number {
        if (manFilter) { frequency = frequency > manFilter ? frequency / 2 : frequency; }
        if (!this.previousFrequency) this.previousFrequency = frequency;
        if (Math.abs(frequency - this.previousFrequency) > 10) this.previousFrequency = frequency;
        return this.previousFrequency = valueSmoothing(frequency, 0.1, this.previousFrequency);
    }

}