import { PianoSingleton } from "./PianoSingleton";
import "./Keyboard.css"
import { round2, round3 } from "../../utils/utils";
import { useEffect, useRef } from "react";

PianoSingleton.getInstance().init();


const getKeyXFactor = (key: number) => {
    let octaveKey = key % 12;
    switch (octaveKey) {
        case 0:
        case 2:
        case 4:
        case 5:
        case 7:
        case 9:
        case 11: {
            // white
            let whiteNumber = 0;
            switch (octaveKey) {
                case 0: whiteNumber = 0; break;
                case 2: whiteNumber = 1; break;
                case 4: whiteNumber = 2; break;
                case 5: whiteNumber = 3; break;
                case 7: whiteNumber = 4; break;
                case 9: whiteNumber = 5; break;
                case 11: whiteNumber = 6; break;
                default: throw new Error("Invalid octaveKey", octaveKey);
            }
            let whiteX = 1 / 7 * whiteNumber;
            return whiteX;
        }
        case 1:
        case 3:
        case 6:
        case 8:
        case 10: {
            let blackFactor = 0;
            switch (octaveKey) {
                case 1: blackFactor = .08; break;
                case 3: blackFactor = .24; break;
                case 6: blackFactor = .51; break;
                case 8: blackFactor = .66; break;
                case 10: blackFactor = .82; break;
                default: throw new Error("Invalid octaveKey");
            }
            let blackX = blackFactor;
            return blackX;
        }
        default: console.log('default', octaveKey);
            return 0;
    }
}

const getKeyOctave = (key: number) => {
    return Math.floor(key / 12);
}

const getIsWhite = (key: number) => {
    let octaveKey = key % 12;
    switch (octaveKey) {
        case 0:
        case 2:
        case 4:
        case 5:
        case 7:
        case 9:
        case 11: return true;
        default: return false;
    }
}

type KeyboardProps = {
    first: number,
    last: number,
    scale: number,
    playNote: (midiNumber: number) => void,
    stopNote: (midiNumber: number) => void,
    mark?: number[],
    mark2?: number[],
    names?: number[],
}

export function Keyboard(props: KeyboardProps) {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    let first = props.first;
    let last = props.last;
    let whiteKeys = [];
    let blackKeys = [];
    let octaveWidth = 200;
    let keyboardHeight = 110;
    const WHITE_MARGIN = 1.2;
    let whiteKeyWidth = round2(octaveWidth / 7 - WHITE_MARGIN);
    let blackKeyWidth = round2(octaveWidth / 12);

    let firstX = getKeyOctave(first) * octaveWidth + getKeyXFactor(first) * octaveWidth;
    let lastX2 = 0;

    for (let i = first; i < last + 1; i++) {
        let octave = getKeyOctave(i);
        let keyXFactor = getKeyXFactor(i);
        let x = round2((keyXFactor * octaveWidth) + (octave * octaveWidth) - firstX);
        if (getIsWhite(i)) {
            whiteKeys.push(<rect className="key whiteKey" key={i} x={x} y={0} width={whiteKeyWidth - WHITE_MARGIN} height={keyboardHeight} stroke="grey" strokeWidth="1"
                onMouseDown={() => {
                    props.playNote && props.playNote(i);
                }}
                onMouseUp={() => {
                    props.stopNote && props.stopNote(i);
                }}
            />);
            whiteKeys.push(<line key={i + 'line'} x1={x} y1={keyboardHeight - 1} x2={x + whiteKeyWidth} y2={keyboardHeight - 1} stroke="gray" strokeWidth="3" />);

            lastX2 = x + whiteKeyWidth;
        } else {
            blackKeys.push(<rect className="key blackKey" key={i} x={x} y={0} width={blackKeyWidth} height={keyboardHeight * .6} stroke="gray" strokeWidth="1"
                onMouseDown={() => {
                    props.playNote && props.playNote(i);
                }}
                onMouseUp={() => {
                    props.stopNote && props.stopNote(i);
                }}
            />);
            lastX2 = x + blackKeyWidth;
        }
    }

    useEffect(() => {
        if (!canvasRef.current) return;
        if (!props.mark) return;
        const ctx = canvasRef.current!.getContext('2d');
        // ctx!.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
        props.mark.forEach(m => {
            let octave = getKeyOctave(m);
            let keyXFactor = getKeyXFactor(m);
            let x = round2((keyXFactor * octaveWidth) + (octave * octaveWidth) - firstX);
            if (getIsWhite(m)) {
                ctx!.fillStyle = 'orange';
                ctx!.fillRect(x + whiteKeyWidth * 0.2, keyboardHeight * 0.7, whiteKeyWidth * 0.6 - WHITE_MARGIN, keyboardHeight * 0.15);
            } else {
                ctx!.fillStyle = 'orange';
                ctx!.fillRect(x + blackKeyWidth * 0.05, keyboardHeight * 0.3, blackKeyWidth * 0.8, keyboardHeight * 0.15);
            }
        });
    }, [props.mark])

    useEffect(() => {
        if (!canvasRef.current) return;
        if (!props.mark2) return;
        const ctx = canvasRef.current!.getContext('2d');
        // ctx!.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);

        props.mark2.forEach(m => {
            let octave = getKeyOctave(m);
            let keyXFactor = getKeyXFactor(m);
            let x = round2((keyXFactor * octaveWidth) + (octave * octaveWidth) - firstX);
            if (getIsWhite(m)) {
                ctx!.fillStyle = '#533575';
                ctx!.fillRect(x + whiteKeyWidth * 0.2, keyboardHeight * 0.7, whiteKeyWidth * 0.6 - WHITE_MARGIN, keyboardHeight * 0.15);
                // ctx!.fill();
            } else {
                ctx!.fillStyle = 'white';
                ctx!.fillRect(x + blackKeyWidth * 0.05, keyboardHeight * 0.3, blackKeyWidth * 0.8, keyboardHeight * 0.15);
            }
        });
    }, [props.mark2])


    useEffect(() => {
        if (!canvasRef.current) return;
        if (!props.names) return;
        let names = ['c', 'ciss', 'd', 'diss', 'e', 'f', 'fiss', 'g', 'giss', 'a', 'aiss', 'b']
        const ctx = canvasRef.current!.getContext('2d');
        ctx!.font = "16px Open Sans";
        // ctx!.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
        props.names.forEach(m => {
            let octave = getKeyOctave(m);
            let keyXFactor = getKeyXFactor(m);
            let x = round2((keyXFactor * octaveWidth) + (octave * octaveWidth) - firstX);
            let k = m % 12;
            if (getIsWhite(m)) {
                ctx!.fillStyle = '#533575';
                // ctx!.fillRect(x + whiteKeyWidth * 0.2, keyboardHeight * 0.7, whiteKeyWidth * 0.6 - WHITE_MARGIN, keyboardHeight * 0.15);
                ctx?.fillText(names[k], x + whiteKeyWidth * 0.2, keyboardHeight * 0.7 + 15);
            } else {
                ctx!.fillStyle = 'white';
                // ctx!.fillRect(x + blackKeyWidth * 0.05, keyboardHeight * 0.3, blackKeyWidth * 0.8, keyboardHeight * 0.15);
                ctx?.fillText(names[k], x + whiteKeyWidth * 0.2, keyboardHeight * 0.7);
            }
        });
    }, [props.names])

    return <>
        <div className="SvgKeyboard" style={{ position: 'relative' }}>
            <svg width={round2(lastX2 / props.scale) + 'rem'} height={round2(keyboardHeight / props.scale) + 'rem'} viewBox={`0 0 ${lastX2} ${keyboardHeight}`} xmlns="http://www.w3.org/2000/svg" >
                {whiteKeys}
                {blackKeys}
            </svg>
            <canvas ref={canvasRef} width={lastX2} height={keyboardHeight} style={{ position: 'absolute', left: '0', top: '0', width: round2(lastX2 / props.scale) + 'rem', height: round2(keyboardHeight / props.scale) + 'rem', pointerEvents: 'none' }} />
        </div>
    </>
}


