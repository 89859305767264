import {
    AudioBuffers,
    findFirstSupportedFormat,
    loadAudioBuffer,
} from "./sampler/load-audio";
import { toMidi } from "./sampler/midi";
import { Sampler, SamplerAudioLoader } from "./sampler/sampler";
import { HttpStorage, Storage } from "./storage";
export type TestvoiceConfig = {
    baseUrl: string;
    destination: AudioNode;
    storage?: Storage;
    detune: number;
    volume: number;
    velocity: number;
    decayTime?: number;
    lpfCutoffHz?: number;
};

const BASE_URL = "/sound/mix";

export class Testvoice extends Sampler {
    constructor(
        context: AudioContext,
        options: Partial<TestvoiceConfig> = {}
    ) {
        super(context, {
            destination: options.destination,

            detune: options.detune,
            volume: options.volume,
            velocity: options.velocity,
            decayTime: options.decayTime ?? 0.5,
            lpfCutoffHz: options.lpfCutoffHz,

            buffers: testvoiceLoader(
                options.baseUrl ?? BASE_URL,
                options.storage ?? HttpStorage
            ),

            noteToSample: (note, buffers, config) => {
                const midi = toMidi(note.note);
                if (!midi) return [note.note, 0];

                const vel = note.velocity ?? config.velocity;
                const layerIdx = LAYERS.findIndex(
                    (layer) => vel >= layer.vel_range[0] && vel <= layer.vel_range[1]
                );
                const layer = LAYERS[layerIdx];
                if (!layer) return ["", 0];

                return findNearestMidiInLayer(layer.name, midi, buffers);
            },
        });
    }
}

function findNearestMidiInLayer(
    prefix: string,
    midi: number,
    buffers: AudioBuffers
): [string, number] {
    let i = 0;
    while (buffers[prefix + (midi + i)] === undefined && i < 128) {
        if (i > 0) i = -i;
        else i = -i + 1;
    }

    return i === 127 ? [prefix + midi, 0] : [prefix + (midi + i), -i * 100];
}

function testvoiceLoader(
    baseUrl: string,
    storage: Storage
): SamplerAudioLoader {
    const format = findFirstSupportedFormat(["mp3", "ogg", "m4a"]) ?? "mp3";
    return async (context: AudioContext, buffers: AudioBuffers) => {
        for (const layer of LAYERS) {
            await Promise.all(
                layer.samples.map(async ([midi, name]) => {
                    const url = `${baseUrl}/${name}.${format}`;
                    const buffer = await loadAudioBuffer(context, url, storage);
                    if (buffer) buffers[layer.name + midi] = buffer;
                })
            );
        }
    };
}

export const LAYERS = [
    {
        name: "Layer1",
        vel_range: [1, 127],
        cutoff: 1000,
        samples: [
            [43, "43"],
            [44, "44"],
            [45, "45"],
            [46, "46"],
            [47, "47"],
            [48, "48"],
            [49, "49"],
            [50, "50"],
            [51, "51"],
            [52, "52"],
            [53, "53"],
            [54, "54"],
            [55, "55"],
            [56, "56"],
            [57, "57"],
            [58, "58"],
            [59, "59"],
            [60, "60"],
            [61, "61"],
            [62, "62"],
            [63, "63"],
            [64, "64"],
            [65, "65"],
            [66, "66"],
            [67, "67"],
            [68, "68"],
            [69, "69"],
            [70, "70"],
            [71, "71"],
            [72, "72"],
            [73, "73"],
            [74, "74"],
            [75, "75"],
            [76, "76"],
        ]
    }


]
