import { ActionCreator, useStoreActions, useStoreState } from "easy-peasy";
import { Item, filterHasChildren, filterPublished, filterTypeInfo, filterTypeItem } from "../data/Data";
import { AppModel } from "../store/appModel";
import { ContentModel } from "../store/content";
import { UserDataModel } from "../store/userdata";
import { LocationModel } from "../store/location";
import { BOOK_TAG, MATERIAL_TAG, linkTo } from "../utils/utils";
import { SideView } from "./SideView";
import { motion } from "framer-motion";
import "./ShelfView.css";
import { KDistShelvesView } from "./content-kdist/KDistShelvesView";


export function MaterialView() {
    const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    const content: ContentModel = useStoreState<AppModel>((state) => state.content);
    const currentRoomItems = content.currentRoomItems as unknown as Item[];

    // if (content.currentRoom.id === "kdist") return <KDistShelvesView />;
    // const currentcontent: CurrentContentModel = useStoreState<AppModel>((state) => state.currentContent);
    // const shelves = (content.currentRoom.id === "kdist") ? <KDistShelvesView /> :
    //     filterPublished(filterHasChildren(filterTypeItem(currentRoomItems))).map((item, index) => <ShelfView item={item} key={item.id} />);


    const shelves =
        filterPublished(filterHasChildren(filterTypeItem(currentRoomItems))).map((item, index) => <ShelfView item={item} key={item.id} />);


    return <>
        <SideView>
            <h3>Kursmaterial</h3>

            <p>{JSON.stringify(content.currentRoom.id)}</p>

        </SideView>
        <motion.div className="Flex FlexColumn AlignStretch JustifyStretch Pad05 OFAuto Flex1"
            initial={{ transform: "translateX(-100%)" }}
            animate={{ transform: "translateX(0)" }}
            transition={{ duration: .2 }}
        >
            {shelves}
        </motion.div>
    </>
}



export function ShelvesView() {

    const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    const content: ContentModel = useStoreState<AppModel>((state) => state.content);
    const currentRoomItems = content.currentRoomItems as unknown as Item[];
    const shelves = filterPublished(filterHasChildren(filterTypeItem(currentRoomItems))).map((item, index) => <ShelfView item={item} key={item.id} />);


    return <>
        <SideView>
            <h3>Kursmaterial</h3>

            <p>{JSON.stringify(content.currentRoom)}</p>

        </SideView>
        <motion.div className="Flex FlexColumn AlignStretch JustifyStretch Pad05 OFAuto Flex1"
            initial={{ transform: "translateX(-100%)" }}
            animate={{ transform: "translateX(0)" }}
            transition={{ duration: .2 }}
        >
            {shelves}
        </motion.div>
    </>

}



export function ShelfView(props: { item: Item }) {
    const books = filterPublished(props.item.children as unknown as Item[]);
    return <div className="bokhyllor">
        <h3>{props.item.title}</h3>
        <ul>
            {books && books.map((item, index) => <Bookfront item={item} key={item.id} css={{ backgroundImage: `url("assets/${props.item.id}.webp")`, backgroundSize: 'cover' }} />)}
        </ul>
    </div>
}

export function Bookfront(props: { item: Item, css?: any }) {
    const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    const setPath: ActionCreator<string> = useStoreActions<AppModel>((actions) => actions.location.setPath);

    return <li className={props.item.id} onClick={e => {
        console.log(props.item.path, props.item.id);
        linkTo(BOOK_TAG + props.item.path);
    }} style={props.css}>
        <h4 className="TextLightx">{props.item.title}</h4>
    </li>
}

// export function ShelvesView() {
//     const content: ContentModel = useStoreState<AppModel>((state) => state.content);
//     const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
//     const roomShelves = content.currentRoomItems as unknown as Item[];
//     const shelves = filterTypeInfo(roomShelves).map((item, index) => <ShelfView item={item} key={item.id} />);
//     console.log(shelves.length);
//     return <>
//         <SideView />
//         <div className="Flex FlexColumn AlignStretch JustifyStretch Pad05 OFAuto BDRed BD3 Flex1">
//             {shelves}
//         </div>
//     </>
// }


