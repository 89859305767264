import { Action, Thunk, action, thunk } from "easy-peasy";
import { GlossaryItem } from "../data/Data";
import { rdbGetGlossaryItemsArray, rdbRemoveGlossarItem, rdbSaveGlossarItem } from "./firebaseStore";

export type GlossaryModel = {
    items: GlossaryItem[],
    setItems: Action<GlossaryModel, GlossaryItem[]>,
    loadItems: Thunk<GlossaryModel, void>,
    addItem: Thunk<GlossaryModel, GlossaryItem>,
    removeItem: Thunk<GlossaryModel, string>,
}

export const createGlossaryModel = (): GlossaryModel => {
    return {
        items: [],

        setItems: action((state, payload) => {
            const sortedItems = payload.sort((a: GlossaryItem, b: GlossaryItem) => { return a.type.localeCompare(b.type) });

            state.items = sortedItems;
            // state.items = payload;
        }),

        loadItems: thunk(async (actions, payload, helpers) => {
            // console.log('LOAD ITEMS');
            const items: GlossaryItem[] = await rdbGetGlossaryItemsArray();
            // console.log(items);
            actions.setItems(items);
        }),

        addItem: thunk(async (actions, payload, helpers) => {
            // console.log('ADD ITEM' + JSON.stringify(payload));
            await rdbSaveGlossarItem(payload);
            actions.loadItems();
        }),

        removeItem: thunk(async (actions, payload, helpers) => {
            // console.log('REMOVE ITEM' + payload);
            await rdbRemoveGlossarItem(payload);
            actions.loadItems();
        }),
    }
}




