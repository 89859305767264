import { Action, ActionOn, FilterActionTypes, StateMapper, Thunk, action, actionOn, thunk } from "easy-peasy";
import { generateId } from "../utils/utils";
import { AppModel } from "./appModel";
import { UserStatus } from "./user";

export type Testdata = {
    name: string,
    id: string,
}

export type TestdataStatus = { type: "empty" } | { type: "loading" } | { type: "data", items: Testdata[] } | { type: "error", error: string };

export type TestdataModel = {
    status: TestdataStatus,
    loadData: Thunk<TestdataModel, boolean>,
    setData: Action<TestdataModel, TestdataStatus>
    // onUserChange: ActionOn<TestdataModel, AppModel>
}

export const createTesdata = (): TestdataModel => {
    return {
        status: { type: "empty" },
        loadData: thunk(async (state, payload) => {
            state.setData({ type: "loading" });
            await new Promise((resolve) => setTimeout(resolve, 2000));
            state.setData({ type: "data", items: [{ name: "dataitem 1", id: generateId() }] });
        }),
        setData: action((state, payload) => {
            state.status = payload;
        }),

        // onUserChange: actionOn( (actions, storeActions) =>[
        //     storeActions.userdata.setStatus,
        // ], (state:StateMapper<FilterActionTypes<TestdataModel>>, target:any) =>{
        //     console.log("onUserChange", target.payload);
        // }),


    }
}