import { useState } from "react";
import { UserAccess } from "../../../store/userdata";
import { UserAccessView } from "./AccessView";
import { Access, strToAccess } from "../../../data/Data";

export function AdminUserAccesses(props: { userAccesses: UserAccess[], onChange: (uas: UserAccess[]) => void }) {
    const [room, setRoom] = useState<string>('kak');
    const [access, setAccess] = useState<number>(1);
    const [uas, setUas] = useState<UserAccess[]>(props.userAccesses);

    return <>
        <div className="Flex AlignCenter">
            <p>Behörigheter</p>
            {uas.map((ua, index) => <UserAccessView key={index} useraccess={ua} />)}
        </div>
        <div className="Flex">
            <button className="Small" onClick={e => {
                const userAccess: UserAccess = { id: room, access: access as Access };

                const already = uas.find(ua => ua.id === userAccess.id && ua.access === userAccess.access);
                console.log('already', already);
                if (!already) {
                    setUas([...uas, userAccess]);
                    props.onChange([...uas, userAccess]);
                } else {
                    alert('Behörighet redan tillagd');
                }
            }}>Lägg till behörighet</button>

            <select value={room} onChange={e => setRoom(e.target.value)} >
                <option value="kak">Körakademin</option>
                <option value="kdist">Kantorsdistans</option>
                <option value="roomt">Room1</option>
            </select>

            <select value={access} onChange={e => setAccess(strToAccess(e.target.value))}>
                <option value={"1"}  >Studerande</option>
                <option value={"2"}  >Lärare</option>
                <option value={"3"}  >Admin</option>
            </select>
        </div>
    </>

}