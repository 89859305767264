export function shake(elem: HTMLElement, iterations: number = 1, duration: number = 900) {
    var keyframes = [
        { transform: 'translate3d(0, 0, 0)', offset: 0 }, { transform: 'translate3d(-10px, 0, 0)', offset: 0.1 },
        { transform: 'translate3d(10px, 0, 0)', offset: 0.2 }, { transform: 'translate3d(-10px, 0, 0)', offset: 0.3 },
        { transform: 'translate3d(10px, 0, 0)', offset: 0.4 }, { transform: 'translate3d(-10px, 0, 0)', offset: 0.5 },
        { transform: 'translate3d(10px, 0, 0)', offset: 0.6 }, { transform: 'translate3d(-10px, 0, 0)', offset: 0.7 },
        { transform: 'translate3d(10px, 0, 0)', offset: 0.8 }, { transform: 'translate3d(-10px, 0, 0)', offset: 0.9 },
        { transform: 'translate3d(0, 0, 0)', offset: 1 },
    ];
    var timing: KeyframeAnimationOptions = { duration: duration, iterations: iterations };
    elem.animate(keyframes, timing);
    return elem;
}

export function nod(elem: HTMLElement, iterations: number = 1, duration: number = 900) {
    var keyframes = [
        { transform: 'translate3d(0, 10px, 0)', offset: 0 }, { transform: 'translate3d(0, 0, 0)', offset: 0.1 },
        { transform: 'translate3d(0, 10px, 0)', offset: 0.2 }, { transform: 'translate3d(0, -10px, 0)', offset: 0.3 },
        { transform: 'translate3d(0, 10px, 0)', offset: 0.4 }, { transform: 'translate3d(0, -10px, 0)', offset: 0.5 },
        { transform: 'translate3d(0, 10px, 0)', offset: 0.6 }, { transform: 'translate3d(0, -10px, 0)', offset: 0.7 },
        { transform: 'translate3d(0, 10px, 0)', offset: 0.8 }, { transform: 'translate3d(0, -10px, 0)', offset: 0.9 },
        { transform: 'translate3d(0, 0, 0)', offset: 1 },
    ];
    var timing: KeyframeAnimationOptions = { duration: duration, iterations: iterations };
    elem.animate(keyframes, timing);
    return elem;
}


export function rotateIn(elem: HTMLElement, iterations: number = 1, duration: number = 700) {
    if (elem == null)
        return null;
    var transformOrigin = elem.style.transformOrigin;

    var keyframes = [
        {
            transform: 'rotate3d(0, 0, 1, -200deg)',
            opacity: '0',
            transformOrigin: 'center',
            offset: 0.0
        },
        {
            transform: 'none',
            opacity: '1',
            transformOrigin: 'center',
            offset: 1.0
        }
    ];
    var timing: KeyframeAnimationOptions = { duration: duration, iterations: iterations, easing: 'cubic-bezier(0.785, 0.135, 0.150, 0.860)' };
    return elem.animate(keyframes, timing);
}

export function rotate(elem: HTMLElement) {
    if (elem == null)
        return null;
    var transformOrigin = elem.style.transformOrigin;

    var keyframes = [
        {
            transform: 'rotate(0deg)',
        },
        {
            transform: 'rotate(360deg)',
        }
    ];
    var timing: KeyframeAnimationOptions = { duration: 1000, iterations: 5 };
    return elem.animate(keyframes, timing);
}
