import React, { useEffect, useState } from "react";
import { CURRENT_CONFIRMATION_TABLE, ConfirmedUser, ConfirmedUserExt, UserDbData, getUsersData, getUsersDataArray, rdbGetActions, rdbGetConfirms, rdbGetConfirmsArray, rdbGetConfirmsExt } from "../../../store/firebaseStore";

import { getSwedishDate, pathToEmail } from "../../../utils/utils";
import { XLSX$Utils } from "xlsx";
import { writeXLSX, utils, writeFile } from "xlsx";
import { ImportUser, NewFirebaseUserExt } from "./ImportUsersView";

export function AdminConfirmed() {
    const [confirms, setConfirms] = useState<ConfirmedUserExt[]>([]);

    const [allUsers, setAllUsers] = useState<{ email: string, firstName: string, lastName: string, kakSource: string, kakVerifyDate: string, uid: string }[]>([]);

    let uidEmailMap = new Map();
    useEffect(() => {
        const doit = async () => {
            const users = await getUsersDataArray();
            setAllUsers(users);
            const confirmedUsers = await rdbGetConfirmsExt();
            setConfirms(confirmedUsers);
        }
        doit();
    }, []);

    const confirmsKakVerify: ConfirmedUserExt[] = confirms.filter((item) => item.kakVerifyDate != null);
    const confirmsNOTkakVerifyDate: ConfirmedUserExt[] = confirms.filter((item) => item.kakVerifyDate == null);

    return <><h3>Bekräftade deltagare</h3>
        <p>Nuvarande tabell för bekräftande: <strong>{CURRENT_CONFIRMATION_TABLE}</strong></p>
        <p>Totala antalet bekräftade: {confirms.length}</p>
        <p>Totala antalet bekräftade MED kakVerifyDate: {confirmsKakVerify.length}</p>
        <div className="Flex">
            <button onClick={async () => {
                const users = await getUsersDataArray();
                console.log('users', users);


            }}>Export users</button>
            <button onClick={async () => {
                const workbook = utils.book_new();
                const allUsers: UserDbData[] = await getUsersDataArray();

                utils.book_append_sheet(workbook, utils.json_to_sheet(allUsers), 'All users');
                utils.book_append_sheet(workbook, utils.json_to_sheet(confirmsKakVerify), 'Confirmed users');
                utils.book_append_sheet(workbook, utils.json_to_sheet(confirmsNOTkakVerifyDate), 'Confirmed without KakVerifyDate');



                const currentTime = new Date().toLocaleString();
                writeFile(workbook, `KAK Confirmed Users ${currentTime}.xlsx`);

            }}>Exportera som Excel-fil</button>
        </div>
        <p>Rödmarkerade saknar KakSource-fält</p>


        {confirmsKakVerify.map((item, index) => {
            const color = item.kakSource != null ? '#efefef' : 'tomato';

            return <p style={{ padding: '.1rem .5rem', background: color }} key={index}>{item.fullname}, {item.email}</p>
            // return <p style={{ padding: '.1rem .5rem', background: color }} key={index}>{item.fullname}, {item.email}, {item.kakSource}, <strong>{item.kakVerifyDate}</strong>, {item.username}</p>
        })}
        <h4>Totala antalet bekräftade UTAN kakVerifyDate: {confirmsNOTkakVerifyDate.length}</h4>
        {confirmsNOTkakVerifyDate.map((item, index) => {
            return <p style={{ padding: '.1rem .5rem', background: '#ccc' }} key={index}>{item.fullname}, {item.email},  {item.kakSource}, {item.username}</p>
        })}
    </>
}

export function AdminConfirmedExport() {
    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);
    const [newUsers, setNewUsers] = useState<NewFirebaseUserExt[]>([]);
    const [progress, setProgress] = useState<{ idx: number, count: number }>({ idx: 0, count: 0 });

    const importUsers = async () => {
        const text = textareaRef.current?.value;
        if (!text) return;
        const lines: string[] = text.split('\n');
        const syncUsers: NewFirebaseUserExt[] = [];
        lines.map(line => {
            const fields: string[] = line.split('\t');
            const user: ImportUser = {
                uid: fields[0],
                ssnr: fields[1],
                lastname: fields[2],
                firstname: fields[3],
                email: fields[4],
                source: fields[5],
            }
            const date = new Date();
            const dateString = date.toLocaleDateString('sv-SE', { month: '2-digit', day: '2-digit', year: 'numeric' });
            const syncUser: NewFirebaseUserExt = {
                uid: user.uid,
                email: user.email,
                password: 'Stenhammar',
                kakVerifyDate: dateString,
                userdata: {
                    firstName: user.firstname,
                    lastName: user.lastname,
                    claims: {
                        accesses: [{ access: 1, id: 'kak' }],
                        current: { access: 1, id: 'kak' }
                    }
                },
                kakSource: user.source,
                existsAuth: false,
                existsDb: false,
                // dbKakSource: 'XXXXXX',
            }

            if (syncUser.uid != '')
                syncUsers.push(syncUser);
            setNewUsers(syncUsers);
        });
        //-----------------------------------------------------
        const confirmedUsers = await rdbGetConfirmsExt();
        console.log(confirmedUsers);

        const confirmedEmails = confirmedUsers.map((item) => item.email);
        const confirmedSyncUsers = syncUsers.filter((item) => confirmedEmails.includes(item.email));
        const unconfirmedSyncUsers = syncUsers.filter((item) => !confirmedEmails.includes(item.email));

        console.log(confirmedEmails.length);
        console.log(syncUsers.length);
        console.log(unconfirmedSyncUsers.length);


        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, utils.json_to_sheet(syncUsers), 'Sync users');
        utils.book_append_sheet(workbook, utils.json_to_sheet(confirmedUsers), 'Confirmed table');
        utils.book_append_sheet(workbook, utils.json_to_sheet(confirmedSyncUsers), 'Confirmed Sync users');
        utils.book_append_sheet(workbook, utils.json_to_sheet(unconfirmedSyncUsers), 'Unconfirmed Sync users');
        const currentTime = new Date().toLocaleString();
        writeFile(workbook, `SYNC users confirmation status - ${currentTime}.xlsx`);

    }


    return <><h3>Bekräftade användare</h3>
        <p>current date: <strong>{getSwedishDate()}</strong></p>
        <p>current table: <strong>{CURRENT_CONFIRMATION_TABLE}</strong></p>
        <p>Klistra in all data från SYNK-fliken i nedanstående textfält, och klicka därefter på Exportera rapport-knappen.</p>
        <textarea ref={textareaRef} ></textarea>
        <div className="Flex JustifyEnd Pad05">
            <button onClick={e => { importUsers(); }}>Exportera rapport</button >
        </div>
        {newUsers.map((user, index) => <UserItem key={index} user={user} />)}
    </>

}

export function UserItem(props: { user: NewFirebaseUserExt }) {
    return <div className="Flex">
        {props.user.email}
    </div>
}