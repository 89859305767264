import { useEffect, useState } from "react";
import { CURRENT_CONFIRMATION_TABLE, ConfirmedUser, ConfirmedUserExt, UserDbData, getUsersData, getUsersDataArray, rdbGetActions, rdbGetConfirms, rdbGetConfirmsArray, rdbGetConfirmsExt } from "../../../store/firebaseStore";

import { pathToEmail } from "../../../utils/utils";



export function AdminLogin2() {
    // const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    // const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    // const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    // const loadMethod: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.content.loadContent);

    const [loginErrors, setLoginErrors] = useState<string[]>([]);
    const [logins, setLogins] = useState<string[]>([]);
    const [success, setSuccess] = useState<{ username: string, success: boolean }[]>([]);
    const [confirms, setConfirms] = useState<{ username: string, confirmed: boolean }[]>([]);
    const [confirmedActive, setConfirmedActive] = useState<{ uid: string, fullname: string, email: string, confirmed: boolean }[]>([]);

    let map = new Map();
    let uidEmailMap = new Map();
    useEffect(() => {
        const doit = async () => {
            const actions: any = await rdbGetActions();
            // console.log(actions);
            const loginErrors: string[] = Object.values(actions['login-error']).filter((item: any) => item.action != null).map((item: any) => item.action); //.map((item: any) => item.action);
            const uniqueErrors = [...new Set(loginErrors)];
            setLoginErrors(uniqueErrors);
            // console.log(uniqueErrors);
            delete actions['login-error'];

            uniqueErrors.forEach((item: string) => {
                map.set(pathToEmail(item).toLowerCase(), false);
            });
            //-----------------------------------------
            const logins: string[] = [];
            Object.entries(actions).forEach(([key, value], idx) => {
                try {

                    const v: any = value;
                    const item: any = Object.entries(v)[0][1];
                    const username = String(item['data']['username']);
                    // console.log();
                    logins.push(username);
                    map.set(username.toLowerCase(), true);
                    uidEmailMap.set(key, username.toLowerCase());
                } catch (e) {
                    console.log(e);
                }
            });
            setLogins(logins);

            const entries = map.entries();
            // console.log(map.keys());

            const keys = [...map.keys()];
            // console.log(keys);
            keys.sort();


            const successes: { username: string, success: boolean }[] = [];
            for (const key of keys) {
                const entry = map.get(key);
                successes.push({ username: key, success: entry });
            }
            setSuccess(successes);

            //-----------------------------------------------------
            const confirms: { key: string, username: string, fullname: string, confirmed: boolean }[] = [];
            const confirmedUids: string[] = [];
            const confirmsDb = await rdbGetConfirms();
            console.log(confirmsDb);
            Object.entries(confirmsDb).forEach(([key, value], idx) => {
                try {
                    const v: any = value;
                    const email = uidEmailMap.get(key);
                    console.log(v);
                    // const item: any = Object.entries(v)[0][1];
                    // const username = String(item['data']['username']);
                    // // console.log();
                    // logins.push(username);
                    // map.set(username.toLowerCase(), true);
                    confirms.push({ key: key, username: email, fullname: v.fullname, confirmed: true });
                    confirmedUids.push(key);
                } catch (e) {
                    console.log(e);
                }
                // console.log(v.action);
                // console.log(Object.entries(v)[1]);
            });
            console.log('confirms', confirms);
            setConfirms(confirms);
            //-----------------------------------------------------
            const allUsers = await getUsersData();
            const activeKakUsers: any[] = Object.entries(allUsers).filter(([key, value]) => {
                const v: any = value;
                const kakSource = v.kakSource;
                return kakSource > 'kak 22';
            });
            console.log('allUsers', allUsers.length);
            console.log('active', activeKakUsers.length);
            const confirmedActiveUsers: { uid: string, email: string, fullname: string, confirmed: boolean }[] = [];
            for (const activeKakUser of activeKakUsers) {
                console.log(activeKakUser);
                const uid = activeKakUser[0];
                const confirmedActiveUser = { uid, fullname: activeKakUser[1].firstName + ' ' + activeKakUser[1].lastName, email: activeKakUser[1].email, confirmed: confirmedUids.includes(uid) };
                confirmedActiveUsers.push(confirmedActiveUser);
                console.log(confirmedActiveUser);
            }
            confirmedActive.sort((a, b) => (a.confirmed ? -1 : 1) - (b.confirmed ? -1 : 1));
            setConfirmedActive(confirmedActiveUsers);
        };
        doit();
    }, []);
    return <>
        <h3>Inloggningar översikt</h3>
        <button onClick={e => { }}>AdminLogin</button>
        {<p>Antal inloggade: {success.filter((item, index) => item.success).length}</p>}
        {<p>Antal bekräftade: {confirms.filter((item, index) => item.confirmed).length}</p>}
        {<p>Antal aktiva Kak-deltagare: {confirmedActive.length}</p>}
        {<p>Antal bekräftade aktiva Kak-deltagare: {confirmedActive.filter(i => i.confirmed).length}</p>}
        {<p>Antal obekräftade aktiva Kak-deltagare: {confirmedActive.filter(i => !i.confirmed).length}</p>}
        <button onClick={e => {
            let s: string = '';
            confirmedActive.filter(i => !i.confirmed).forEach((item, index) => {
                s += item.fullname + ',' + item.email + ',' + item.uid + '\n';
            });
            console.log(s);


        }}>Exportera obekräftade</button>

        {confirmedActive.filter(i => i.confirmed).map((item, index) => <p key={index} style={{ background: item.confirmed ? 'lime' : 'tomato' }}>{item.fullname} {item.email} {item.uid} {item.confirmed ? 'true' : 'false'}</p>)}
        {confirmedActive.filter(i => !i.confirmed).map((item, index) => <p key={index} style={{ background: item.confirmed ? 'lime' : 'tomato' }}>{item.fullname} {item.email} {item.uid} {item.confirmed ? 'true' : 'false'}</p>)}


    </>
}

// {success.map((item, index) => <ItemView key={index} username={item.username} success={item.success} />)}

export function ItemView(props: { username: string, success: boolean }) {
    return <p style={{ background: props.success ? 'lime' : 'tomato' }}>{props.username}</p>
}



