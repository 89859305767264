import React, { useEffect, useState } from 'react';

import './Style.css';
import './App.css';
import { UserView } from './components/UserView';
import { CurrentContentMini2, LocationView } from './components/LocationView';
import { UserDataView } from './components/UserDataView';
import { LogoView } from './components/LogoView';
import { ActionCreator, useStoreActions, useStoreState } from 'easy-peasy';
import { AppModel } from './store/appModel';
import { MainView } from './components/MainView';
import { PitchTest } from './components/PitchTest';
import { ContentUserModel } from './store/contentuser';
import { TestFirebaseFunctionsView } from './components/TestFirebaseFunctionsView';
import { TestPianoView } from './components/TestPianoView';
import { NotationView } from './components/music/NotationView';
import { TestMarkdownView } from './components/TestMarkdownView';
import { AliasUserView } from './components/admin/users/AliasUserView';
import { MusicTestView } from './components/test/MusicTestView';
import { FrontFunctionsModel } from './store/frontfunctionsstore';
import { TestStorage } from './components/test/TestStorage';
import { FrequencyView } from './components/test/FrequencyView';
import { UploadScorxFiles } from './components/test/UploadScorxFiles';
import { ScorxPlayerView } from './components/music/player/ScorxPlayerView';
import { MusicModel } from './store/musicstore';
import { SvgButtonKeyboard } from './components/buttons/SvgButtonKeyboard';
import { SvgButtonMic } from './components/buttons/SvgButtonMic';
import { SvgButtonMusic } from './components/buttons/SvgButtonMusic';
import { SvgButtonClose } from './components/buttons/SvgButtonClose';
import { LocationModel, LocationStatus } from './store/location';

function App() {
	const loadContent: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
	const setupLocation: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.location.setupLocation);
	const frontFunctions: FrontFunctionsModel = useStoreState<AppModel>((state) => state.frontFunctions);

	useEffect(() => {
		setupLocation();
		loadContent();
	}, []);

	return <>
		<header className="Flex JustifyBetween AlignStretch Z1">
			<div className="Flex AlignCenter">
				<LogoView />
				<LocationView />
			</div>
			<UserDataView /><UserView />
		</header>
		<AliasUserView />
		<div className="Flex Flex1 OFHidden Gap0">
			<MainView />
		</div>
		{/* <TestFirebaseFunctionsView /> */}
		{/* <TestMarkdownView text="abc" />
		<NotationView timeout={100} text="|clef G |sp3 | 0 1 2 | bl" />
		<NotationView2 text="|clef G |sp3 | nv8 4 3 b2 1 n2 #3 | bl" /> */}
		{/* <CurrentContentMini2 /> */}
		{/* <ContentUserView /> */}
		{/* <FrequencyView /> */}
		{/* <TestStorage /> */}

		{frontFunctions.showScorx && <>
			<ScorxPlayerView />
		</>}
		{frontFunctions.showPiano && <TestPianoView />}
		<footer>
			<FooterFunctionsView />
		</footer>
	</>
}

export default App;

function ContentUserView() {
	const { contentAccess, contentUserData }: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
	return <>
		<p>{JSON.stringify(contentAccess)}</p>
		<p>{JSON.stringify(contentUserData)}</p>
	</>
}

function FooterFunctionsView() {
	const frontFunctions: FrontFunctionsModel = useStoreState<AppModel>((state) => state.frontFunctions);
	const setShowPiano: ActionCreator<boolean> = useStoreActions<AppModel>((actions) => actions.frontFunctions.setShowPiano);
	const setShowPitch: ActionCreator<boolean> = useStoreActions<AppModel>((actions) => actions.frontFunctions.setShowPitch);
	const setShowScorx: ActionCreator<boolean> = useStoreActions<AppModel>((actions) => actions.frontFunctions.setShowScorx);

	const music: MusicModel = useStoreState<AppModel>((state) => state.music);


	return <div className="Flex AlignCenter JustifyBetween">

		<SvgButtonKeyboard title="Visa klavitaur" active={frontFunctions.showPiano} onClick={() => setShowPiano(!frontFunctions.showPiano)} />
		<SvgButtonMic title="Aktivera tonhöjdslyssning" active={frontFunctions.showPitch} onClick={() => setShowPitch(!frontFunctions.showPitch)} />
		{/* <SvgButtonMusic active={frontFunctions.showScorx} onClick={() => setShowScorx(!frontFunctions.showScorx)} /> */}
		{frontFunctions.showPitch && <PitchTest />}



	</div>
}