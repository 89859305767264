import { AppModel } from '../store/appModel';
import { Action, ActionCreator, Actions, action, useStoreActions, useStoreState } from 'easy-peasy';
import { LocationModel, LocationStatus } from "../store/location";
import { linkTo } from '../utils/utils';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import { useState } from 'react';
import "./LocationView.css";
import { useMediaQuery } from '../hooks/useMediaQuery';
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { UserDataModel } from '../store/userdata';

export function LocationView() {
    const { book, status, statusIdx }: LocationModel = useStoreState<AppModel>((state) => state.location);
    const { currentAccess }: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    const content: Actions<AppModel> = useStoreActions<AppModel>((actions) => actions.content);
    const setStatus: ActionCreator<LocationStatus> = useStoreActions<AppModel>((actions) => actions.location.setStatus);
    const [tabIdx, setTabIdx] = useState(0);
    const smallScreen = useMediaQuery('(min-width: 700px)')



    if (smallScreen) {
        const displayBook = book ? {} : { display: 'none' };

        return <div className="BGWhite" >
            <Tabs index={Number(statusIdx)} onChange={(idx: number) => {
                switch (idx) {
                    case 0: linkTo(""); break;
                    case 1: linkTo("classroom"); break;
                    case 2: {
                        const hash = window.location.hash.substring(1);
                        const segments = hash.split('/');
                        segments[0] = "material";
                        linkTo(segments.join('/'));
                    } break;
                    case 3: {

                        const hash = window.location.hash.substring(1);
                        const segments = hash.split('/');
                        segments[0] = "book";
                        linkTo(segments.join('/'));
                    } break;
                    // case 4: linkTo("repertoir"); break;
                    // case 5: linkTo("desktop"); break;
                    case 4: {
                        linkTo("search"); break;
                    }
                    case 5: linkTo("admin"); break;
                }
                setTabIdx(idx);
            }}>
                <TabList >
                    <Tab >Hem</Tab>
                    <Tab>Klassrummet</Tab>
                    <Tab>Kursmaterial</Tab>
                    <Tab style={displayBook}>Bok</Tab>
                    <Tab>Ordlista</Tab>
                    {/* <Tab>Repertoar</Tab> */}
                    {/* <Tab>Skrivbordet</Tab> */}
                    {currentAccess.access == 3 && <Tab>Admin</Tab>}
                </TabList>
            </Tabs>
            {/* <p>{JSON.stringify(status)}</p> */}

        </div>
    }
    return <>
        <Menu menuButton={<MenuButton>☰</MenuButton>} >
            <MenuItem onClick={e => { linkTo("") }}>Hem</MenuItem>
            <MenuItem onClick={e => { linkTo("classrom") }}>Klassrummet</MenuItem>
            <MenuItem onClick={e => { linkTo("material") }}>Kursmaterial</MenuItem>
            <MenuItem onClick={e => { linkTo("book") }}>Bok</MenuItem>
            <MenuItem onClick={e => { linkTo("search") }}>Sök</MenuItem>
            {/* <MenuItem onClick={e => { linkTo("repertoir") }}>Repertoar</MenuItem> */}
            {/* <MenuItem onClick={e => { linkTo("desktop") }}>Skrivbordet</MenuItem> */}

            {currentAccess.access == 3 && <MenuItem onClick={e => { linkTo("admin") }}>Adnub</MenuItem>}
        </Menu>


    </>

}


export function CurrentContentMini2() {
    const location: LocationModel = useStoreState<AppModel>((state) => state.location);

    return <div className="Flex AlignCenter Pad05 BGLightGray ">
        <p>Current content location:</p>
        {location.shelf && <p>Shelf: {location.shelf.title}</p>}
        {location.book && <p>Book: {location.book.title}</p>}
        {location.chapter && <p>Chapter: {location.chapter.title}</p>}
        {location.subchapter && <p>Subchapter: {location.subchapter.title}</p>}
    </div>
}