import { action, Action, Helpers, thunk, Thunk } from "easy-peasy";
import { filterHasChildren, filterPublished, filterTypeItem, Item, usersByAccess } from "../data/Data";
import { AppModel } from "./appModel";
import { User } from "firebase/auth";
import { DatabaseReference } from "firebase/database";
import { rdbKdistLoadAllConnectionsItemsArray, rdbKdistLoadConnectedItems, rdbKdistLoadConnectedItemsArray, rgbSaveKdistUserItemConnection, rgbSaveKdistUserItemOmdome } from "./firebaseStore";
import { UsersStatus } from "./users";
import { UserData } from "./userdata";

export type KdistContentStatus =
    | { type: 'empty' }
    | { type: 'loading' }
    | {
        type: 'items',
        items: Item[],
        shelves: Item[],
        // itemsAndConnections: KDistItemAndConnection[],
        connectionsItems: KdistUserConnection[],
        kdistUsers: UserData[],
    }

export type KdistContentModel = {
    status: KdistContentStatus;
    setStatus: Action<KdistContentModel, KdistContentStatus>;
    // loadItems: Thunk<KdistContentModel, boolean>;
    // loadConnectedItems: Thunk<KdistContentModel, string>;
    loadAllConnectionsItems: Thunk<KdistContentModel, void>;
    // saveKdistUserStatusForItem: Thunk<KdistContentModel, SaveKdistUserItem>;
    saveKdistUserItemConnection: Thunk<KdistContentModel, { uid: string, itemId: string, active: KDistActive }>;
    // saveKdistUserItemOmdome: Thunk<{ uid: string, itemId: string, omdome: KDistOmdome }, KdistUserConnection>;
}

export type KDistOmdome = 'godkänd' | 'väl-godkänd';
export type KDistActive = 'inactive' | 'active' | 'self-estimated' | 'godkand' | 'val-godkand';


export type SaveKdistUserItem = {}; //{ item: Item, itemStatus: number };
export type KdistUserConnection = { uid: string, itemId: string, active: KDistActive, omdome: KDistOmdome }; //{ item: Item, itemStatus: number };
export type KDistItemAndConnection = { item: Item, connection: KdistUserConnection | null };

export const createKdistContentModel = (): KdistContentModel => {
    return {
        status: { type: 'empty' },
        setStatus: action((state, payload) => {
            state.status = payload;
        }),

        // saveKdistUserStatusForItem: thunk(async (actions, payload, helpers: Helpers<KdistContentModel, AppModel, SaveKdistUserItem>) => {
        //     if (helpers.getStoreState().user.status.type === 'user') {
        //         const user = helpers.getStoreState().user.status.user;
        //         console.log('user', user);
        //         console.log('Saving kdist user status for item   ');
        //     } else {
        //         console.log('Wrong user status  for saving kdist user status for item   ');
        //     }
        // }),

        saveKdistUserItemConnection: thunk(async (actions, payload: { uid: string, itemId: string, active: KDistActive }, helpers: Helpers<KdistContentModel, AppModel, KdistUserConnection>) => {
            if (helpers.getStoreState().user.status.type === 'user') {
                console.log('saving connection...', payload.uid, payload.itemId);
                await rgbSaveKdistUserItemConnection(payload.uid, payload.itemId, payload.active);
                actions.loadAllConnectionsItems();
            } else {
                console.log('Wrong user status  for saving kdist user connection for item   ');
            }
        }),

        // saveKdistUserItemOmdome: thunk(async (actions, payload: { uid: string, itemId: string, omdome: KDistOmdome }, helpers: Helpers<KdistContentModel, AppModel, KdistUserConnection>) => {
        //     if (helpers.getStoreState().user.status.type === 'user') {
        //         console.log('saving connection...', payload.uid, payload.itemId);
        //         await rgbSaveKdistUserItemOmdome(payload.uid, payload.itemId, payload.omdome);
        //         actions.loadAllConnectionsItems();
        //     } else {
        //         console.log('Wrong user status  for saving kdist user connection for item   ');
        //     }
        // }),

        loadAllConnectionsItems: thunk(async (actions, payload, helpers: Helpers<KdistContentModel, AppModel, void>) => {
            await helpers.getStoreActions().users.load();
            const usersStatus: UsersStatus = await helpers.getStoreState().users.status;
            let kdistUsers: UserData[] = [];
            // ladda kdist-users
            if (usersStatus.type === 'users') {
                const keys = Object.keys(usersStatus.usersdata);
                kdistUsers = Object.entries(usersStatus.usersdata).map((data, idx) => {
                    return data[1] as UserData;
                });
                kdistUsers = usersByAccess(kdistUsers, { access: 1, id: 'kdist' });
                console.log('kdistUsers', kdistUsers);
            } else {
                console.log('no kdist users found', usersStatus.type);
            }

            // ladda kdist-books
            const items = await helpers.getStoreState().content.currentRoomItems;
            const shelves = filterKDistShelves(items);
            const books = filterKDistBooksX(items);

            // ladda kdist-connections
            const connectionsItems: KdistUserConnection[] = await rdbKdistLoadAllConnectionsItemsArray();
            console.log('connectionsItems', connectionsItems);
            actions.setStatus({ type: 'items', items: books, connectionsItems, kdistUsers, shelves });
        }),
    }
}

export function filterKDistShelves(items: Item[]): Item[] {
    const shelves: Item[] = [];
    const shelvesItems =
        filterPublished(filterHasChildren(filterTypeItem(items))).map((item, index) => item);
    return shelvesItems;
}


export function filterKDistBooksX(items: Item[]): Item[] {
    const kakBooks: Item[] = [];
    const shelvesItems =
        filterPublished(filterHasChildren(filterTypeItem(items))).map((item, index) => item);
    for (const shelfItem of shelvesItems) {
        for (const book of shelfItem.children!) {
            kakBooks.push(book);
        }
    }
    return kakBooks;
}

//----------------------------------------------

