import { child } from "firebase/database";
import { UserAccess, UserData } from "../store/userdata";

export const roomIds = ['kak', 'kdist', 'room1'];

export type Publish = { t: "always" } | { t: "never" };

export function strToPublish(str: string): Publish {
  switch (str) {
    case "never": return { t: "never" };
    default: return { t: "always" };
  }
}

export type Access = 0 | 1 | 2 | 3;
export function strToAccess(str: string): Access {
  switch (str) {
    case "0": return 0;
    case "1": return 1;
    case "2": return 2;
    case "3": return 3;
    default: return 0;
  }
}

export type Item = {
  id: string;
  parent?: Item;
  children?: Item[];
  access?: Access;
  sort?: number;
  text: string;
  title: string;
  originators?: Array<Originator>;
  publish: Publish;
  metas?: Array<MetaType>;
  type?: ItemType;
  path?: string;
};

export type RosettaItem = { a: string; q: string };

export type ItemType =
  | { t: "item" }
  | { t: "footer" }
  | { t: "rosetta"; columns: number; items: RosettaItem[] }
  | { t: "scorxdev"; id: string; left: number; right: number }
  | { t: "info" }
  | { t: "task" }
  | { t: "tasks" }
  | { t: "news" }
  | { t: "pdf", url: string }
  | { t: "pitch", data: TypePitchData }
  | { t: "glossary", types: string[] };

export function strToType(str: string): ItemType {
  switch (str) {
    case "item": return { t: "item" };
    case "footer": return { t: "footer" };
    case "rosetta": return { t: "rosetta", columns: 0, items: [] };
    case "scorxdev": return { t: "scorxdev", id: "", left: 0, right: 0 };
    case "info": return { t: "info" };
    case "task": return { t: "task" };
    case "tasks": return { t: "tasks" };
    case "news": return { t: "news" };
    case "pdf": return { t: "pdf", url: "" };
    case "pitch": return { t: "pitch", data: { data1: '', data2: '' } };
    case "glossary": return { t: "glossary", types: [] };
    default: return { t: "item" };
  }
}

export type Originator = {
  firstName: string;
  lastName: string;
  uid: string;
  type: OriginatorType;
};

export type OriginatorType = "document" | "author" | "music";

export type MetaType =
  | { t: "image"; src: string }
  | { t: "reference"; comment: string; url: string };

export const toPublish = (p: any): Publish => {
  if (!p) return { t: "always" };
  switch (p.t) {
    case "never":
      return { t: "never" };
      break;
    default:
      return { t: "always" };
      break;
  }
};

export const fromPublish = (p: Publish): any => {
  switch (p.t) {
    case "never":
      return { t: "never" };
      break;
    default:
      return { t: "always" };
      break;
  }
}


export const toItemtype = (t: any): ItemType | undefined => {
  if (!t) return { t: "item" };
  switch (t.t) {
    case "footer":
      return { t: "footer" };
    case "rosetta":
      return { t: "rosetta", columns: t.columns, items: t.items };
    case "scorxdev":
      return { t: "scorxdev", id: t.id, left: t.left, right: t.right };
    case "info":
      return { t: "info" };
    case "task":
      return { t: "task" };
    case "tasks":
      return { t: "tasks" };
    case "news":
      return { t: "news" };
    case "pdf":
      return { t: "pdf", url: t.url };
    case "pitch":
      return { t: "pitch", data: t.data };
    case "glossary":
      return { t: "glossary", types: t.types };
    default:
      return { t: "item" };
  }
};

export type TypePitchData = { data1: string, data2: string };


export const toMeta = (m: any): MetaType => {
  if (!m) return { t: "image", src: "" };
  switch (m.t) {
    case "reference":
      return { t: "reference", comment: m.comment, url: m.url };
      break;
    default:
      return { t: "image", src: m.src };
      break;
  }
};

export const sortChildren = (items: Item[]): Item[] => {
  return items.sort((a, b) => {
    return (a.sort || 0) - (b.sort || 0);
  });
};

export const dataToItem = ({
  id = "",
  children = {},
  sort = 0,
  text = "",
  title = "",
  originators = [],
  publish = { t: "always" },
  metas = [],
  type = null,
  path = "",
  access = 0,
}): Item => ({
  id,
  children: sortChildren(Object.values(children).map(dataToItem as any)),
  sort,
  text,
  title,
  originators: originators ? Object.values(originators) : [],
  publish: toPublish(publish),
  metas: Object.values(metas).map(toMeta),
  type: toItemtype(type),
  access: access as Access,
});

export const resolvePaths = (item: Item, path?: string, level?: number) => {
  if (!level) level = 0;
  if (!item.path) item.path = "";
  if (level >= 2) {
    item.path = path + "/" + item.id;
  }
  if (item.children) {
    item.children?.forEach(child => {
      resolvePaths(child, item.path!, level! + 1);
    });
  }
}


export const itemToObject = (item: Item): any => {
  let obj: any = {};
  obj['title'] = item.title;
  obj['id'] = item.id;
  obj['publish'] = item.publish;
  obj['access'] = item.access;
  obj['originators'] = item.originators;
  obj['sort'] = item.sort;
  obj['text'] = item.text;
  obj['type'] = item.type ? item.type : { t: "item" };
  obj['children'] = {};

  if (item.children)
    for (let child of item.children) {
      let childObj = itemToObject(child);
      obj.children[child.id] = childObj;
    }

  return obj;
}

export const findChildFromPath = (
  item: Item,
  path: string
): Item | null => {
  let currentItem = item;
  const segments = path.split("/");
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    const foundItem = currentItem.children?.find(
      (child) => child.id === segment
    );
    if (foundItem) {
      currentItem = foundItem;
      if (i === segments.length - 1) {
        return foundItem;
      }
    }
  }
  return null;
};

export const accessIdToFullname = (id: string): string => {
  switch (id) {
    case "kak":
      return "Körakademin";
    case "kdist":
      return "Kantorsutbildningen";
    default:
      return id;
  }
};

export const accessLevelToRole = (access: Access): string => {
  switch (access) {
    case 0:
      return "Gäst";
    case 1:
      return "Studerande";
    case 2:
      return "Lärare";
    case 3:
      return "Admin";
    default:
      return "Unspecified access:" + access;
  }
}

// const segments = path.split('/');
// const segmentsLength = segments.length;
// const firstSegment = segments[0];

// if (item.id === firstSegment) {
//     if (segmentsLength > 1) {
//         segments.shift();
//         const newPath = segments.join('/');
//         // console.log('leta i children!', newPath);
//         item.children!.forEach(child => {
//             // console.log('-', child.id, newPath);
//             findChildFromPath(child, newPath);

//         });
//     } else if (path === item.id) {
//         console.log('FOUND ITEM!', item.id);
//         return item;
//     }
// }
// console.log('hoho')
// return undefined;

export const filterPublished = (items: Item[]) => {
  return items.filter(item => item.publish && item.publish.t === 'always');
}

export const filterByAccess = (items: Item[], access: Access) => {
  return items.filter(item => Number(item.access) === Number(access))
}

export const filterAccessGuest = (items: Item[]) => {
  return items.filter(item => Number(item.access) === 0);
}

export const filterAccessStudent = (items: Item[]) => {
  return items.filter(item => Number(item.access) === 1);
}

export const filterByAtLeastAccess = (items: Item[], access: Access) => {
  return items.filter(item => Number(item.access) <= Number(access));
};

export const filterByType = (items: Item[], type: ItemType) => {
  return items.filter(item => item.type && item.type.t && item.type.t === type.t);
}

export const filterTypeInfo = (items: Item[]) => {
  return items.filter(item => item.type && item.type.t && item.type.t === "info");
}

export const filterTypeItem = (items: Item[]) => {
  return items.filter(it => it.type && it.type.t && it.type.t === "item");
}

export const filterHasChildren = (items: Item[]) => {
  return items.filter(item => item.children && item.children.length > 0);
}

export const sortItems = (items: Item[]) => {
  items.sort((a, b) => (a.sort || 0) - (b.sort || 0));
  return items;
}

export type ThreadItemStatus =
  | "unread"
  | "unanswered"
  | "solved"

export type ThreadItem = {
  time: string,
  text: string,
  fullname: string,
  uid?: string,
  useraccess: UserAccess,
  public: boolean,
  itempath: string,
  status: ThreadItemStatus,
}

export type ThreadType =
  | "discussion"
  | "message"

export type ThreadStatus =
  | "initiated"
  | "open"
  | "ongoing"
  | "closed"

export type Thread = {
  active: boolean,
  itempath: string,
  type: ThreadType,
  status: ThreadStatus,
  text: string,
  items: { [key: string]: ThreadItem },
  created: string,
  updated: string,
  closed?: string,
}

export function getThreadsByPath(data: { [key: string]: any }, parentpath: string): Thread[] {
  const threads: Thread[] = [];
  if (data) {
    Object.entries(data).map(([key, value]) => {
      if (value.items) {
        const thread: Thread = {
          active: value.active,
          itempath: value.itempath + '/' + key,
          items: value.items,
          status: value.status,
          text: value.text,
          type: value.type,
          created: value.created,
          updated: value.updated,
          closed: value.closed,
        }
        threads.push(thread);
      }
    });
  }
  return threads;
}


function fixTime(time: string): string {
  if (time.indexOf('/') > -1) {
    let timepart = time.substring(time.indexOf('-') + 1);
    console.log('timepart', timepart);
  }
  return time;
}

export function sortThreadsByUpdated(threads: Thread[]) {
  console.log('sortThreadsByUpdated')
  threads.sort((a, b) => {
    console.log(fixTime(a.updated), fixTime(b.updated))
    return (a.updated || "").localeCompare(b.updated || "");
  });
  return threads.reverse();
}

export function filterThreadsByUserId(threads: Thread[], uid: string) {
  return threads.filter(thread => {
    return Object.values(thread.items).find(item => {
      return uid === item.uid!;
    });
    return false;

  });
}

export type EventType =
  | { type: "videoconference", link: string }

export type EventItem = {
  id: string,
  description: string,
  title: string,
  type: EventType,
  startdate: string,
  starttime: string,
  endtime: string,
  publishdate: string,
}

export function sortEventItems(items: EventItem[]) {
  items.sort((a, b) => {
    return (a.startdate || "").localeCompare(b.startdate || "");
  });
  return items;
}

export function filterPassedEvents(items: EventItem[]) {
  return items.filter(item => {
    const now = new Date();
    const start = new Date(item.startdate + ' ' + '23:59:00'); // + item.starttime);
    return start.getTime() > (now.getTime() + hoursToMs(1));
  });
}

export function createItemStoragePath(houseId: string, itemPath: string, filename?: string) {
  let p = 'content' + '/' + houseId + itemPath;
  p = filename ? p + '/' + filename : p;
  return p;
}

export function createScorxStoragePath(scorxId: string, filename: string) {
  return 'scorx' + '/' + scorxId + '/' + filename;
}

export type BookmarkData = {
  time: string,
  path: string,
  title: string,
  bookTitle: string,
  shelfTitle: string,
}

export function usersByAccess(userdatas: UserData[], uAcc: UserAccess): UserData[] {
  return userdatas.filter(userdata => userdata.claims && userdata.claims.accesses && userdata.claims.accesses.find(a => a.access === uAcc.access && a.id === uAcc.id));
}

function hoursToMs(hours: number) {
  return hours * 60 * 60 * 1000;
}

export type GlossaryItem = {
  id: string,
  text: string,
  definition: string,
  info: string,
  language: GlossaryLanguage,
  type: GlossaryItemType,
  links: GlossaryLink[],
  sort: number,
  picUrl: string,
}

export type GlossaryLanguage =
  | 'latin'
  | 'italian'
  | 'french'
  | 'german'
  | 'swedish'
  | 'english';

export type GlossaryItemType =
  | 'tempo'
  | 'dynamics'
  | 'change'
  | 'articulation'
  | 'character'
  | 'rhythm'
  | 'pitch'
  | 'harmony'
  | 'form'
  | 'style'
  | 'instrument'
  | 'ensemble'
  | 'notation'
  | 'acoustics'
  | 'vocal'
  | 'other';




export const GLOSSARY_TYPES: string[] = [
  'tempo'
  , 'dynamics'
  // , 'change'
  , 'articulation'
  , 'character'
  // , 'rhythm'
  // , 'pitch'
  , 'harmony'
  , 'form'
  // , 'style'
  , 'instrument'
  // , 'ensemble'
  , 'notation'
  , 'acoustics'
  , 'vocal'
  , 'other']

export const GLOSSARY_TYPE_LABELS: string[] = [
  'Tempobeteckningar'
  , 'Dynamikbeteckningar'
  // , 'change'
  , 'Articulation'
  , 'Karaktärsbeteckningar'
  // , 'rhythm'
  // , 'pitch'
  , 'Harmonik'
  , 'Form'
  // , 'style'
  , 'Instrumentation'
  // , 'ensemble'
  , 'Notation'
  , 'Akustik'
  , 'Röst-termer'
  , 'Övrigt']

export const GLOSSARY_TYPE_LABELS_SHORT: string[] = [
  'tempo'
  , 'dynamik'
  // , 'change'
  , 'artikulation'
  , 'karaktär'
  // , 'rhythm'
  // , 'pitch'
  , 'harmonik'
  , 'form'
  // , 'style'
  , 'instrument'
  // , 'ensemble'
  , 'notation'
  , 'akustik'
  , 'röst'
  , 'övrigt']




export type GlossaryLink =
  | { type: 'also', id: string }
  | { type: 'url', url: string }


type U2O<U extends string> = {
  [key in U]: U2O<Exclude<U, key>>;
}

type O2T<O extends {}> = {} extends O ? [] : {
  [key in keyof O]: [key, ...O2T<O[key]>];
}[keyof O]


export type UnionOfGlossaryTypes = O2T<U2O<GlossaryItemType>>;