import { Action, action } from "easy-peasy";
import { centsOffFromPitch, noteFromPitch } from "../components/pitch/utils";
import { valueSmoothing } from "../utils/utils";
import FreqSmoothing from "../utils/freqsmoothing";

export type PitchStatus = "active" | "inactive" | "error";

export type ScorePlaybackStatus =
    | { type: 'empty' }
    | { type: 'playing', id: string }
    | { type: 'stopped' }


export type MusicModel = {
    // running: boolean;
    // setRunning: Action<MusicModel, boolean>;

    frequency: number | null;
    setFrequency: Action<MusicModel, number | null>;
    smoothedFrequency: number | null;
    manFilterFrequency: number | null;
    setManFilterFrequency: Action<MusicModel, number | null>;
    midinote: number | null,
    centsOff: number | null,
    midinotes: number[];
    setMidinotes: Action<MusicModel, number[]>;
    // setSmoothedFrequency: Action<MusicModel, number | null>;
    // setMidinote: Action<MusicModel, number | null>;
    // setCentsOff: Action<MusicModel, number | null>;

    scorePlaybackStatus: ScorePlaybackStatus;
    setScorePlaybackStatus: Action<MusicModel, ScorePlaybackStatus>;

}


export const createMusicModel = (): MusicModel => {
    return {
        // running: false,
        // setRunning: action((state, payload) => {
        //     state.running = payload;
        // }),

        frequency: null,
        smoothedFrequency: null,
        manFilterFrequency: null,
        midinote: null,
        centsOff: null,
        setFrequency: action((state, payload) => {
            const frequency = payload;
            state.frequency = frequency;
            const smoothedFrequency = frequency ? FreqSmoothing.getInstance().smooth(frequency, state.manFilterFrequency) : null;
            state.smoothedFrequency = smoothedFrequency;
            const midinote = smoothedFrequency ? noteFromPitch(smoothedFrequency) : null;
            state.midinote = midinote;
            state.centsOff = smoothedFrequency && midinote ? centsOffFromPitch(smoothedFrequency, midinote) : null;
        }),
        setManFilterFrequency: action((state, payload: number) => {
            state.manFilterFrequency = payload;
        }),
        midinotes: [60],
        setMidinotes: action((state, payload: number[]) => {
            state.midinotes = payload;
        }),

        // setSmoothedFrequency: action((state, payload) => {
        //     state.smoothedFrequency = payload;
        // }),
        // setMidinote: action((state, payload) => {
        //     state.midinote = payload;
        // }),
        // setCentsOff: action((state, payload) => {
        //     state.centsOff = payload;
        // }),

        scorePlaybackStatus: { type: 'empty' },
        setScorePlaybackStatus: action((state, payload) => {
            state.scorePlaybackStatus = payload;
        }),

    };

}