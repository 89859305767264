import { useStoreState } from "easy-peasy";
import { Item } from "../../data/Data"
import { AppModel } from "../../store/appModel";
import { ContentUserModel } from "../../store/contentuser";
import { createBookmark, getBookmarkUser, getBookmarksUser, removeBookmark } from "../../store/firebaseStore";
import { content } from "../../model/contentModel";
import { LocationModel } from "../../store/location";
import { useEffect, useState } from "react";
import { SvgBookmark } from "../buttons/SvgBookmark";

export function BookmarkView(props: { item: Item }) {
    const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    //const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    // const loadMethod: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
    // useEffect(()=>{
    const [hasBookmark, setHasBookmark] = useState(false);

    useEffect(() => {
        const doit = async () => {
            const val = await getBookmarkUser(contentuser.contentUserData.uid!, contentuser.contentAccess.id!,
                props.item.path!);
            if (val) {
                setHasBookmark(true);
            } else {
                setHasBookmark(false);
            }
        };
        doit();


    }, [props.item.path]);

    return <div className="Flex JustifyEnd">
        {/* <button onClick={e => {
            if (contentuser.contentUserData.uid && props.item.path && location.book && location.shelf)
                createBookmark(contentuser.contentUserData.uid!, contentuser.contentAccess.id!, props.item.path!, props.item.title!, location.shelf!.title, location.book!.title)
        }}>BookmarkView</button>

        <button onClick={async e => {
            if (contentuser.contentUserData.uid && contentuser.contentAccess.id) {
                const val = await getBookmarkUser(contentuser.contentUserData.uid!, contentuser.contentAccess.id!,
                    props.item.path!);
                if (val) {
                    Object.keys(val).forEach((v: any) => {
                        console.log(v);
                    });
                }
            }
        }}>GetBookmarks</button>
        <p>{JSON.stringify(hasBookmark)} {props.item.path!}</p> */}
        <div className="Flex AlignCenter" onClick={() => {
            if (!hasBookmark) {
                createBookmark(contentuser.contentUserData.uid!, contentuser.contentAccess.id!, props.item.path!, props.item.title!, location.shelf!.title, location.book!.title);
                setHasBookmark(true);
            } else {
                removeBookmark(contentuser.contentUserData.uid!, contentuser.contentAccess.id!, props.item.path!);
                setHasBookmark(false);
            }
        }}>
            <p className="" style={{ fontSize: '80%', color: 'gray' }}>Bokmärk</p>
            <SvgBookmark style={{ cursor: 'pointer', fill: hasBookmark ? 'orange' : 'lightgray' }} />
        </div>

    </div >
}