import { useStoreState } from "easy-peasy";
import { ContentModel } from "../../../store/content";
import { CenterContent } from "../../CenterContent";
import { MarkdownView } from "../../MarkdownView";
import { ContentFrame } from "../../content/ContentFrame";
import { AppModel } from "../../../store/appModel";
import { Item, filterAccessGuest, filterByType } from "../../../data/Data";
import { LoadingSpinner } from "../LoadingSpinner";
import { FrontInfo } from "../FrontView";

export function FrontKakGuest() {
    const content: ContentModel = useStoreState<AppModel>((state) => state.content);
    const currentRoomItems = content.currentRoomItems as unknown as Item[];
    const items: Item[] = filterAccessGuest(filterByType(currentRoomItems, { t: 'info' }));
    const loading = content.status.type === 'loading' ? <LoadingSpinner /> : <></>;
    const roomItem = content.currentRoom as any as Item;

    return <>
        <CenterContent >
            <ContentFrame css={{ padding: '0 2rem 2rem', backgroundColor: 'rgba(255,255,255,.5)' }}>
                <h1 className="Jumbo">Välkommen till Körakademin!</h1>
                {loading}
                <div className="Flex JustifyStart" >
                    <div className="MaxW40Rem Large">
                        <MarkdownView markdown={roomItem.text} />
                    </div>
                </div>
            </ContentFrame>
            <>{items.map((item, index) => <FrontInfo item={item} key={item.id} />)}</>
        </CenterContent>
    </>
}