import { useStoreState } from "easy-peasy";
import { ContentUserModel } from "../../store/contentuser";
import { AppModel } from "../../store/appModel";
import { useEffect, useState } from "react";
import { getBookmarksUser } from "../../store/firebaseStore";
import { BookmarkData } from "../../data/Data";
import { linkTo } from "../../utils/utils";
import { SvgBookmark } from "../buttons/SvgBookmark";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";

export function BookmarksOverview() {
    // const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    // const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    // const loadMethod: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.content.loadContent);

    const [items, setItems] = useState<BookmarkData[]>([]);

    useEffect(() => {
        const doit = async () => {

            const val = await getBookmarksUser(contentuser.contentUserData.uid!, contentuser.contentAccess.id);
            //    console.log(val);

            const entities: BookmarkData[] = Object.entries(val).map(([key, value]) => {
                console.log(key, value);
                key = key.replaceAll("_&&_", "/");
                const v: any = value;
                return { path: key, time: v.time, title: v.title, bookTitle: v.bookTitle, shelfTitle: v.shelfTitle };
            });

            // console.log(entities);
            setItems(entities);
        }
        doit();
    }, []);

    if (items.length === 0) return <>
        <h3>Bokmärken</h3>
        <p>Du har inte skapat något bokmärke ännu. </p>
    </>

    return <>
        <h3>Bokmärken</h3>
        <div className="Flex FlexColumn">
            {items.map((item, index) => <BookmarkItem data={item} key={index} />)}

        </div>
    </>
}

export function BookmarkItem(props: { data: BookmarkData }) {
    return <div className="Frame Pointer Flex JustifyBetween" >
        <div className="Flex AlignCenter JustifyBetween Flex1" onClick={e => {
            const path = 'book' + props.data.path;
            linkTo(path);
        }}>
            <SvgBookmark style={{ fill: 'orange' }} />
            <div className="Flex AlignBase JustifyBetween" style={{ flex: '1' }}>
                <h4 className="NoMargin">{props.data.title}</h4>
                <p>{props.data.shelfTitle} / {props.data.bookTitle}</p>
            </div>
        </div>
        <Menu menuButton={<MenuButton >☰</MenuButton>}>
            <MenuItem onClick={e => {
                console.log('remove');
            }}>Ta bort bokmärke</MenuItem>
        </Menu>
    </div>
};

