import { ActionCreator, useStoreActions, useStoreState } from "easy-peasy";
import { UserAccess, UserDataModel } from "../../store/userdata";
import { AppModel } from "../../store/appModel";
import { DiscussionModel } from "../../store/discussionstore";
import { Thread, ThreadItem, filterThreadsByUserId, sortThreadsByUpdated } from "../../data/Data";
import { UserAccessView } from "../admin/users/AccessView";
import { useEffect } from "react";
import { TreeItem, TreeItemLink } from "../general/TreeItem";
import { ContentUserModel } from "../../store/contentuser";
import { dateOnly } from "../../utils/utils";

export function StudentDiscussionsOverview() {
    const contentAccess: UserAccess = useStoreState<AppModel>((state) => state.contentUser.contentAccess);
    const discussions: DiscussionModel = useStoreState<AppModel>((state) => state.discussions);
    const loadDiscussions: ActionCreator<string> = useStoreActions<AppModel>((actions) => actions.discussions.loadDiscussions);
    const contentUser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);

    useEffect(() => {
        loadDiscussions(contentAccess.id);
        return () => {
            // cleanup
        }
    }, [])

    if (discussions.status.type === 'loading') return <h3>CRDiscussionsView loading...</h3>
    if (discussions.status.type === 'items') {

        let threads = sortThreadsByUpdated(discussions.status.threads);
        // if (contentAccess.access <= 1) {
        //     threads = filterThreadsByUserId(threads, contentUser.contentUserData.uid!);
        // }

        return <>

            <h3>Pågående diskussioner</h3>
            <p style={{ color: 'gray' }}>Klicka på rubrikerna nedan för att gå till respektive sida med diskussion</p>

            {/* {contentAccess.access <= 1 && <h3>Mina diskussioner</h3>}
            {contentAccess.access >= 2 && <h3>Pågående diskussioner</h3>}
            {threads.map((thread, index) => {                
                return <ThreadView key={index} thread={thread} />
            })} */}

            {threads.map((thread, index) => {
                return <ThreadOverview key={index} thread={thread} />
            })}
        </>
    }
    return <h3>empty</h3>
}



export function AdminDiscussionsOverview() {
    const contentAccess: UserAccess = useStoreState<AppModel>((state) => state.contentUser.contentAccess);
    const discussions: DiscussionModel = useStoreState<AppModel>((state) => state.discussions);
    const loadDiscussions: ActionCreator<string> = useStoreActions<AppModel>((actions) => actions.discussions.loadDiscussions);
    const contentUser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    const currentAccess: UserAccess = useStoreState<AppModel>((state) => state.userdata.currentAccess);

    useEffect(() => {
        loadDiscussions(contentAccess.id);
        return () => {
            // cleanup
        }
    }, [])

    if (discussions.status.type === 'loading') return <h3>CRDiscussionsView loading...</h3>
    if (discussions.status.type === 'items') {

        let threads = sortThreadsByUpdated(discussions.status.threads);
        if (contentAccess.access <= 1) {
            threads = filterThreadsByUserId(threads, contentUser.contentUserData.uid!);
        }

        return <>
            {contentAccess.access <= 1 && <h3>Mina diskussioner</h3>}
            {contentAccess.access >= 2 && <h3>Pågående diskussioner</h3>}
            {threads.map((thread, index) => {
                return <ThreadView key={index} thread={thread} />
            })}
        </>
    }
    return <h3>empty</h3>
}

export function ThreadOverview(props: { thread: Thread }) {
    const entries = Object.entries(props.thread.items).map(([key, value]) => {
        const item: ThreadItem = value as ThreadItem;
        return item
    });
    const count = entries.length;
    const first: ThreadItem = entries[entries.length - 1];

    return <div className="Flex FlexColumn">
        <div className="Flex JustifyBetween Frame">
            <a href={('#book/' + props.thread.itempath).replaceAll('//', '/')}><h4>{props.thread.text}</h4></a>
            <p>{count} inlägg, senast {dateOnly(first.time)}</p>
        </div>
    </div>
}

export function ThreadView(props: { thread: Thread }) {
    return <div className="Flex FlexColumn Frame">

        <TreeItem open={true} header={
            <div className="Flex JustifyBetween">
                <a href={'#book/' + props.thread.itempath}><h4>{props.thread.text}</h4></a>
                <p>{props.thread.status}</p>
                <p>{props.thread.active}</p>
                <p>{props.thread.type}</p>
            </div>
        }>
            <div className="Flex FlexColumn" style={{ paddingLeft: '.5rem' }}>
                {Object.entries(props.thread.items).map(([key, value]) => {
                    const item: ThreadItem = value as ThreadItem;
                    let right: boolean = true;
                    if (item.useraccess && item.useraccess.access && (item.useraccess.access === 1)) right = true;
                    return <DiscussionItemView key={key} item={item} alignRight={right} />

                })}
            </div>
        </TreeItem>
    </div>
}




export function DiscussionItemView(props: { item: ThreadItem, alignRight: boolean }) {
    if (!props.item.useraccess) return <p>no useraccess</p>
    const flexRight: any = props.alignRight ? { justifyContent: 'flex-end' } : {};
    const textRight: any = props.alignRight ? { textAlign: 'right' } : {};
    return <div className=" DiscussionItem" style={flexRight}>
        <div className="DiscussionItemHeader" style={flexRight}>
            <div >
                <p>{props.item.fullname}</p>
                <UserAccessView useraccess={props.item.useraccess} />
                <p>{props.item.time}</p>
            </div>
        </div>
        <div className="DiscussionItemText" style={flexRight}>
            <p style={textRight}>{props.item.text}</p>
        </div>
    </div>
}