import { ActionCreator, useStoreActions, useStoreState } from "easy-peasy";
import { UserAccess } from "../../store/userdata";
import { AppModel } from "../../store/appModel";
import { createEvent, removeEvent } from "../../store/firebaseStore";
import { EventItem, filterPassedEvents, sortEventItems } from "../../data/Data";
import { useEffect, useState } from "react";
import { generateId, getTimePath, timeWOSeconds } from "../../utils/utils";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MarkdownView } from "../MarkdownView";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";


export function EventsView() {
    const contentAccess: UserAccess = useStoreState<AppModel>((state) => state.contentUser.contentAccess);
    const currentAccess: UserAccess = useStoreState<AppModel>((state) => state.userdata.currentAccess);
    const loadEvents: ActionCreator<string> = useStoreActions<AppModel>((actions) => actions.events.loadEvents);
    const events: EventItem[] = useStoreState<AppModel>((state) => state.events.events);

    useEffect(() => {
        loadEvents(contentAccess.id);
    }, []);

    return <>
        {filterPassedEvents(events).length > 0 && <h3>Aktuella händelser</h3>}
        {filterPassedEvents(events).length === 0 && <p>Inga händelser är aktuella just nu.</p>}

        {filterPassedEvents(sortEventItems(events)).map((item, index) => {
            return <EventItemView item={item} key={item.id} />;
        })}

        {contentAccess.access >= 3 &&
            <CreateEventView onCreated={(item) => {
                console.log('onCreated', item);
                loadEvents(contentAccess.id);
            }} />}
    </>
}

function EventItemView(props: { item: EventItem }) {
    const currentAccess: UserAccess = useStoreState<AppModel>((state) => state.userdata.currentAccess);
    const contentAccess: UserAccess = useStoreState<AppModel>((state) => state.contentUser.contentAccess);
    const link = props.item.type.link;

    return <div className="Frame">
        <div className="Flex">
            <h4>{props.item.title}</h4>
            <p>{props.item.startdate}, kl {timeWOSeconds(props.item.starttime)}-{timeWOSeconds(props.item.endtime)}</p>

            {currentAccess.access >= 3 && <Menu menuButton={<MenuButton>☰</MenuButton>} >
                <MenuItem onClick={e => { removeEvent(currentAccess.id, props.item) }}>Ta bort</MenuItem>
            </Menu>}


        </div>


        <MarkdownView markdown={props.item.description} />

        <div className="Flex FlexColumn">
            <p>Klicka på länken nedan för att gå till videomötet:</p>
            <a href={link} style={{ color: "purple" }} target="_blank">{link}</a>
        </div>
    </div>
}

function CreateEventView(props: { onCreated: (item: EventItem) => void }) {
    const contentAccess: UserAccess = useStoreState<AppModel>((state) => state.contentUser.contentAccess);
    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [description, setDescription] = useState('');


    return <>
        <h3>Skapa händelse</h3>
        <form className="FormGrid" onSubmit={e => {
            e.preventDefault();
            const form = e.target as HTMLFormElement;
            const title = form.eventtitle.value;
            const item: EventItem = {
                type: { type: 'videoconference', link: form.link.value },
                description: form.description.value,
                id: generateId(),
                title,
                startdate: startDate.toLocaleDateString(),
                starttime: startTime.toLocaleTimeString(),
                endtime: endTime.toLocaleTimeString(),
                publishdate: getTimePath(),
            }

            createEvent(contentAccess.id, item).then(_ => {
                console.log('Event created');
                form.eventtitle.value = '';
                form.starttime.value = '';
                form.endtime.value = '';
            }).catch(err => {
                alert('Error creating event:' + err);
            });

            props.onCreated(item);
        }}>
            <label htmlFor="eventtitle">Titel</label>
            <input type="text" name="eventtitle" placeholder="title" required />
            <label htmlFor="type">Typ</label>
            <select name="type">
                <option value="videoconference">Videomöte</option>
            </select>
            <label htmlFor="link">Länk till videokonferens</label>
            <input type="text" name="link" placeholder="länk" required />

            <label htmlFor="description">Beskrivning</label>
            <textarea name="description" placeholder="beskrivning" value={description} onChange={(e) => setDescription(e.target.value)} />
            <label htmlFor="Markdown">Förhandsgranska</label>
            <MarkdownView markdown={description} />
            <label htmlFor="startdate">Startdatum</label>
            <div>
                <DatePicker
                    name="startdate"
                    selected={startDate}
                    onChange={(date: Date) => setStartDate(date)}
                    dateFormat={"yyyy-MM-dd"}
                />
            </div>
            <label htmlFor="starttime">Starttid</label>
            <div>
                <DatePicker
                    name="starttime2"
                    selected={startTime}
                    onChange={(date: Date) => setStartTime(date)}
                    timeCaption="Starttid"
                    timeIntervals={15}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                />
            </div>


            <label htmlFor="endtime">Sluttid</label>
            <div>
                <DatePicker
                    name="endtime"
                    selected={endTime}
                    onChange={(date: Date) => setEndTime(date)}
                    timeCaption="Sluttid"
                    timeIntervals={15}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                />
            </div>

            <button type="submit">Skapa händelse</button>

        </form>
    </>
}