import { Access, accessIdToFullname, accessLevelToRole } from "../../../data/Data";
import { UserAccess } from "../../../store/userdata";

export function AccessView(props: { access: Access, short?: boolean }) {
    let role = accessLevelToRole(props.access);
    if (props.short) {
        role = role.substr(0, 1);
    }
    return <span className={`Badge Access${props.access}`}>{role}</span>
}

export function UserAccessView(props: { useraccess: UserAccess }) {
    if (!props.useraccess) return <span className="BadgeOuter" >No access</span>
    const role = accessLevelToRole(props.useraccess.access);
    const roomname = props.useraccess.id;
    return <span className="BadgeOuter" ><span>{roomname} </span><AccessView short={true} access={props.useraccess.access} /></span>
}