/**
 *
 * @typedef Options
 *   Configuration (optional).
 * @property {Array<string>} [imageExtensions]
 *   File extensions (without dot) to treat as images.
 */

import { Link, Image, Root } from 'mdast'

import isUrl from 'is-url'
import { visitParents } from 'unist-util-visit-parents'
import { is } from 'unist-util-is'

const isImgPath = (value: string) =>
    value.startsWith('/') || value.startsWith('./') || value.startsWith('../')

/**
 * Extensions recognized as images by default
 */
export const defaultImageExtensions = [
    'svg',
    'png',
    'jpg',
    'jpeg',
    'gif',
    'webp',
    'avif'
]

/**
 * Plugin to add a simpler image syntax.
 *
 * @type {import('unified').Plugin<[Options?]|void[], Root>}
 */
export default function remarkImages({
    imageExtensions = defaultImageExtensions
} = {}) {
    // const imgExtRegex = new RegExp(`\\.(${imageExtensions.join('|')})$`)
    const imgExtRegex = new RegExp(`\\.(${imageExtensions.join('|')})(.*?)`)
    const isImgExt = (value: string) => imgExtRegex.test(value)

    return (tree: any) => {
        visitParents(tree, 'text', (node, parents) => {
            const value = String(node.value).trim()

            if ((isUrl(value) || isImgPath(value)) && isImgExt(value)) {
                let interactive = false
                let length = parents.length
                const parent = parents[length - 1]
                const siblings = parent.children
                const index = siblings.indexOf(node)

                while (length--) {
                    if (is(parents[length], ['link', 'linkReference'])) {
                        interactive = true
                        break
                    }
                }

                const image: Image = {
                    type: 'image',
                    url: value,
                    title: null,
                    alt: '',
                    position: node.position
                }

                let next: Image | Link = image

                // Add a link if we’re not already in one.
                if (!interactive) {
                    next = {
                        type: 'link',
                        url: value,
                        title: null,
                        children: [image],
                        position: node.position
                    }
                }

                siblings[index] = next
            }
        })
    }
}