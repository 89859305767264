import { ActionCreator, useStoreActions, useStoreState } from "easy-peasy";
import { GlossaryItem, GlossaryItemType, GlossaryLanguage, GlossaryLink, GLOSSARY_TYPES, GLOSSARY_TYPE_LABELS } from "../data/Data";
import { rdbGetGlossaryItem, rdbSaveGlossarItem, rdbSetGlossarLinks } from "../store/firebaseStore";
import { GlossaryModel } from "../store/glossaryStore";
import { CenterContent, FullwidthContent } from "./CenterContent";
import { AppModel } from "../store/appModel";
import { useEffect, useState } from "react";
import { useDebounce } from "../hooks/useDebounce";
import { MarkdownView } from "./MarkdownView";
import { LocationModel, LocationStatus } from "../store/location";

export function SearchView() {
    const items: GlossaryItem[] = useStoreState<GlossaryModel>((state) => state.items);

    return <>
        <div className="Flex FlexColumn AlignStretch JustifyStretch OFAuto Flex1">

            <CenterContent>
                <SearchTerm />
                {/* <AddGlossarItem /> */}
                {/* <ListItemsByType /> */}
                {/* <ListItems /> */}

            </CenterContent>
        </div>
    </>
}

export function ListItems() {
    const items: GlossaryItem[] = useStoreState<AppModel>((state) => state.glossary.items);
    const loadItems: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.glossary.loadItems);

    useEffect(() => {
        loadItems();
    }, []);

    return <>
        <h3>List items</h3>
        {items.sort((a, b) => a.text.localeCompare(b.text)).map((item, index) => {
            return <GlossaryItemView key={item.id} item={item} />
        })}
    </>
}

export function ListItemsByType() {
    const searchStatus: LocationStatus = useStoreState<AppModel>((state) => state.location.status);
    const tags = searchStatus.type === 'search' ? searchStatus.tags : [];



    const types = tags.length == 0 ? GLOSSARY_TYPES :
        GLOSSARY_TYPES.filter(type => tags.includes(String(type)));

    return <>
        {types.map((type, index) => {
            return <>
                <h3>{getGlossaryLabel(type)}</h3>
                <ListItemsType key={index} type={type as GlossaryItemType} />
            </>
        })}
    </>
}

export function getGlossaryLabel(type: string): string {
    switch (type) {
        case 'tempo': return 'Tempobeteckningar';
        case 'dynamics': return 'Dynamikbeteckningar';
        case 'change': return 'Change';
        case 'articulation': return 'Artikulationsbeteckningar';
        case 'character': return 'Karaktärsbetekningar';
        case 'rhythm': return 'Rytmtermer';
        case 'pitch': return 'Pitch';
        case 'form': return 'Formbeteckningar';
        case 'harmony': return 'Harmoniktermer';
        case 'style': return 'Style';
        case 'instrument': return 'Instrument-termer';
        case 'ensemble': return 'Ensemble';
        case 'notation': return 'Notationstermer';
        case 'acoustics': return 'Akustik';
        case 'vocal': return 'Vokaltermer';
        case 'other': return 'Övriga termer';
        default: return 'Other';
    }
    return 'Other';

}

export function ListItemsType(props: { type: GlossaryItemType }) {
    const items: GlossaryItem[] = useStoreState<AppModel>((state) => state.glossary.items);
    const loadItems: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.glossary.loadItems);

    useEffect(() => {
        loadItems();
    }, []);

    const hasSorted = (items && items.filter(item => item.type == props.type)
        .filter(item => item.sort > 0)).length > 0;

    return <>
        {items.length == 0 && <p>Laddar lista...</p>}

        {items && items
            .filter(item => item.type == props.type)
            .filter(item => item.sort > 0)
            .sort((a, b) => a.sort - b.sort)
            .map((item, index) => <GlossaryItemView key={item.id} item={item} />)}
        {hasSorted && <br />}

        {items && items
            .filter(item => item.type == props.type)
            .filter(item => item.sort == 0)
            .sort((a, b) => a.text.localeCompare(b.text))
            .map((item, index) => <GlossaryItemView key={item.id} item={item} />)}


    </>
}

export function SearchTerm() {
    const items: GlossaryItem[] = useStoreState<AppModel>((state) => state.glossary.items);
    const loadItems: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.glossary.loadItems);
    const status: LocationStatus = useStoreState<AppModel>((state) => state.location.status);


    const tags: string[] = status.type === 'search' ? status.tags : [];


    const [search, setSearch] = useState('');
    const [filteredItems, setFilteredItems] = useState<GlossaryItem[]>([]);
    const debouncedItems = useDebounce<GlossaryItem[]>(filteredItems, 500);
    const [filterType, setFilterType] = useState('termOnly');

    useEffect(() => {
        loadItems();
    }, []);

    useEffect(() => {
        if (search.length == 0) {
            setFilteredItems([]);
            return;
        }
        if (filterType == 'termOnly') {
            setFilteredItems(items.filter(item => item.text.toLowerCase().includes(search.toLowerCase())));
            return;
        }
        if (filterType == 'termAndDefinition') {
            setFilteredItems(items.filter(item => item.text.toLowerCase().includes(search.toLowerCase()) || item.definition.toLowerCase().includes(search.toLowerCase())));
            return;
        }
        setFilteredItems([]);
    }, [search, filterType, items])

    return <>
        <h2>Ordlista</h2>
        <div className="Flex FlexRow JustifyStretch">
            <div className="Flex FlexColumn">
                <p>Sök:</p>
                <input type="search" value={search} onChange={e => setSearch(e.target.value)}></input>
            </div>
            <div className="Flex FlexColumn">
                <p>Filter:</p>
                <select onChange={e => {
                    setFilterType(e.target.value);
                }}>
                    <option value="termOnly" >Enbart term</option>
                    <option value="termAndDefinition" >Term och förklaring</option>
                </select>
            </div>
        </div>

        {debouncedItems && debouncedItems.map((item, index) => {
            return <GlossaryItemView key={item.id} item={item} />
        })}
        {debouncedItems.length == 0 && search.length > 0 && <p>Inga träffar</p>}
        {debouncedItems.length == 0 && <ListItemsByType />}
    </>
}



export function AddGlossarItem() {
    const addItem: ActionCreator<GlossaryItem> = useStoreActions<AppModel>((actions) => actions.glossary.addItem);
    return <>
        <form className="Flex FlexColumn" onSubmit={e => {
            e.preventDefault();

            const target = e.target as typeof e.target & {
                text: { value: string };
                definition: { value: string };
                info: { value: string };
                language: { value: GlossaryLanguage };
                type: { value: GlossaryItemType };
                sort: { value: number };
                picUrl: { value: string };
            };

            addItem({ id: '', text: target.text.value, definition: target.definition.value, info: target.info.value, language: target.language.value, links: [], type: target.type.value, sort: target.sort.value, picUrl: target.picUrl.value });

            target.text.value = '';
            target.definition.value = '';
            target.info.value = '';
            target.sort.value = 0;
            target.picUrl.value = '';

        }}>

            <input name="text" id="text" placeholder="text"></input>
            <input name="definition" id="definition" placeholder="definition"></input>
            <input name="info" id="info" placeholder="info"></input>
            <input type="number" name="sort" id="sort" placeholder="sort"></input>
            <select name="language" id="language">
                <option value="italian" >Italian</option>
                <option value="latin" >Latin</option>
                <option value="swedish" >Swedish</option>
                <option value="english" >English</option>
                <option value="german" >German</option>
                <option value="french" >French</option>
            </select>
            <select name="type" id="type">
                <option value="tempo" >tempo</option>
                <option value="dynamics" >dynamics</option>
                <option value="change" >dynamics</option>
                <option value="articulation" >articulation</option>
                <option value="character" >character</option>
                <option value="rhythm" >rhythm</option>
                <option value="pitch" >pitch</option>
                <option value="form" >form</option>
                <option value="harmony" >harmony</option>
                <option value="style" >style</option>
                <option value="instrument" >instrument</option>
                <option value="ensemble" >ensemble</option>
                <option value="notation" >notation</option>
                <option value="acoustics" >akustik</option>
                <option value="vocal" >vocal</option>
                <option value="other" >other</option>
            </select>

            <input name="picUrl" id="picUrl" placeholder="picUrl"></input>
            <button type="submit">Submit</button>
        </form >

    </>

}

export function GlossaryItemView(props: { item: GlossaryItem }) {
    const removeItem: ActionCreator<string> = useStoreActions<AppModel>((actions) => actions.glossary.removeItem);
    const [edit, setEdit] = useState(false);
    return <div className="Flex FlexRow AlignStart GlossaryItem">
        <p title={props.item.id} onClick={e => {
            //setEdit(!edit)
        }} className="GlossaryType Pointer">{props.item.type}</p>
        <p className="GlossaryTerm">{props.item.text}</p>
        <MarkdownView markdown={props.item.definition} />
        {/* <p>{props.item.id}</p> */}
        {props.item.picUrl &&
            <img className="GlossaryPic" src={props.item.picUrl} alt={props.item.text} />}
        {/* <button onClick={e => { removeItem(props.item.id) }}>remove</button> */}
        {/* <p>{props.item.sort}</p> */}
        {edit && <EditGlossaryItem item={props.item} />}
        {props.item.links && <span className="ColorGray">{props.item.links.length} länk</span>}


    </div>
}

export function EditGlossaryItem(props: { item: GlossaryItem }) {
    const [connect, setConnect] = useState(false);
    const [linktype, setLinktype] = useState('also');
    return <div >
        <h3>Edit item</h3>
        <button onClick={e => { setConnect(!connect) }}>Connect</button>
        <select onChange={e => {
            setLinktype(e.target.value);
        }} >
            <option value="also">Also</option>
            <option value="url">Url</option>
        </select>
        {linktype == 'also' && <ConnectGlossaryItem item={props.item} />}
        {linktype == 'url' && <h3>URL</h3>}

        {connect && <ConnectGlossaryItem item={props.item} />}
    </div>
}

export function ConnectGlossaryItem(props: { item: GlossaryItem }) {
    const items: GlossaryItem[] = useStoreState<AppModel>((state) => state.glossary.items);
    const [connectId, setConnectId] = useState('');

    return <>
        <h3>ConnectGlossaryItem</h3>
        <p>{props.item.id} - {connectId}</p>
        {
            props.item.links && <>
                <p>Links: {props.item.links.length}</p>
                <p>{JSON.stringify(props.item.links)}</p>
                {props.item.links && props.item.links.map((item, index) => {
                    <p>Link: {JSON.stringify(props.item.links)}</p>
                })}
            </>
        }
        <select onChange={e => setConnectId(e.target.value)}>
            {items.sort((a, b) => a.text.localeCompare(b.text)).map((item, index) => {
                return <option key={item.id} value={item.id}>{item.text}</option>
            })}
        </select >
        <button onClick={async e => {
            if (!props.item.links) props.item.links = [];
            props.item.links.push({ type: 'also', id: connectId });
            console.log(props.item.links);
            rdbSetGlossarLinks(props.item.id, props.item.links);
            const item: GlossaryItem | undefined = await rdbGetGlossaryItem(connectId);
            if (item) {
                if (!item.links) item.links = [];
                item.links.push({ type: 'also', id: props.item.id });
                rdbSetGlossarLinks(item.id, item.links);
            }

        }}>Connect</button >
    </>
}
