import { FrontKakGuest } from "./FrontKakGuest";
import { FrontKakStudent } from "./FrontKakStudent";

export function FrontKakAdmin() {
    return <>
        <FrontKakStudent />
        <FrontKakGuest />
    </>
}

