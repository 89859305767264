export function SvgButtonClose(props: { title?: string, onClick?: () => void }) {
    return <div title={props.title} className={`SvgButton Close`} onClick={e => props.onClick && props.onClick()}>
        <svg width="100%" height="100%" viewBox="0 0 100 100" >
            <g transform="matrix(1,0,0,1,-126.126,-0.19119)">
                <g transform="matrix(1,0,0,1,0.943762,0.19119)">
                    <path d="M175.078,0C202.616,0 224.973,22.404 224.973,50C224.973,77.596 202.616,100 175.078,100C147.539,100 125.182,77.596 125.182,50C125.182,22.404 147.539,0 175.078,0ZM166.763,50L153.198,63.564C150.904,65.859 150.904,69.585 153.198,71.879C155.493,74.174 159.219,74.174 161.513,71.879L175.078,58.315L188.642,71.879C190.936,74.174 194.662,74.174 196.957,71.879C199.251,69.585 199.251,65.859 196.957,63.564L183.393,50L196.957,36.436C199.251,34.141 199.251,30.415 196.957,28.121C194.662,25.826 190.936,25.826 188.642,28.121L175.078,41.685L161.513,28.121C159.219,25.826 155.493,25.826 153.198,28.121C150.904,30.415 150.904,34.141 153.198,36.436L166.763,50Z" />
                </g>
            </g>
        </svg>
    </div>
}

