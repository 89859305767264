import { Action, Thunk, action, thunk } from "easy-peasy";
import { EventItem } from "../data/Data";
import { getEvents } from "./firebaseStore";

export type EventsModel = {
    events: EventItem[],
    setEvents: Action<EventsModel, EventItem[]>,
    loadEvents: Thunk<EventsModel, string>,
}

export const createEventsModel = (): EventsModel => {
    return {
        events: [],
        setEvents: action((state, payload) => {
            state.events = payload;
        }),
        loadEvents: thunk(async (actions, payload, helpers) => {
            const events = await getEvents(payload);
            const items: EventItem[] = events ? Object.entries(events).map(([key, value]) => {
                return value as EventItem;
            }) : [];
            actions.setEvents(items);
        }),
    }

}