import { useRef, useState } from "react";
import { NewFirebaseUser, getUserData, serverAddUser } from "../../../store/firebaseStore";
import { UserAccess } from "../../../store/userdata";
import { Access, strToAccess } from "../../../data/Data";
import { UserAccessView } from "./AccessView";
import { emailToRefPath } from "../../../utils/utils";

export function AdminAddUserView() {
    const uidRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const firstNameRef = useRef<HTMLInputElement>(null);
    const lastNameRef = useRef<HTMLInputElement>(null);
    const [room, setRoom] = useState<string>('kak');
    const [access, setAccess] = useState<number>(1);
    const [uas, setUas] = useState<UserAccess[]>([{ id: 'kak', access: 1 as Access }]);
    const [message, setMessage] = useState<string>('');


    return <>
        <h3>Lägg till användare</h3>
        <form className="Flex FlexColumn AlignStart" onSubmit={e => {
            e.preventDefault();
            if (!uidRef.current!.value) { alert('uid missing'); return; }
            if (!emailRef.current!.value) { alert('email missing'); return; }
            if (!passwordRef.current!.value) { alert('password missing'); return; }
            if (!firstNameRef.current!.value) { alert('firstName missing'); return; }
            if (!lastNameRef.current!.value) { alert('lastName missing'); return; }

            const newUser: NewFirebaseUser = {
                uid: uidRef.current!.value,
                email: emailRef.current!.value,
                password: passwordRef.current!.value,
                userdata: {
                    firstName: firstNameRef.current!.value,
                    lastName: lastNameRef.current!.value,
                    claims: {
                        accesses: uas,
                        current: uas[0]
                    }
                },
                kakVerifyDate: null,
            }

            // alert(JSON.stringify(newUser));

            serverAddUser(newUser).then(response => {
                console.log('User added', response);
                setMessage(`User ${newUser.uid} successfully added!`);
                uidRef.current!.value = '';
                emailRef.current!.value = '';
                passwordRef.current!.value = '';
                firstNameRef.current!.value = '';
                lastNameRef.current!.value = '';

            }).catch(err => {
                alert('Error: ' + err);
            });
        }}>
            <input ref={uidRef} autoComplete="off" name="uid" type="text" required placeholder="Användar-ID" />
            <input ref={emailRef} name="email" type="email" required placeholder="E-postadress" />
            <input ref={passwordRef} autoComplete="new-password" name="password" type="password" required placeholder="Lösenord" value="Stenhammar" />
            <input ref={firstNameRef} name="firstName" type="text" required placeholder="Förnamn" />
            <input ref={lastNameRef} name="lastName" type="text" required placeholder="Efternamn" />

            <div className="Flex AlignCenter">
                <p>Behörigheter</p>
                {uas.map((ua, index) => <UserAccessView key={index} useraccess={ua} />)}
            </div>
            <div className="Flex">
                <button className="Small" onClick={e => {
                    const userAccess: UserAccess = { id: room, access: access as Access };
                    setUas([...uas, userAccess]);
                }}>Lägg till behörighet</button>
                <select value={room} onChange={e => setRoom(e.target.value)} >
                    <option value="kak">Körakademin</option>
                    <option value="kdist">Kantorsdistans</option>
                    <option value="roomt">Room1</option>
                </select>
                <select value={access} onChange={e => setAccess(strToAccess(e.target.value))}>
                    <option value={"1"}  >Studerande</option>
                    <option value={"2"}  >Lärare</option>
                    <option value={"3"}  >Admin</option>
                </select>
            </div>

            <button type="submit">Skapa ny användare</button>
            {message && <p>{message}</p>}
        </form>
        <AddKdistUser />
    </>

}



export function AddKdistUser() {
    const [line, setLine] = useState<string>('');
    return <>

        <h3>Add kdist user</h3>
        <button onClick={async e => {
            console.log(line);

            // console.log(textarea.current!.value);



            // const firstLine = lines[0];

            const fields = line.split('\t');
            const email = fields[5];
            const uid = fields[5];
            // console.log(email, uid);
            // const newLines = lines.splice(1, lines.length - 1);
            // console.log(newLines, newLines.length);


            // lines.forEach(line => {
            // const fields = line.split('\t');
            // console.log(fields);
            const newUser: NewFirebaseUser = {
                uid: fields[5],
                email: fields[5],
                password: 'Stenhammar',
                userdata: {
                    firstName: fields[2],
                    lastName: fields[1],
                    claims: {
                        accesses: [{ id: 'kdist', access: 1 as Access }, { id: 'kak', access: 1 as Access }],
                        current: { id: 'kdist', access: 1 as Access }
                    }
                },
                kakVerifyDate: null,
            }
            console.log(newUser);

            // serverAddUser(newUser).then(response => {
            //     console.log('User added', response);
            // }).catch(err => {
            //     alert('Error: ' + err);
            // });

            const userData = await getUserData(newUser.uid);
            if (userData) {
                console.log('User already exists, Not Added:', userData);
                return;
            }
            const addedUserData = await serverAddUser(newUser);
            console.log('Added:', addedUserData);




        }}>Add kdist user</button>

        <input type="text" onChange={e => setLine(e.target.value)} value={line} />
        <button onClick={e => { setLine('') }}>Clear</button>

    </>
}
