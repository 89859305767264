import { ActionCreator, useStoreActions, useStoreState } from "easy-peasy";
import { ContentModel } from "../../../store/content";
import { AppModel } from "../../../store/appModel";
import { Item } from "../../../data/Data";
import { useState } from "react";
import { EditItemStatus } from "../AdminMain";
import { AdminItemView } from "./AdminItemView";
import { AdminItemOther } from "./AdminItemOther";
import { DeleteItem } from "./DeleteItem";
import { CreateItemChild } from "./CreateItemChild";
import { AdminItemEdit } from "./AdminItemEdit";
import { EditRosettaView } from "./EditRosettaView";
import { EditPdfView } from "./EditPdfView";
import { EditResourcesView } from "./EditResourcesView";
import { EditGlossaryView } from "./EditGlossaryView";

export function AdminContentView() {
    const content: ContentModel = useStoreState<AppModel>((state) => state.content);
    const currentRoom: Item = useStoreState<AppModel>((state) => state.content.currentRoom);
    const loadContent: ActionCreator<boolean> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
    // const [editItem, setEditItem] = useState<{ item: Item, path: string } | null>(null);
    const [editStatus, setEditStatus] = useState<EditItemStatus>({ type: "none" });

    if (content.status.type === "loading") return <p>Loading...</p>
    if (content.status.type === "error") return <p>Error: {content.status.msg}</p>
    const editVisible = editStatus.type === "none" ? 'visible' : 'hidden';

    return <div className="Flex FlexColumn Pad05">
        {/* <p>{JSON.stringify(editStatus)}</p> */}
        <h3>Kursmaterial</h3>
        {editStatus.type != 'none' && <div><button onClick={_ => { setEditStatus({ type: "none" }); }}>Close</button></div>}
        {editStatus.type === 'markdown' && <AdminItemEdit item={editStatus.item} path={editStatus.path} />}
        {editStatus.type === 'other' && <AdminItemOther item={editStatus.item} path={editStatus.path} />}
        {editStatus.type === 'addChild' && <CreateItemChild item={editStatus.item} level={editStatus.level} path={editStatus.path} crumbs={editStatus.crumbs} onClose={e => {
            console.log('onClose');
            setEditStatus({ type: "none" });
            loadContent(true);
        }} />}
        {editStatus.type === 'delete' && <DeleteItem item={editStatus.item} path={editStatus.path} crumbs={editStatus.crumbs} onClose={e => {
            console.log('onClose');
            setEditStatus({ type: "none" });
            loadContent(true);
        }} />}

        {editStatus.type === 'editRosetta' && <EditRosettaView item={editStatus.item} path={editStatus.path} onClose={e => {
            //
        }} />}

        {editStatus.type === 'editPdf' && <EditPdfView item={editStatus.item} path={editStatus.path} onClose={e => {
            //
        }} />}

        {editStatus.type === 'editGlossary' && <EditGlossaryView item={editStatus.item} path={editStatus.path} onClose={e => {
            //
        }} />}


        {editStatus.type === 'resources' && <EditResourcesView item={editStatus.item} />}


        <div style={{ visibility: editVisible }}>
            <AdminItemView key={currentRoom.id} item={currentRoom} path={""} level={0} crumbs={[]} setEditStatus={setEditStatus} />
        </div>
    </div>
}