import { useStoreState } from "easy-peasy";
import { AppModel } from "../../store/appModel";
import { ContentModel } from "../../store/content";
import { UserAccess, UserData, UserDataModel } from "../../store/userdata";
import { CenterContent, FullwidthContent } from "../CenterContent"
import { Item, filterAccessGuest, filterAccessStudent, filterByAccess, filterByType, filterPublished } from "../../data/Data";

import { MarkdownView } from "../MarkdownView";
import { AdminMain } from "../admin/AdminMain";
import { AliasUserView } from "../admin/users/AliasUserView";
import { ContentFrame } from "../content/ContentFrame";
import { CardsWrapper } from "../content/CardsWrapper";
import { ContentUserModel } from "../../store/contentuser";
import { LoadingSpinner } from "./LoadingSpinner";
import { TestGehor1 } from "../modules/TestGehor1";
import { EventsView } from "../classroom/EventsView";
import { linkTo } from "../../utils/utils";
import { ConfirmCourseView } from "./ConfirmCourseView";
import { FrontKakGuest } from "./kak/FrontKakGuest";
import { FrontKakStudent } from "./kak/FrontKakStudent";
import { FrontKakTeacher } from "./kak/FrontKakTeacher";
import { FrontKakAdmin } from "./kak/FrontKakAdmin";
import { FrontKdistGuest } from "./kdist/FrontKdistGuest";
import { FrontKdistStudent } from "./kdist/FrontKdistStudent";
import { FrontKdistAdmin } from "./kdist/FrontKdistAdmin";
import { TypeRosettaView } from "../content/types/TypeRosettaView";

export function FrontView() {
    const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    const content: ContentModel = useStoreState<AppModel>((state) => state.content);
    const currentRoomItems = content.currentRoomItems as unknown as Item[];
    const contentAccess: UserAccess = useStoreState<AppModel>((state) => state.contentUser.contentAccess);
    return <>

        {contentAccess.id === "kak" && <>
            <div className="Flex FlexColumn AlignStretch JustifyStretch OFAuto Pad05 Flex1" style={{
                color: 'black', backgroundImage: `url("assets/kak.jpg")`, backgroundSize: 'cover'
            }}>
                {/* <TypeRosettaView items={[
                    { q: '## d', a: '#### kbd: 48:59:50' },
                    { q: 'Vad är vattnet?', a: 'En vätska' },
                    { q: 'Vad är jorden?', a: 'En planet' },
                    { q: 'Vad är universum?', a: 'Allt som finns' },
                ]} /> */}

                {contentAccess.access === 0 && <FrontKakGuest />}
                {contentAccess.access === 1 && <FrontKakStudent />}
                {contentAccess.access === 2 && <FrontKakTeacher />}
                {contentAccess.access === 3 && <FrontKakAdmin />}
            </div>
        </>}
        {contentAccess.id === "kdist" && <>
            <div className="Flex FlexColumn AlignStretch JustifyStretch OFAuto Pad05 Flex1" style={{ color: 'white', backgroundColor: 'black', backgroundImage: `url("assets/kdist.jpg")`, backgroundSize: 'cover' }}>
                {contentAccess.access === 0 && <FrontKdistGuest />}
                {contentAccess.access === 1 && <FrontKdistStudent />}
                {contentAccess.access === 2 && <FrontKdistTeacher />}
                {contentAccess.access === 3 && <FrontKdistAdmin />}
            </div>
        </>}
        {contentAccess.id === "room1" && <>
            <div className="Flex FlexColumn AlignStretch JustifyStretch OFAuto Pad05 Flex1" style={{}}>
                <CenterContent>
                    <TestGehor1 />
                </CenterContent>
            </div>
        </>}
    </>
}






// {items.map((item, index) => <FrontItemDisplay item={item} key={item.id} />)}

export function FrontInfo(props: { item: Item }) {
    return <>
        <FrontItemDisplay item={props.item} key={props.item.id} />
        {props.item.children &&
            <CardsWrapper>
                {props.item.children.map((item, index) => <FrontItemDisplay item={item} key={item.id} />)}
            </CardsWrapper>}
    </>
}







export function FrontKdistTeacher() {
    return <>
        <h1 className="Jumbo">FrontKdistTeacher</h1>
    </>
}

export function FrontItemDisplay(props: { item: Item, css?: any }) {
    return <div className="FrontItem" key={props.item.id} style={props.css}>
        <h4 className="Pad05">{props.item.title}</h4>
        <div className="Pad1">
            <MarkdownView markdown={props.item.text} />
        </div>
    </div >
}



