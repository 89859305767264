import { useState } from "react";
import { MusicModel } from "../../store/musicstore";
import { useStoreState } from "easy-peasy";
import { AppModel } from "../../store/appModel";
import { NotationView } from "../music/NotationView";
import { Testvoice } from "../../smplr/testvoice";
import WebAudio from "../../audio/WebAudio";




export function TestGehor1() {
    // const location: LocationModel = useStoreState<AppModel>((state) => state.location);
    // const userdata: UserDataModel = useStoreState<AppModel>((state) => state.userdata);
    // const contentuser: ContentUserModel = useStoreState<AppModel>((state) => state.contentUser);
    // const loadMethod: ActionCreator<void> = useStoreActions<AppModel>((actions) => actions.content.loadContent);
    // useEffect(()=>{
    // }, []);


    const music: MusicModel = useStoreState<AppModel>((state) => state.music);
    const [midiNumbers, setMidiNumbers] = useState<number[]>([48, 55, 57, 49]);
    const [currentIdx, setCurrentIdx] = useState<number>(0);
    const [currentNumber, setCurrentNumber] = useState<number>(midiNumbers[currentIdx]);


    return <>
        <h3>Tonplatsmodulen - testexempel</h3>
        {/* <button onClick={e => { }}>TestGehor1</button>

        <div>
            <button onClick={e => {
                if (currentIdx > 0) {
                    setCurrentIdx(currentIdx - 1);
                    setCurrentNumber(midiNumbers[currentIdx - 1]);
                }
            }}>Prev</button>
            <button onClick={e => {
                if (currentIdx < midiNumbers.length - 1) {
                    setCurrentIdx(currentIdx + 1);
                    setCurrentNumber(midiNumbers[currentIdx + 1]);
                }
            }}>Next</button >
        </div> */}

        <div className="Flex" style={{ paddingLeft: '4.3rem' }}>
            {midiNumbers.map((midiNr, idx) => <TestGehorItem onClick={idx => {
                console.log(idx);
                setCurrentIdx(idx);
                setCurrentNumber(midiNumbers[currentIdx - 1]);
            }} active={idx == currentIdx} idx={idx} midiNr={midiNr} pitchNr={music.midinote} />)}
        </div>

        <NotationView text="|clef - F |key - F## |sp3 | lyr tpl:0:1 tpl:0:2 tpl:0:3 tpl:0:6 tpl:0:4 tpl:0:7 tpl:0:5 / 0 -1 -2 -5 -3 1 -4  |bl" onPlaydata={(playdata) => {
            console.log(playdata);
            const notes = playdata.notes.map((note: any) => note.note);
            console.log(notes);
            setMidiNumbers(notes);
        }} />
    </>
}

export function TestGehorItem(props: { idx: number, active: boolean, midiNr: number, pitchNr: number | null, onClick: (idx: number) => void }) {

    const diff = props.active && props.pitchNr ? props.pitchNr - props.midiNr : null;
    let background = props.active ? 'white' : 'lightgray';
    if (props.active && diff !== null) {
        switch (Math.abs(diff)) {
            case 0: background = 'lime'; break;
            case 1: background = 'orange'; break;
            case 2: background = 'tomato'; break;
            case 3: background = 'red'; break;
            default: background = 'lightgray'; break;
        }
    }

    return <div className="Flex FlexColumn Pad0 Frame  Gap0" style={{ userSelect: 'none', padding: '.2rem', cursor: 'pointer', borderColor: props.active ? 'dodgerblue' : 'lightgray' }
    } onClick={e => props.onClick(props.idx)} >
        <div style={{ width: '1.5rem', height: '1rem', border: '1px solid lightgray', backgroundColor: diff != null && diff >= 3 ? 'red' : 'white' }}></div>
        <div style={{ width: '1.5rem', height: '1rem', border: '1px solid lightgray', backgroundColor: diff != null && diff === 2 ? 'tomato' : 'white' }}></div>
        <div style={{ width: '1.5rem', height: '1rem', border: '1px solid lightgray', backgroundColor: diff != null && diff === 1 ? 'orange' : 'white' }}></div>
        <div style={{ width: '1.5rem', height: '1rem', border: '1px solid lightgray', backgroundColor: diff != null && diff === 0 ? 'lime' : 'white' }}></div>
        <div style={{ width: '1.5rem', height: '1rem', border: '1px solid lightgray', backgroundColor: diff != null && diff === -1 ? 'orange' : 'white' }}></div>
        <div style={{ width: '1.5rem', height: '1rem', border: '1px solid lightgray', backgroundColor: diff != null && diff === -2 ? 'tomato' : 'white' }}></div>
        <div style={{ width: '1.5rem', height: '1rem', border: '1px solid lightgray', backgroundColor: diff != null && diff <= -3 ? 'red' : 'white' }}></div>


    </div >

}